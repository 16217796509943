import { ReactComponent as CloseIconSVG } from '../../../src/svg/close-tickets.svg';
import { Button } from '../../components/button/button';
import { ROUTE_DOWNLOAD } from '../../constants/routes';
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';
import classNames from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface ModalProps {
  isOpen: boolean;
  session?: boolean;
  onClose: () => void;
  eventStartTime?: string | null;
  eventEndTime?: string | null;
}

export const TicketModal = ({ isOpen, onClose, session }: ModalProps) => {
  const navigate = useNavigate();

  useDisableBodyScroll(isOpen);

  if (!isOpen) return null;

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleButtonClick = () => {
    navigate(ROUTE_DOWNLOAD);
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-[16px] flex justify-center items-center z-[9999]"
      onClick={handleOverlayClick}
    >
      <div
        className="lg:w-[640px] w-full relative max-lg:p-[1.25rem] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-col items-center text-center gap-[2.5rem] lg:gap-0">
          <CloseIconSVG
            width={100}
            height={100}
            fill={session ? '#ec67f7' : '#B8CAD9'}
          />

          <h1
            className={classNames([
              'text-[3rem]/[3.5rem] font-light lg:mt-[2.5rem]',
              'max-lg:text-[1.25rem]/[1.75rem]',
              session ? ' text-white ' : 'text-generic ',
            ])}
          >
            {session
              ? 'Real ones know the tickets are in the Cene app...'
              : 'We could explain why your tickets are only visible in the Cene+ app'}
          </h1>

          <p
            className={classNames([
              'text-[1.0625rem]/[1.375rem]font-light flex flex-col gap-[0.75rem] lg:mt-[2.5rem]',
              'max-lg:text-[0.875rem]/[1.3125rem] max-lg:text-gray-light',
              session ? ' text-gray-light ' : 'text-generic ',
            ])}
          >
            {session
              ? 'So why are you still here? Go..'
              : 'But downloading the app would be faster than reading our explanation.'}
          </p>

          <Button
            color={session ? 'ticket' : 'generic'}
            className={classNames('w-[336px] lg:mt-[4rem] max-lg:w-full')}
            onClick={handleButtonClick}
          >
            Get the App
          </Button>
        </div>

        <div className="h-full lg:h-[calc(100%-96px)] overflow-y-auto lg:px-8 px-[20px]"></div>
      </div>
    </div>
  );
};
