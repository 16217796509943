import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';
import { useSupabase } from '../../providers/supabase';
import { Button } from '../button/button';
import { useState } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

interface ICropModalProps {
  crop: Crop | undefined;
  src: string | undefined;
  onCropComplete: (croppedImage: Crop | undefined) => void;
  onClose: () => void;
  aspect?: number;
  isUploadingAvatar: boolean;
}

export const CropModal = ({
  aspect,
  onCropComplete,
  src,
  crop,
  isUploadingAvatar,
  onClose,
}: ICropModalProps) => {
  const [_crop, setCrop] = useState<Crop | undefined>(crop);
  const [completedCrop, setCompletedCrop] = useState<Crop | undefined>();
  const { session } = useSupabase();
  const sessionType = session?.type === 'cene';

  useDisableBodyScroll(true);

  return (
    <div className="fixed flex left-0 top-0 w-full h-full justify-center items-center bg-black/50 backdrop-blur-sm z-[999]">
      <div className="bg-black p-3  h-auto ">
        <ReactCrop
          aspect={aspect}
          crop={_crop}
          ruleOfThirds
          onComplete={(_, crop) => setCompletedCrop(crop)}
          onChange={(c) => setCrop(c)}
        >
          <img
            alt="CropedPicture"
            className="rounded-tl-[30px] rounded-br-[30px]"
            src={src}
            style={{ width: '100%', height: '500px', display: 'block' }}
          />
        </ReactCrop>
        <div className="flex justify-center py-2 gap-2 items-center">
          <Button
            color="outlined"
            className="w-[200px]"
            onClick={() => onClose()}
            disabled={isUploadingAvatar}
          >
            Close
          </Button>
          <Button
            disabled={isUploadingAvatar}
            color={sessionType ? 'content' : 'generic'}
            className="w-[200px]"
            onClick={() => onCropComplete(completedCrop)}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
