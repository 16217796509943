import { ReactComponent as LoaderSVG } from '../svg/Loader.svg';
import {
  Category,
  HoldToken,
  Pricing,
  SeatingChart,
  SelectableObject,
} from '../types/seatsio';
import { useEffect, useState } from 'react';

interface ISeatsioPreview {
  containerId?: string;
  eventId: string;
  pricing: Pricing;
  currencyLabel?: string;
  onChartRendered: (chart: SeatingChart) => void;
  onSessionInitialized: (params: HoldToken) => void;
  onHoldTokenExpired: () => void;
  onObjectClicked: (
    object: SelectableObject & { confirmSelection: (param: string) => void }
  ) => void;
  onListCategories: (categories: Category[]) => void;
  onObjectDeselected: (obj: SelectableObject) => void;
  onInitSelectableObjects: (selectableObjects: SelectableObject[]) => void;
}

export const SeatsioTables = ({
  containerId = 'seatsio',
  eventId = 'a592c5b4-0869-4ff9-81d9-3e9932087834',
  pricing,
  currencyLabel = '',
  onSessionInitialized,
  onHoldTokenExpired,
  onInitSelectableObjects,
  onListCategories,
  onObjectClicked,
  onChartRendered,
  onObjectDeselected,
}: ISeatsioPreview) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (window.seatsio) {
      const chart = new window.seatsio.SeatingChart({
        divId: containerId,
        workspaceKey: `${process.env.REACT_APP_SEATSIO_WORKSPACE}`,
        event: eventId,
        session: 'continue',
        colorScheme: 'dark',
        pricing,
        priceFormatter: (price) => {
          return currencyLabel + price;
        },
        onChartRendered: async () => {
          await chart.startNewSession();
          const categories = await chart.listCategories();
          const selectedObjects = await chart.listSelectedObjects();

          onInitSelectableObjects(selectedObjects);

          onListCategories(categories);
          setLoading(false);
          onChartRendered(chart);
        },
        onSessionInitialized: (e) => {
          onSessionInitialized(e);
        },
        onObjectDeselected: (e) => {
          onObjectDeselected(e);
        },
        onTicketTypePrompt: (e, confirmSelection) => {
          onObjectClicked({ ...e.objectToSelect, confirmSelection });
          console.log('onTicketTypePrompt', e);
        },
        onHoldTokenExpired,
      }).render();

      console.log(chart);
    }
  }, []);

  const renderLoader = (
    <div className="w-full absolute bg-black flex justify-center items-center h-full">
      <LoaderSVG className="animate-spin" />
    </div>
  );

  return (
    <div className="relative">
      {loading && renderLoader}
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
        id={containerId}
      />
    </div>
  );
};
