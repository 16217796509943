// import { Button } from '../../components/button/button';
// import { Input } from '../../components/input/cene/input';
import {
  ROUTER_ABOUT,
  ROUTE_BURST,
  ROUTE_CAREERS,
  ROUTE_CONTACT_WITH_US,
  ROUTE_DIVERSITY, // ROUTE_DOWNLOAD,
  ROUTE_FAQ,
  ROUTE_MEMBERSHIP,
  ROUTE_PARTNER_WITH_US,
  ROUTE_TERMS,
  ROUTE_TICKETING,
} from '../../constants/routes';
import translations from '../../constants/translations.json';
import { ReactComponent as TikTokIcon } from '../../svg/TikTokWhite.svg';
import { ReactComponent as InstagramIcon } from '../../svg/instagramWhite.svg';
import { ReactComponent as XWhiteIcon } from '../../svg/xComeWhite.svg';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

type LinkType = { to: string; text: string } | { href: string; text: string };

const isToLink = (link: LinkType): link is { to: string; text: string } => {
  return (link as { to: string }).to !== undefined;
};

export const Footer = () => {
  const location = useLocation();

  const prevSearch = useRef(location.search);

  useEffect(() => {
    if (location.search !== prevSearch.current) {
      prevSearch.current = location.search;
      window.location.reload();
      window.scrollTo(0, 0);
    }
  }, [location.search]);
  return (
    <footer
      className={classNames([
        'z-[8]  flex justify-between pt-[56px] pb-[32px]',
        'lg:pt-[64px]',
        'xl:bg-none xl:relative xl:z-[8]',
        'max-xl:bg-black border-t border-t-gray-medium',
      ])}
    >
      <div className="max-w-full lg:mx-[64px] mx-[20px] flex flex-wrap justify-between items-start ">
        {/* <div
          className={classNames([
            'w-full',
            'max-md:mb-[36px]',
            'md:w-1/3',
            'xl:w-[335px]',
          ])}
        >
          <h4 className="text-white text-[2rem] font-light mb-[24px] leading-[40px]">
            {translations.footerText.title}
          </h4>
          <p className="text-[1.0625rem] text-gray-light font-light leading-[22px] mb-[24px]">
            {translations.footerText.desc}
          </p>
          <div>
            <div className='flex flex-row'>
            <p className="block text-generic text-[0.875rem] font-light mb-[8px]">
              {translations.footerText.eMail}
            </p>
            <span className={classNames( 'text-content')}>*</span>
            </div> 
            <Input
              type="email"
              placeholder="Enter your e-mail..."
              className="w-full  text-white mb-[24px]"
              id="footer"
            />
          </div>
          <Button
            isFooter={true}
            color="village"
            type="button"
            className=" rounded-tl-[20px] rounded-br-[20px] w-[181px]"
          >
            {translations.footerText.join}
          </Button>
        </div> */}
        {/* 57 */}
        <div className="max-md:w-full max-lg:w-[45%] lg:flex lg:justify-center lg:items-start lg:w-[100%]">
          <div className="grid max-md:w-full max-md:grid-cols-2 max-md:gap-[36px] lg:flex lg:gap-[48px]">
            {[
              {
                title: translations.footerText.cene,
                links: [
                  // { to: ROUTE_DOWNLOAD, text: translations.footerText.getApp },
                  { to: ROUTER_ABOUT, text: translations.footerText.whoWeAre },
                  {
                    to: ROUTE_MEMBERSHIP,
                    text: translations.footerText['cene+'],
                  },
                  {
                    to: ROUTE_DIVERSITY,
                    text: translations.footerText.accessibility,
                  },
                  { to: ROUTE_CAREERS, text: translations.footerText.careers },
                ],
                width: 'lg:w-[176px]',
              },
              {
                title: translations.footerText.support,
                links: [
                  {
                    to: 'http://cenexyz.zendesk.com/',
                    text: translations.footerText.helpCentre,
                  },
                  { to: ROUTE_FAQ, text: translations.footerText.faq },
                  {
                    to: ROUTE_CONTACT_WITH_US,
                    text: translations.footerText.touch,
                  },
                ],
                width: 'lg:w-[137px]',
              },
              {
                title: translations.footerText.work,
                links: [
                  { to: ROUTE_BURST, text: translations.footerText.partner },
                  {
                    to: ROUTE_PARTNER_WITH_US,
                    text: translations.footerText.becomePartner,
                  },
                  { to: ROUTE_TICKETING, text: translations.footerText.plans },
                  {
                    to: 'https://cene-admin.web.app/',
                    text: translations.footerText.partnerSignIn,
                  },
                ],
                width: 'lg:w-[173px]',
              },
              {
                title: translations.footerText.social,
                links: [
                  {
                    href: 'https://x.com/getcene?s=21',
                    text: translations.footerText.xSocial,
                    icon: <XWhiteIcon className="ml-3" />,
                  },
                  {
                    href: 'https://www.instagram.com/getcene/?igsh=MWFrdzZ0eXZ3bng3Yg%3D%3D&utm_source=qr',
                    text: translations.footerText.instagram,
                    icon: <InstagramIcon className="ml-3" />,
                  },
                  {
                    href: 'https://www.tiktok.com/@getcene?_t=8qQQ1NNlHtN&_r=1',
                    text: translations.footerText.tikTok,
                    icon: <TikTokIcon className="ml-3" />,
                  },
                  {
                    href: 'https://web.snapchat.com/',
                    text: translations.footerText.snapChat,
                  },
                ],
                width: 'lg:w-[119px]',
              },
            ].map(({ title, links, width }, index) => (
              <div
                key={index}
                className={`flex flex-col items-start space-y-2 sm:w-auto ${width}`}
              >
                <p className="text-gray-light text-[0.875rem] leading-[18px] font-light mb-[22px]">
                  {title}
                </p>
                {links.map((link, linkIndex) =>
                  isToLink(link) ? (
                    <Link
                      key={linkIndex}
                      to={link.to}
                      className="bg-gray-dark h-[48px] font-medium  hover:bg-gray-medium leading-[24px] text-[1rem] text-white px-[24px] py-[12px] rounded-tl-[20px] rounded-br-[20px] text-center whitespace-nowrap"
                    >
                      {link.text}
                    </Link>
                  ) : (
                    <a
                      key={linkIndex}
                      href={link.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-gray-dark h-[48px] hover:bg-gray-medium  flex flex-row font-medium text-[1rem] leading-[24px] text-white px-[24px] py-[12px] rounded-tl-[20px] rounded-br-[20px] text-center whitespace-nowrap"
                    >
                      {link.text}
                      {link.icon}
                    </a>
                  )
                )}
              </div>
            ))}
          </div>
        </div>

        <div
          className={classNames([
            'flex flex-col items-center w-full mt-[24px]',
            'max-md:mt-[36px]',
            'lg:items-end lg:flex-row-reverse border-t border-t-gray-medium',
          ])}
        >
          <div
            className={classNames([
              'max-w-full flex flex-wrap justify-between items-start mt-[24px]',
              'md:flex-row-reverse md:w-full',
            ])}
          >
            <div className="flex max-md:gap-[72px] gap-[24px]">
              <div
                className={classNames([
                  'flex flex-col gap-[24px]',
                  'md:flex-row lg:space-x-4 lg:mr-0',
                ])}
              >
                <Link
                  to={`${ROUTE_TERMS}?index=1`}
                  className="text-[0.75rem] text-white  lg:mb-0 "
                >
                  {translations.footerText.terms}
                </Link>
                <Link
                  to={`${ROUTE_TERMS}?index=0`}
                  className="text-[0.75rem] text-white lg:mb-0"
                >
                  {translations.footerText.privacy}
                </Link>
              </div>
              <div className="flex flex-col gap-[24px] md:flex-row lg:space-x-4">
                <Link
                  to={`${ROUTE_TERMS}?index=2`}
                  className="text-[0.75rem] text-white lg:mb-0"
                >
                  {translations.footerText.purchase}
                </Link>
                <Link
                  to={`${ROUTE_TERMS}?index=3`}
                  className="text-[0.75rem] text-white lg:mb-0"
                >
                  {translations.footerText.cookie}
                </Link>
              </div>
            </div>
            <div
              className={classNames([
                'text-[0.75rem] text-white',
                'max-md:w-full max-md:mt-[24px]',
              ])}
            >
              {translations.footerText.reserved}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
