import Steps from '../../../../components/steps/steps';
import { usePreventUnload } from '../../../../hooks/usePreventUpload';
import {
  RegisterGuestSchema,
  newRegisterSchema,
} from '../../../../schemas/registerSchema';
import { supabaseCeneClient } from '../../../../supabase/supabase';
import { GenderAndBirthForm } from '../../genderAndBirthStep/cene/genderAndBirthForm';
import { GenrePreferencesForm } from '../../genrePreferencesStep/cene/genrePreferences';
import { InterestPreferencesForm } from '../../interestPreferencesStep/cene/interestPreferences';
import { NameForm } from '../../nameStep/cene/nameForm';
import { Notifications } from '../../notificationStep/notificationForm';
// import { VerifyForm } from '../../otpStep/cene/verifyForm';
import { PasswordForm } from '../../passwordStep/cene/passwordForm';
import { PhoneForm } from '../../phoneStep/cene/phoneForm';
import { countryCodes } from '../../phoneStep/countryCodes';
import { Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface RegisterFormProps {
  onLoading: () => void;
}

export const RegisterGuestForm = ({ onLoading }: RegisterFormProps) => {
  const [activeStep, setStep] = useState(1);
  const [accessToken, setAccessToken] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const hashParams = new URLSearchParams(window.location.hash.substring(1));
    setAccessToken(hashParams.get('access_token') || '');
    setRefreshToken(hashParams.get('refresh_token') || '');
  }, []);

  useEffect(() => {
    const getSessionWithTokens = async () => {
      if (accessToken && refreshToken) {
        const { error } = await supabaseCeneClient.auth.setSession({
          access_token: accessToken,
          refresh_token: refreshToken,
        });

        if (error) {
          alert(`Error signing in: ${error.message}`);
        }
      }
    };

    if (accessToken && refreshToken) {
      getSessionWithTokens();
    }
  }, [accessToken, refreshToken]);

  const [isFormDirty, setIsFormDirty] = useState(false);
  usePreventUnload(isFormDirty);

  const onBackStep = useCallback(() => {
    if (activeStep === 1) return navigate(-1);

    setStep(activeStep - 1);
  }, [activeStep]);

  const nextStep = useCallback(
    (values?: RegisterGuestSchema) => {
      if (activeStep === 10 && values) {
        return onSubmit(values);
      }

      setStep(activeStep + 1);
    },
    [activeStep]
  );

  const defaultCountry = countryCodes.find((_, index) => index === 0);

  const onSubmit = async (values: RegisterGuestSchema) => {
    const { data: userData } = await supabaseCeneClient.auth.updateUser({
      password: values.password,
    });

    const { error } = await supabaseCeneClient
      .from('customers')
      .update([
        {
          interests: values.interests,
          genres: values.genres,
          notifications: values?.notifications,
          onboarding_finished: true,
        },
      ])
      .eq('user_id', userData.user?.id);

    if (!error) onLoading();
  };

  const isSkip = activeStep === 8 || activeStep === 9 || activeStep === 10;

  return (
    <Formik<RegisterGuestSchema>
      initialValues={{
        firstName: '',
        lastName: '',
        gender: 'Male',
        dateOfBirth: null,
        country: `${defaultCountry?.title}`,
        countryCode: '',
        phone: '',
        genres: [],
        interests: [],
        password: '',
        confirmPassword: '',
        agreement: false,
        notifications: [],
      }}
      onSubmit={() => {}}
      validationSchema={newRegisterSchema}
    >
      {/* setFieldValue */}
      {({ values, dirty }) => {
        useEffect(() => {
          setIsFormDirty(dirty);
        }, [dirty]);

        return (
          <Steps
            hideBackButton={activeStep === 6 || activeStep === 7}
            activeStep={activeStep}
            onBack={onBackStep}
            onSkip={() => {
              // if (activeStep === 8) setFieldValue('genres', []);
              // if (activeStep === 9) setFieldValue('interests', []);
              if (activeStep === 5) {
                return onSubmit({ ...values, notifications: [] });
              }
              nextStep();
            }}
            canSkip={isSkip}
          >
            <NameForm onNext={nextStep} />
            <GenderAndBirthForm onNext={nextStep} />
            <PhoneForm onNext={nextStep} />
            <PasswordForm />
            <GenrePreferencesForm onNext={nextStep} />
            <InterestPreferencesForm onNext={nextStep} />
            <Notifications onNext={() => onSubmit(values)} />
          </Steps>
        );
      }}
    </Formik>
  );
};
