import translations from '../../../constants/translations.json';
import { ICategory } from '../../../types/category';

const tickets: ICategory = {
  label: 'Your Tickets',
  children: [
    {
      block: 'Using Your Ticket',
      items: [
        {
          question: 'Why Can’t I Find My Tickets While Logged In?',
          answer: (
            <p>
              {translations.tickets.answerFirst.split('two (2) hours')[0]}
              <span className="font-[700]">two (2) hours</span>
              {translations.tickets.answerFirst.split('two (2) hours')[1]}
            </p>
          ),
        },
        {
          question: 'I Bought a Ticket But Didn’t Receive It—What Now?',
          answer: (
            <p className="whitespace-pre-line">
              {translations.tickets.answerTwo.split('support@cene.xyz')[0]}
              <a className="underline" href="mailto:support@cene.xyz">
                support@cene.xyz
              </a>
              {translations.tickets.answerTwo.split('support@cene.xyz')[1]}
            </p>
          ),
        },
        {
          question: 'How Do I Use My Ticket at an Event?',
          answer: <p>{translations.tickets.answerThree}</p>,
        },
        {
          question: 'Are My Tickets Secure?',
          answer: <p>{translations.tickets.answerFour}</p>,
        },
      ],
    },
    {
      block: 'Sharing Your Ticket',
      items: [
        {
          question: 'How Do I Share Tickets with My Tribe?',
          answer: (
            <p className=" whitespace-pre-line">
              {translations.tickets.answerFive.split('two (2) hours')[0]}
              <span className="font-[700]">two (2) hours</span>
              {translations.tickets.answerFive.split('two (2) hours')[1]}
            </p>
          ),
        },
        {
          question:
            'What Should I Do Now That My Friend Has Shared a Ticket with Me?',
          answer: (
            <>
              <p className="whitespace-pre-line">
                Once your friend shares a ticket with you, you&apos;ll get an
                email confirming that they sent it. After that, just follow
                these steps:
              </p>
              <ol className="list-decimal list-inside ml-4 my-2">
                <li>
                  Make sure you’ve downloaded the Cene or Cene+ app (if you
                  haven’t already).
                </li>
                <li>Log into your account.</li>
                <li>
                  Head to the &quot;My Tickets&quot; section to find your shared
                  ticket.
                </li>
              </ol>
              <p className="whitespace-pre-line">
                Just a heads up: the QR code for entry will only pop up{' '}
                <span className="font-[700]">two (2) hours</span> before the
                doors open.
              </p>
            </>
          ),
        },
        {
          question:
            'I Didn’t Receive the Ticket My Friend Sent Me. What Should I Do?',
          answer: (
            <p className="whitespace-pre-line">
              {translations.tickets.answerSeven.split('support@cene.xyz')[0]}
              <a className="underline" href="mailto:support@cene.xyz">
                support@cene.xyz
              </a>
              {translations.tickets.answerSeven.split('support@cene.xyz')[1]}
            </p>
          ),
        },
        {
          question:
            'Can I Transfer My Tickets to Someone Else if I Can’t Attend the Event?',
          answer: <p>{translations.tickets.answerEight}</p>,
        },
        {
          question: 'Can I Transfer My Ticket to a Different Event?',
          answer: <p>{translations.tickets.answerNine}</p>,
        },
      ],
    },
  ],
};

export default tickets;
