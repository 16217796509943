import { Ticket, TicketRequest } from '../../pages/myBasket/myBasket';
import {
  supabaseCeneClient,
  supabaseCenePlusClient,
} from '../../supabase/supabase';
// import { ReactComponent as InfoSVG } from '../../svg/warning-circle.svg';
import { Button } from '../button/button';
import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface CardProps {
  ticketRequest: TicketRequest;
  isCene?: boolean;
  color?: string;
  isActive?: boolean;
  onClick?: () => void;
}

enum Status {
  NEW = 'new',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  EXPIRED = 'expired',
  CANCELLED = 'cancelled',
}

const RequestCard = ({
  ticketRequest,
  isCene,
  color,
  isActive = false,
}: CardProps) => {
  const navigate = useNavigate();
  const [timeRemaining, setTimeRemaining] = useState<string>('');
  const [tax, setTax] = useState();

  console.log(tax);

  if (!ticketRequest.event) return <></>;
  const { featured_image, category, name, address, starts_at, tickets } =
    ticketRequest.event;

  const ticket = ticketRequest.details
    .map((detail) => tickets?.find((t) => t.id === detail.ticket_name_id))
    .find((t) => t !== undefined);

  const ticketPrice = ticket ? ticket.NGN || 0 : 0;

  const amountDue =
    ticketPrice *
    ticketRequest.details.reduce((sum, detail) => sum + detail.quantity, 0);

  const amountDueFormatted = amountDue.toLocaleString('uk-UA', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const result = ticketRequest.details
    .map((ticket) => `${ticket.quantity}x ${ticket.name}`)
    .join(', ');

  useEffect(() => {
    setTimeRemaining(calculateTimeRemaining());

    const interval = setInterval(() => {
      const updatedTime = calculateTimeRemaining();
      setTimeRemaining(updatedTime);

      if (updatedTime === 'Expired') {
        clearInterval(interval);
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [ticketRequest.expires_at]);

  const renderStatusText = () => {
    switch (ticketRequest.status) {
      case Status.APPROVED:
        return 'CONFIRM PURCHASE';
      case Status.REJECTED:
        return 'REQUEST REJECTED';
      case Status.EXPIRED:
        return 'REQUEST EXPIRED';
      case Status.CANCELLED:
        return 'REQUEST CANCELLED';
      case Status.NEW:
      default:
        return 'IN PROGRESS';
    }
  };

  const calculateTimeRemaining = () => {
    const now = moment();
    const expiresAt = moment(ticketRequest.expires_at);
    const duration = moment.duration(expiresAt.diff(now));

    if (duration.asSeconds() <= 0) {
      return 'Expired';
    }

    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  };

  const getTax = async () => {
    const ticketDetails = ticketRequest.details.map((ticket) => ({
      quantity: ticket.quantity,
      ticket_name_id: ticket.ticket_name_id,
    }));

    try {
      const client = isCene ? supabaseCeneClient : supabaseCenePlusClient;
      const { data } = await client.rpc(
        'build_event_order_finance_details_v2',
        {
          currency: 'NGN',
          event_id: ticketRequest.event_id,
          ordered_tickets: ticketDetails,
        }
      );
      setTax(data);
      return data;
    } catch (error) {
      console.error('Error fetching tax:', error);
      throw error;
    }
  };

  console.log(tax);

  const handlePayNow = async (eventId: string, tickets: Ticket[]) => {
    try {
      const calculatedTax = await getTax();
      if (calculatedTax) {
        navigate(`/event-details/${eventId}`, {
          state: {
            selectedTickets: tickets,
            openPaymentModal: true,
            transaction: ticketRequest.transaction,
            selectedTax: calculatedTax,
          },
        });
      } else {
        console.error('Tax calculation failed. No tax data available.');
      }
    } catch (error) {
      console.error('Error during payment process:', error);
    }
  };

  return (
    <div className={classNames('max-w-full w-full relative h-[520px]')}>
      <div
        className={classNames(
          'flex w-full static items-center justify-between min-h-[2.5rem]'
        )}
      >
        <div className={classNames('flex items-center gap-[0.5rem]')}>
          <p
            className={classNames(
              'uppercase text-white text-[1rem]/[1.3125rem] font-light'
            )}
          >
            {renderStatusText()}
          </p>
          {/* <InfoSVG /> */}
        </div>
        {ticketRequest.status === Status.APPROVED && (
          <div
            className={classNames(
              'border border-generic-light bg-gray-medium flex justify-center items-center px-[1rem] py-[0.5rem] rounded-tl-[1rem] rounded-br-[1rem]'
            )}
          >
            <span
              className={classNames(
                'text-white text-[1rem]/[1.3125rem] font-light'
              )}
            >
              {timeRemaining}
            </span>
          </div>
        )}
      </div>
      <div className="max-w-full relative h-[336px] mt-[24px] overflow-hidden">
        <img
          src={featured_image.url || ''}
          alt={name}
          className="max-w-full rounded-tl-[30px] rounded-br-[30px]"
        />
        <div className="auto bg-card-gradient absolute w-full h-[110%] top-0 -bottom-3" />

        <div
          className={classNames(
            'w-full max-w-[176px] flex flex-col mt-[1rem] absolute bottom-5 left-5',
            isCene ? 'text-ticket' : 'text-generic'
          )}
        >
          <span
            className={classNames(
              'uppercase text-[1rem]/[1.3125rem] font-light',
              isCene
            )}
          >
            {category}
          </span>
          <h3
            title={name}
            style={{ color: color }}
            className={classNames(
              'text-[2rem]/[2.5rem] font-light truncate max-w-full'
            )}
          >
            {name}
          </h3>
        </div>

        <div
          className={classNames(
            'mt-[1rem] w-[72px] text-start absolute top-5 left-5',
            'text-[0.875rem]/[0.875rem] font-light',
            'text-white'
          )}
        >
          <p
            title={address}
            className="w-full h-[30px] text-ellipsis overflow-hidden line-clamp-2"
          >
            {address.split(',')[0]}
          </p>
          <p className={classNames('mt-[1rem]')}>
            {moment(starts_at, 'YYYY-MM-DD HH:mm').format('Do MMM HH:mm')}
          </p>
        </div>
      </div>

      <div
        className={classNames(
          'w-full max-w-[272px] flex flex-col mt-[1rem] absolute left-1/2 -translate-x-1/2'
        )}
      >
        <div
          className={classNames(
            'w-full flex items-center justify-between text-white text-[1.25rem/1.75rem] font-light'
          )}
        >
          <p>Amount Due</p>
          <span>₦ {amountDueFormatted}</span>
        </div>
        <p
          className={classNames(
            'text-[1rem]/[1.3125rem] font-light mt-[0.5rem] mb-[1.5rem]',
            isCene ? 'text-ticket' : 'text-generic'
          )}
        >
          {result}
        </p>

        {ticketRequest.status === Status.APPROVED && isActive && (
          <Button
            type="button"
            color={isCene ? 'ticket' : 'generic'}
            onClick={() =>
              handlePayNow(ticketRequest.event_id, ticketRequest.details)
            }
          >
            Pay Now
          </Button>
        )}
      </div>
    </div>
  );
};

export default RequestCard;
