/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactComponent as DividerSvg } from '../../../src/svg/divider.svg';
import { ReactComponent as TrashSvg } from '../../../src/svg/trashGeneric.svg';
import { Loader } from '../../components/loader/cenePlus/loader';
import { ROUTER_MY_TICKETS } from '../../constants/routes';
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';
import { usePayment } from '../../hooks/useSendPayment';
import { useCenePlusCart } from '../../providers/cenePlusBasket';
import { supabaseCenePlusClient } from '../../supabase/supabase';
import { ReactComponent as ArmchairSVG } from '../../svg/Armchair.svg';
import { ReactComponent as CalendarBlankSvg } from '../../svg/calendarPlus.svg';
import { ReactComponent as CloseSVG } from '../../svg/close.svg';
import { ReactComponent as TableSVG } from '../../svg/home-table.svg';
import { ReactComponent as MapSvg } from '../../svg/mapTrifold.svg';
import { CartSummary } from './cart.summary';
import { Event, LocalOrderDetails } from '@ceneteam/namespace';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { usePaystackPayment } from 'react-paystack';
import { useNavigate } from 'react-router-dom';

interface ICartModal {
  event: Event;
  onCloseModal: () => void;
  onRemoveItem: (id: string) => void;
}

export const CartModal = ({
  event,
  onCloseModal,
  onRemoveItem,
}: ICartModal) => {
  const { cart } = useCenePlusCart();
  const navigation = useNavigate();
  const [isLoadingPayment, setLoadingPayment] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [orderInformation, setOrderInformation] =
    useState<LocalOrderDetails | null>(null);

  const publicKey = process.env.REACT_APP_PAY_STACK_KEY || '';
  const initializePayment = usePaystackPayment({ publicKey });

  const filterTables = cart.map((item) =>
    item.selectableObject.objectType === 'Table'
      ? {
          ticket_name_id: item.id,
          full_table: true,
          table_id: item.selectableObject.labels.own,
        }
      : {
          ticket_name_id: item.id,
          table_id: item.selectableObject.labels.parent,
          seat_ids: [item.selectableObject.labels.own],
        }
  ) as any;

  const { onSendPayment } = usePayment({
    isSeatsio: true,
    totalPrice: orderInformation?.total_gross_amount || 0,
    tickets: filterTables,
    quantity: cart.reduce((a, v) => ({ ...a, [v.id]: v.quantity }), {}),
    id: event.id,
    initializePayment,
    setIsPaymentSuccessful: () => {
      navigation(ROUTER_MY_TICKETS);
    },
    setIsPaymentError: () => {
      setLoadingPayment(false);
    },
  });

  useDisableBodyScroll(true);

  useEffect(() => {
    window.scroll({ top: 0 });
  }, []);

  useEffect(() => {
    if (cart.length === 0) onCloseModal();

    fetchTicketsInformation();
  }, [cart]);

  const fetchTicketsInformation = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabaseCenePlusClient.rpc(
        'build_event_order_finance_details_v2',
        {
          currency: 'NGN',
          event_id: event.id,
          ordered_tickets: cart.map((item) => ({
            ticket_name_id: item.id,
            quantity: item.quantity,
          })),
        }
      );

      if (error) throw error;

      setOrderInformation(data as LocalOrderDetails);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemove = (id: string) => {
    onRemoveItem(id);
  };

  const renderTablesItems = cart
    .filter((item) => item.selectableObject.objectType === 'Table')
    .map((item) => (
      <div
        className="w-full item-center items-center justify-between text-generic text-[1rem] font-light gap-2 flex"
        key={item.id}
      >
        <div className="flex item-center items-center gap-2">
          <div>{item.selectableObject.labels.section}</div>
          <div>Table {item.selectableObject.labels.own}</div>
          <div>
            Seats{' '}
            {(item.selectableObject as any).seats
              .map((_: any, i: any) => i + 1)
              .join(',')}
          </div>
        </div>
        <button
          onClick={() => handleRemove(item.selectableObject.seatId)}
          className="w-[32px] h-[32px] border border-gray-medium rounded-tl-[6px] rounded-br-[6px] flex justify-center items-center"
        >
          <TrashSvg />
        </button>
      </div>
    ));

  const renderSeatsItems = cart
    .filter((item) => item.selectableObject.objectType === 'Seat')
    .map((item) => (
      <div
        className="w-full item-center items-center justify-between text-generic text-[1rem] font-light gap-2 flex"
        key={item.id}
      >
        <div className="flex item-center items-center gap-2">
          <div>{item.selectableObject.labels.section}</div>
          <div>Table {item.selectableObject.labels.parent}</div>
          <div>Seat {item.selectableObject.labels.own}</div>
        </div>
        <button
          onClick={() => handleRemove(item.selectableObject.seatId)}
          className="w-[32px] h-[32px] border border-gray-medium rounded-tl-[6px] rounded-br-[6px] flex justify-center items-center"
        >
          <TrashSvg />
        </button>
      </div>
    ));

  return (
    <>
      <div
        onClick={onCloseModal}
        className="absolute z-[99999] top-[20px] right-[20px] cursor-pointer z-[1] opacity-[0.8] hover:opacity-[1]"
      >
        <CloseSVG />
      </div>
      <div className="left-0 top-[0] absolute z-[11229] flex justify-center w-full h-full">
        <div className="overflow-y-auto max-h-[calc(100vh-30px)] p-[20px] mt-[20px] bg-black border-solid border border-[rgba(184,202,217,0.16)] absolute w-[532px] rounded-br-[30px] rounded-tl-[30px]">
          <div className="flex items-center">
            <div className="w-[119px] h-[154px] relative">
              <div className="bg-event-gradient absolute w-full h-full" />
              <img
                className="min-w-[119px] min-h-[154px] object-cover"
                src={event?.featured_image.url}
                alt={event?.name}
              />
            </div>
            <div className="ml-[32px] max-w-[346px]">
              <h2 className="text-generic text-[1.32rem] font-light">
                {event?.name}
              </h2>
              <div className="mt-[17.5px] flex gap-[12px]">
                <MapSvg className="min-w-[24px] min-h-[24px]" />
                <label
                  title={event?.address}
                  className="line-clamp-3 text-generic text-[1rem] font-light"
                >
                  {event?.address}
                </label>
              </div>
              <div className="mt-[17.5px] flex gap-[12px]">
                <CalendarBlankSvg className="min-w-[24px] min-h-[24px]" />
                <label className="text-generic text-[1rem] font-light">
                  {moment(event?.starts_at, 'YYYY-MM-DD HH:mm').format(
                    'dddd, MMMM D · HH:mm'
                  )}
                </label>
              </div>
            </div>
          </div>

          {isLoading ? (
            <div className="flex items-center justify-center h-[200px] mt-[33px]">
              <div className="flex h-[120px] w-[120px]">
                <Loader />
              </div>
            </div>
          ) : (
            <div className="mt-[33px]">
              <div className="text-white text-[1.10rem] font-light">
                Your basket
              </div>
              {renderTablesItems.length !== 0 && (
                <>
                  <div className="mt-[16px]">
                    <div className="flex items-center gap-[10px]">
                      <TableSVG />
                      <div className="text-generic text-[1rem] font-light">
                        {renderTablesItems.length}x Table
                      </div>
                    </div>
                    <div className="flex flex-col mt-[10px] gap-1">
                      {renderTablesItems}
                    </div>
                  </div>
                  <DividerSvg className="my-[15px] w-full" />
                </>
              )}

              {renderSeatsItems.length !== 0 && (
                <>
                  <div className="mt-[16px]">
                    <div className="flex items-center gap-[10px]">
                      <ArmchairSVG />
                      <div className="text-generic text-[1rem] font-light">
                        {renderSeatsItems.length} Seats
                      </div>
                    </div>
                    <div className="flex flex-col mt-[10px] gap-1">
                      {renderSeatsItems}
                    </div>
                  </div>
                  <DividerSvg className="my-[15px] w-full" />
                </>
              )}

              {orderInformation && (
                <CartSummary
                  isLoading={isLoadingPayment}
                  onSendPayment={() => {
                    setLoadingPayment(true);
                    onSendPayment();
                  }}
                  orderSummary={orderInformation}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className="absolute top-[0] w-full h-full left-[0] z-[222] bg-black/30 backdrop-blur-md p-4 rounded-lg" />
    </>
  );
};
