import NotificationModal from '../../components/notificationModal/notificationModal';
import translations from '../../constants/translations.json';
import { useSupabase } from '../../providers/supabase';
import { ReactComponent as BellSVG } from '../../svg/bell.svg';
import { ReactComponent as CloseSVG } from '../../svg/closeGeneric.svg';
import { ReactComponent as UserPlaceholderSVG } from '../../svg/sdoer.svg';
import classNames from 'classnames';
import { useState } from 'react';

interface ProfileMenuProps {
  isProfileMenuOpen: boolean;
  handleProfileClick: () => void;
  profileMenuRef: React.RefObject<HTMLDivElement>;
  mapProfileMenu: () => JSX.Element;
  isBurgerMenuOpen: boolean;
}

export const ProfileMenu: React.FC<ProfileMenuProps> = ({
  isProfileMenuOpen,
  handleProfileClick,
  profileMenuRef,
  mapProfileMenu,
  isBurgerMenuOpen,
}) => {
  const { session } = useSupabase();
  const sessionType = session?.type === 'cene';

  const isFaqPage = window.location.pathname === '/faqs';
  const isTiersPage =
    window.location.pathname === '/workwithus/tiers-and-plans';
  const isPartnersPage =
    window.location.pathname === '/workwithus/cene-partners';

  const [isNotificationsOpen, setNotificationsOpen] = useState(false);

  const toggleNotificationModal = () => {
    setNotificationsOpen(!isNotificationsOpen);
  };

  return (
    <>
      {!isBurgerMenuOpen && (
        <>
          <div
            className="w-[40px] h-[40px] lg:w-[48px] lg:h-[48px] rounded-tl-[16px] rounded-br-[16px]  items-center justify-center bg-gray-dark lg:ml-[24px] ml-[8px] flex z-[10] "
            onClick={toggleNotificationModal}
          >
            <BellSVG />
          </div>
          <div
            className="relative lg:flex  items-center justify-center z-50"
            onClick={handleProfileClick}
            ref={profileMenuRef}
          >
            {session?.avatar ? (
              <div className="relative cursor-pointer">
                <img
                  src={session?.avatar}
                  alt="Avatar"
                  className="ml-[8px] lg:w-[48px] lg:h-[48px] rounded-tl-[16px] rounded-br-[16px] border w-[40px] h-[40px]"
                />
              </div>
            ) : (
              <UserPlaceholderSVG
                style={{ stroke: sessionType ? '#EC76F7' : '#B8CAD9' }}
                className={classNames([
                  'cursor-pointer',
                  'w-[40px] ml-[8px] lg:ml-0 h-[40px] rounded-full lg:w-[48px] lg:h-[48px]',
                  isFaqPage ? 'hidden lg:flex' : 'flex',
                ])}
              />
            )}

            {isProfileMenuOpen && (
              <>
                <div
                  className={classNames([
                    'absolute right-0  bg-black h-screen rounded-lg shadow-lg z-50 top-full border border-l-gray-medium border-b-gray-medium  py-[20px] pl-[20px] space-y-[16px] w-[206px]',
                    ' lg:h-auto lg:mt-2 lg:border-gray-medium lg:rounded-tl-[30px] lg:rounded-br-[30px] lg:p-[20px] ',
                    isTiersPage || isPartnersPage ? 'mt-[20px]' : 'mt-[26px]',
                  ])}
                >
                  {mapProfileMenu()}

                  <div className=" lg:hidden w-full h-[1px] bg-gray-medium" />
                  <button
                    className="w-full h-[40px] lg:hidden flex items-center px-[24px] border border-gray-medium rounded-tl-[16px] rounded-br-[16px] hover:bg-gray-medium  "
                    onClick={() => handleProfileClick()}
                  >
                    <CloseSVG className="mr-[10px]" />
                    <p className="text-generic text-[1rem] leading-[24px] font-medium">
                      {translations.header.close}
                    </p>
                  </button>
                </div>
              </>
            )}
          </div>
        </>
      )}
      <NotificationModal
        isOpen={isNotificationsOpen}
        onClose={toggleNotificationModal}
        isCene={sessionType}
      />
    </>
  );
};
