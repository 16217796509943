import { ReactComponent as AppStoreSvg } from '../../../../src/svg/appStoreCeneCta.svg';
import { ReactComponent as GoogleStoreSvg } from '../../../../src/svg/googleStore.svg';
import { Button } from '../../../components/button/button';
import { ROUTE_DOWNLOAD } from '../../../constants/routes';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

interface DiscoverSectionProps {
  title: string;
  desc: string;
  sessionType?: string;
  buttonText: string;
  favourite?: boolean;
  similar?: boolean;
}

const Phone = '/pictures/groupPhone.png';
const PhonePlus = '/pictures/phoneMyCene.png';

export const DiscoverSection: React.FC<DiscoverSectionProps> = ({
  title,
  favourite,
  desc,
  buttonText,
  sessionType,
  similar,
}) => {
  const navigate = useNavigate();
  const session = sessionType == 'cene' || sessionType == undefined;
  const sessionText = session ? 'text-white z-[4] ' : 'text-black ';
  const sessionBg = session
    ? 'bg-loading-bg-new lg:bg-loading-bg'
    : 'lg:bg-gradient-plus bg-generic lg:mb-[76px]';
  const heightClass = !favourite && session ? 'lg:h-[112px]' : '';

  return (
    <div
      className={classNames([
        'relative rounded-tl-[30px] rounded-br-[30px] mb-[40px] lg:mb-0 h-auto',
        ' lg:h-auto lg:w-full w-full',
        similar ? '!lg:my-[72px]' : 'lg:mb-[72px]',
        sessionBg,
      ])}
    >
      <div
        className={classNames([
          'flex flex-col justify-between w-full  lg:mt-0 ',
          'lg:flex-row',
        ])}
      >
        <div
          className={classNames([
            'flex flex-col items-start ',
            'lg:w-full lg:flex-row lg:items-center ',
          ])}
        >
          <div
            className={classNames([
              'ml-0 mb-[42px] w-full px-[24px] lg:px-0 mt-[24px] lg:mt-0',
              'lg:w-[532px]  lg:ml-[101px] lg:mb-0 lg:flex-col lg:justify-center lg:flex',
              session ? 'lg:h-[460px]' : 'lg:h-[555px]',
            ])}
          >
            <div
              className={classNames([
                'lg:w-[532px]',
                heightClass,
                'w-full h-auto',
              ])}
            >
              <h2
                className={classNames([
                  ' text-[1.5rem] leading-[36px] font-light',
                  'lg:text-[3rem] lg:leading-[56px] lg:w-[588px]',
                  sessionText,
                ])}
              >
                {title}
              </h2>
            </div>
            <div
              className={classNames([
                'w-full lg:mb-[24px] mb-[32px]',
                ' lg:w-[414px] lg:h-[63px]',
              ])}
            >
              <p
                className={classNames([
                  ' lg:mt-[16px] lg:mb-[16px] text-start relative font-light text-[1.1875rem] leading-[19px]',
                  'lg:mt-[24px] lg:mb-[24px] lg:text-[1.3125rem] lg:leading-[21px] mt-[16px] ',
                  sessionText,
                ])}
              >
                {desc}
              </p>
            </div>
            {session ? (
              <Button
                className={classNames([
                  'z-[4] relative ',
                  ' w-[156px] h-[48px] lg:-mb-[42px] font-medium mt-[24px]',
                ])}
                color="white"
                type="button"
                isFooter={true}
                onClick={() => navigate(ROUTE_DOWNLOAD)}
              >
                <p className="font-medium"> {buttonText}</p>
              </Button>
            ) : (
              <div className="flex flex-row space-x-[12px] z-10 ">
                <a
                  href="https://apps.apple.com/app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AppStoreSvg />
                </a>
                <a
                  href="https://play.google.com/store/apps"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <GoogleStoreSvg />
                </a>
              </div>
            )}
          </div>

          <img
            src={session ? Phone : PhonePlus}
            alt="Phone"
            className={classNames([
              'items-start flex justify-start mt-[16px] lg:mt-0 object-cover',
              '2xl:w-auto lg:w-auto lg:right-[100px]  2xl:h-[460px] lg:absolute 2xl:right-[181px] lg:bottom-0 w-full px-[20px] lg:px-0',
              session ? 'lg:h-[400px]' : 'lg:h-[555px] lg:right-[50px]',
            ])}
          />
        </div>
      </div>
      {session && (
        <div className="absolute bottom-0 left-0 right-0 h-[170px] bg-gradient-desc lg:h-[278px]" />
      )}
    </div>
  );
};
