const termsOfUse = {
  label: 'Terms of Use',
  content: (
    <div>
      <p className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light">
        Please read these Terms of Use carefully as they set out the basis on
        which you are allowed to use the Cene mobile software applications (the
        “App”) or the websites (together with the App, collectively the
        “Services”) offered by Cene Entertainment Limited (“Cene”, “we”, “us” or
        “our”). If you have any questions about them or do not accept them,
        please contact us at{' '}
        <a href="mailto:support@cene.xyz" className="text-blue-500 underline">
          support@cene.xyz
        </a>{' '}
        before registering with the Services. We may change these Terms of Use
        from time to time so please check our App or website at{' '}
        <a
          href="https://cene.xyz/terms?index=1"
          className="text-blue-500 underline"
        >
         cene.xyz/terms
        </a>{' '}
        from time to time for any updates to them to ensure that you are happy
        with any changes. Your continued use of any part of the Services
        following any update to these Terms of Use will constitute your
        acceptance of those changes.
      </p>
      <ol className="terms-list text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] text-white list-decimal list-inside space-y-[4rem] max-lg:space-y-[2.5rem] mt-[4rem] max-lg:mt-[2.5rem]">
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] font-normal text-white inline">
            About us
          </h2>
          <ol className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            <li>
              We are a disclosed ticketing agent selling tickets via the
              Services on behalf of promoters, organisers, providers of
              entertainment activities and similar events (the “Event
              Partners”).
            </li>
          </ol>
        </li>
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] font-normal text-white inline">
            Overview
          </h2>
          <ol className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            <li>
              Your access to and use of the Services is subject to your
              acceptance and compliance with these Terms of Use. You cannot
              access or use the Services without accepting these Terms of Use
              and Privacy Policy (which is incorporated into these Terms of Use
              by this reference).
            </li>
            <li>
              You must be over 18 to download the App and access and use the
              Services. If you are under 18 then you can only download the App
              and use the Services with permission from your parent or guardian.
            </li>
          </ol>
        </li>
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] font-normal text-white inline">
            General service description
          </h2>
          <ol className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            <li>
              These Terms of Use deal with your access to the Services. The
              Services enable you to acquire tickets for events, providing you
              with entry into venues and other establishments. We make tickets
              available on behalf of third-party Event Partners who are not
              connected in any way with Cene. When you purchase a ticket, you
              obtain a right to attend the event as granted by the Event
              Partner, as further described in our Purchase Terms. The
              third-party Event Partners are responsible to you for the relevant
              events, and we are not liable for the event or any actions or
              inactions of the Event Partners.
            </li>
            <li>
              The Services allow you to register an account which is specific to
              you. To provide you with access to the Services, we must collect,
              process and store certain data including, at times, your personal
              data, which we will do in accordance with these Terms of Use and
              our Privacy Policy.
            </li>
            <li>
              The Services will also provide you with event recommendations
              based on your preferences, musical tastes, purchase history and
              what people like you are going to. These recommendations will be
              shown to you via the Home page in the App. You can also buy
              tickets and products, or share events, music, or products and
              recommendations with your friends on social media such as Twitter.
            </li>
            <li>
              We may, from time to time, update the Services to provide
              additional functionality or services to you, or to carry out any
              maintenance or upgrades to existing functionality.
            </li>
            <li>
              Tickets purchased through the Services will be subject to our
              Purchase Terms, and any additional terms and conditions provided
              to you by the Event Partner(s) and the venue of the event. Entry
              to an event will be in accordance with the rules and regulations
              of the Event Partner(s) and venue of the event.
            </li>
          </ol>
        </li>
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] font-normal text-white inline">
            Liability
          </h2>
          <ol className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            <li>
              <p className="inline">
                We can’t guarantee that the Services will be:
              </p>
              <ul className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light list-outside list-disc pl-[16px]">
                <li>
                  compatible with any or all your mobile devices or any software
                  which you may use on your device;
                </li>
                <li>available all the time or at any specific time; or</li>
                <li>completely accurate and up to date, although we try</li>
              </ul>
            </li>
            <li>
              If we fail to comply with our Terms of Use, Purchase Terms or
              Privacy Policy, or otherwise cause you loss due to our action or
              inaction (for example, due to our negligence) we are only
              responsible for loss or damage you suffer that is a direct and
              foreseeable result of our breach of those terms or our action or
              inaction, and we are not responsible for any loss or damage that
              is not directly foreseeable, or not our fault. We have no
              liability to you for any indirect loss or for any loss of profit,
              loss of revenue, loss of business, loss of goodwill, reputational
              damage or loss, business interruption, loss of value or loss of
              data you may suffer in connection with your use of the Services or
              any materials available through the Services, or any related
              services provided by us. As mentioned in Clause 3.1, we are not
              responsible for the events or the actions or inactions of the
              Event Partners, and we hereby disclaim all liability in relation
              thereto. Furthermore, we are not responsible for any losses that
              you suffer as a result of malfunctioning or failure of your
              device, or errors, delays, or interruptions in the operation of
              the Services, or for anything which is beyond our reasonable
              control.
            </li>
            <li>
              You may have other rights granted by law in addition to these
              Terms of Use. These Terms of Use will override any such rights
              unless this is not permitted by law.
            </li>
          </ol>
        </li>
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] font-normal text-white inline">
            Presales, reminders, and saved events
          </h2>
          <ol className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            <li>
              Certain events will allow you to sign up for a
              &quot;reminder&quot; to be notified (via email or push
              notification) when tickets for an event are placed on sale, or to
              &quot;save&quot; an event meaning you will be notified (via email
              or push notification) when the event date is approaching and/or if
              there are only a few tickets remaining for sale. Tickets are sold
              on a first-come-first-sold basis, and you must purchase the
              tickets in the same manner as any other transaction. You
              acknowledge that signing up to a presale notification, or saving
              an event, does not guarantee you will successfully purchase a
              ticket. Presale, reminder, and saved event notifications may also
              include additional information about the event.
            </li>
          </ol>
        </li>
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] font-normal text-white inline">
            User conduct
          </h2>
          <ol className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            <li>
              You can use the Services for your own personal use and agree not
              to use it for any purpose prohibited by these Terms of Use. You
              are solely responsible for all your activities in connection with
              the Services.
            </li>
            <li>
              You will need to register an account in order to purchase a ticket
              or other item. When registering an account, you will be required
              to provide your name, mobile number, email address, date of birth,
              gender, location, and occupation. Any tickets you purchase via the
              Services will be issued in your name as shown in your account
              details. You agree to provide us with accurate and up-to-date
              information as requested when registering your account, and you
              agree to update your details promptly if there are any changes.
            </li>
            <li>
              In order to use certain aspects and features of the Services, you
              must have a compatible mobile device, access to the Internet,
              mobile messaging, and data services. Fees and charges may apply to
              your use of the Internet or mobile messaging services. You agree
              that you are responsible for any such fees or charges where these
              apply.
            </li>
            <li>
              <p className="inline">You also agree that:</p>
              <ul className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light list-outside list-disc pl-[16px]">
                <li>
                  you will not interfere or attempt to interfere with the proper
                  functioning of the Services or disrupt the operations or
                  breach the security of the Services;
                </li>
                <li>
                  you will not reproduce, duplicate, copy, sell, resell, remove,
                  modify, or exploit the Services or their content or its
                  software in any way;
                </li>
                <li>
                  you will not use software, devices, or other manual or
                  automated processes to access any portion of the Services,
                  including but not limited to use of any scripts or web
                  crawlers; and
                </li>
                <li>
                  you will not use the Services in any way that is fraudulent or
                  illegal.
                </li>
              </ul>
            </li>
            <li>
              The Services and their contents are protected by intellectual
              property rights which either belong to us or are licensed to us to
              use. You may not copy or use them without our consent in writing.
            </li>
            <li>
              The Cene word mark and our logo are registered trademarks and must
              not be used in any way without our express prior written consent.
            </li>
            <li>
              We reserve the right to cancel any transaction or remove, suspend,
              edit, or modify your access to the Services at our discretion if
              we have reason to believe you have used the Services in
              contravention of these Terms of Use. Any and all ticket(s)
              purchased as part of such transaction will be void.
            </li>
            <li>
              Once you have successfully acquired a ticket through our Services,
              it is important to note that the assignment of that ticket to your
              guest is considered final and cannot be transferred or reassigned
              to another individual. When a ticket is assigned to your guest, it
              is linked to their account and identity, ensuring that only the
              rightful ticket holder gains access to the event.
            </li>
            <li>
              The QR codes associated with your event tickets will become active
              and accessible within the Services strictly 3 hours prior to the
              scheduled start time of the event.
            </li>
            <li>
              If you or any third party is aware of any breach of these terms of
              use which could potentially damage or infringe our property,
              protectable rights, or interests, you should immediately contact
              Cene via email at{' '}
              <a
                href="mailto:support@cene.xyz"
                className="text-blue-500 underline"
              >
                support@cene.xyz
              </a>{' '}
              with details of the alleged infringement.
            </li>
          </ol>
        </li>
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] font-normal text-white inline">
            Closing your Cene account
          </h2>
          <ol className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            <li>
              These Terms of Use commence from the date that you access and or
              use the Services until the time that these Terms of Use with us
              are terminated. There is no minimum time period of when these
              Terms of Use will be in effect.
            </li>
            <li>
              You can uninstall the App at any time, using the functionality
              within the App or your mobile device. Please note though that
              deleting the App will not automatically result in the closure of
              your account. To close your account, please email us at{' '}
              <a
                href="mailto:support@cene.xyz"
                className="text-blue-500 underline"
              >
                support@cene.xyz
              </a>
              .
            </li>
            <li>
              We reserve the right to suspend or terminate your account,
              terminate these Terms of Use, or remove, suspend, edit, or modify
              the Services at any time and at our convenience by providing at
              least 24-hours notice to you.
            </li>
          </ol>
        </li>
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] font-normal text-white inline">
            Third-party content and services
          </h2>
          <ol className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            <li>
              The Services may integrate with social networking platforms (such
              as Instagram), music streaming platforms (such as Spotify) and
              other third-party applications. Your use of any integrated
              applications will be subject to those third party&apos;s terms of
              use and their privacy policies.
            </li>
            <li>
              When you access third party websites or applications from the
              Services you do so at your own risk. Any third-party websites or
              applications are outside of our control, and we are not
              responsible for the content, functions, accuracy, legality,
              appropriateness or any other aspect of such websites or
              applications.
            </li>
            <li>
              Sometimes Cene may request additional permissions related to the
              settings on your mobile device, such as accessing your location or
              enabling push notifications in order to carry out certain actions
              or allow you to enjoy certain features. If you choose not to give
              us the necessary permissions, then you may not be able to access
              all the features and functions of the Services.
            </li>
          </ol>
        </li>
        <li>
          <h2 className="text-[3rem]/[3.5rem] max-lg:text-[1.5rem]/[2.25rem] font-normal text-white inline">
            General
          </h2>
          <ol className="text-generic text-h3/[1.375rem] max-lg:text-[1rem]/[1.3125rem] font-light mt-[24px]">
            <li>
              If you have any complaint about the Services, you should contact
              our customer services department at the following email:{' '}
              <a
                href="mailto:support@cene.xyz"
                className="text-blue-500 underline"
              >
                support@cene.xyz
              </a>{' '}
              and we will try and resolve it as soon as possible.
            </li>
          </ol>
        </li>
      </ol>
    </div>
  ),
};

export default termsOfUse;
