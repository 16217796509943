import { CollapsibleSection } from '../../components/collapsibleSection/сollapseSection';
import translations from '../../constants/translations.json';
import { usePreventHorizontalScroll } from '../../hooks/usePreventHorizontalScroll';
import { AboutUs } from '../../sections/moreTicketing/aboutUs';
import { MoreTicketing } from '../../sections/moreTicketing/moreTicketing';
import { SubscriptionPrice } from '../../sections/moreTicketing/subscriptionPrice';
import { TestimonialSection } from '../../sections/moreTicketing/testimonialSection';
import classNames from 'classnames';
import { useRef } from 'react';

interface SectionData {
  title: string;
  description: string;
}
const sections: SectionData[] = translations.sections;
export const TicketingPage = () => {
  usePreventHorizontalScroll();
  const aboutUsRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div
        className={classNames([
          'h-[calc(100vh)] flex items-center justify-end',
          'max-lg:block max-lg:h-full',
        ])}
      >
        <div
          className={classNames([
            'block h-full w-full top-0 left-1/2 -translate-x-1/2 z-[4]',
            'relative max-lg:-mx-[20px] max-lg:-mt-[88px] max-lg:w-auto max-lg:left-0 max-lg:translate-x-0 ',
            'max-sm:h-[578px]',
          ])}
        >
          <MoreTicketing />

          <div className="h-full lg:block lg:w-screen lg:relative lg:left-[50%] lg:right-[50%] lg:mx-[-50vw] ">
            <img
              src="/pictures/bannerTicketing.jpg"
              className={classNames([
                'h-full w-full max-sm:object-cover object-cover object-[50%] max-lg:w-full ',
              ])}
            />
          </div>
          <div
            className={classNames([
              'bg-gradient-ticket absolute w-full lg:w-screen h-full top-0 lg:left-[50%] lg:right-[50%] lg:mx-[-50vw]',
              'max-lg:bg-gradient-ticket',
            ])}
          />
        </div>
      </div>
      <div
        className={classNames([
          'w-full mx-auto container',
          'max-lg:flex-col-reverse max-lg:px-0 max-lg:mt-0 max-lg:gap-0 px-0 ',
        ])}
      >
        <div className={classNames(['lg:my-[120px] lg:mx-0  my-[72px] '])}>
          <h1
            className={classNames([
              'text-[2rem] font-light text-white text-center leading-[40px]',
              ' lg:text-[4rem] lg:leading-[72px]',
            ])}
          >
            {translations.ticketing.title}
          </h1>
          <div className={classNames(['flex justify-center text-center'])}>
            <h3
              className={classNames([
                ' font-light text-generic  mt-[16px] leading-[21px] ',
                'lg:text-[1.0625rem] lg:mt-[24px] lg:leading-[22px] lg:w-[780px]',
              ])}
            >
              {translations.ticketing.titleTwo}
            </h3>
          </div>
          <SubscriptionPrice />
        </div>
        <TestimonialSection />
        <AboutUs refProp={aboutUsRef} />
        <div
          className={classNames([
            'flex flex-col items-center justify-center ',
            'lg:my-[120px] lg:mx-[64px] mx-0 my-[72px]',
          ])}
        >
          <h1 className="lg:text-[4rem] font-light text-white text-center lg:leading-[72px] whitespace-pre-line text-[2rem] leading-[40px]">
            {translations.questions.titleTwo}
          </h1>
          <h3 className="text-[1.3125rem] font-light text-generic text-center  lg:mb-[64px] mb-[56px] leading-[21px] mt-[24px] px-[20px]">
            {translations.questions.descriptionTwo}
          </h3>
          {sections.map((section, index) => (
            <CollapsibleSection
              key={index}
              title={section.title}
              description={section.description}
              isLast={index === sections.length - 1}
            />
          ))}
        </div>
      </div>
    </>
  );
};
