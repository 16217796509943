import CarouselSection from '../../components/carouselSection/carouselSection';
import { Loader } from '../../components/loader/cene/loader';
import { Loader as LoaderCenePlus } from '../../components/loader/cenePlus/loader';
import translations from '../../constants/translations.json';
import { useSupabase } from '../../providers/supabase';
import EmptyEventsSection, { EventType } from './notFound.tsx/notFound';
import { Event } from '@ceneteam/namespace';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

interface UpcomingProps {
  events: Event[];
  isLoading: boolean;
}

const UpcomingEventsSection = ({ events, isLoading }: UpcomingProps) => {
  const navigate = useNavigate();
  const { session } = useSupabase();
  const sessionType = session?.type === 'cene';

  const handleCardClick = (id: string, eventData: Event) => {
    navigate(`/ticket-details/${id}`, { state: { eventData } });
  };

  return (
    <div className={classNames('mt-[2.75rem]', 'max-lg:mt-[1.75rem]')}>
      {isLoading ? (
        <div className="flex justify-center items-center h-[558px]">
          {sessionType ? <Loader /> : <LoaderCenePlus />}
        </div>
      ) : events.length === 0 ? (
        <EmptyEventsSection
          events={events}
          title={translations.myTickets.upcomingEvents}
          sessionType={sessionType}
          type={EventType.UPCOMING}
        />
      ) : (
        <CarouselSection
          data={events}
          title={translations.myTickets.upcomingEvents}
          sessionType={sessionType}
          color={sessionType ? 'ticket' : 'generic'}
          handleCardClick={(id) =>
            handleCardClick(id, events.find((event) => event.id === id)!)
          }
          colorCard={sessionType ? 'ticket' : 'black'}
        />
      )}
    </div>
  );
};

export default UpcomingEventsSection;
