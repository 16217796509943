import { ShareOptions } from '../../components/shareOptions/shareOptionsOrganization';
// import { ROUTE_DOWNLOAD } from '../../constants/routes';
import translations from '../../constants/translations.json';
import { OrganizerSection } from '../../pages/ticketDetails/organizer';
// import {
//   supabaseCeneClient,
//   supabaseCenePlusClient,
// } from '../../supabase/supabase';
// import { useSupabase } from '../../providers/supabase';
import { ReactComponent as CalendarSVG } from '../../svg/calendarPlus.svg';
import { ReactComponent as ArrowRightSVG } from '../../svg/caretRight.svg';
// import { InviteUserModal } from '../ticketsShare/inviteUser';
// import { ShareTicketModal } from '../ticketsShare/shareTicketModal';
// import { TicketSuccessModal } from '../ticketsShare/ticketSuccessModal';
// import { TicketsModal } from '../ticketsShare/ticketsModal';
import { Event } from './aboutEvent';
import { AddToCalendar } from './addToCalendar';
import { TicketModal } from './ticketModal';
import classNames from 'classnames';
import { useState } from 'react';

// import { useNavigate } from 'react-router-dom';

interface Tickets {
  ticket: Event;
  sessionType: boolean;
}

const TicketsSection = ({ ticket, sessionType }: Tickets) => {
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [isTicketModalOpen, setTicketModalOpen] = useState(false);
  // const [showShareTicketModal, setShowShareTicketModal] = useState(false);
  // const [showTicketInviteModal, setShowTicketInviteModal] = useState(false);
  // const [showTicketTransferredModal, setShowTicketTransferredModal] =
  //   useState(false);
  // const [name, setName] = useState<string | ''>('');
  // const [ticketId, setTicketId] = useState<string | null>(null);
  // const [date, setData] = useState<Ticket[]>([]);

  // const navigate = useNavigate();

  const toggleShareOptions = () => {
    setShowShareOptions((prev) => !prev);
  };

  const toggleModal = () => {
    setTicketModalOpen(!isTicketModalOpen);
  };

  // const handleSendClick = (ticketId: string) => {
  //   setTicketId(ticketId);
  //   setTicketModalOpen(false);
  //   setShowShareTicketModal(true);
  // };

  // const handleSendClickSuccess = (name: string) => {
  //   setName(name);
  //   setShowShareTicketModal(false); // Close ShareTicketModal
  //   setShowTicketInviteModal(true); // Open TicketSuccessModal
  // };

  // const handleSendClickTransferred = () => {
  //   setShowShareTicketModal(false); // Close ShareTicketModal
  //   setShowTicketTransferredModal(true); // Open TicketSuccessModal
  // };

  // const typeSupabase = sessionType
  //   ? supabaseCeneClient
  //   : supabaseCenePlusClient;

  // const fetchTicketsWithShares = async (eventId: string) => {
  //   try {
  //     const { data, error } = await typeSupabase
  //       .from('tickets_with_shares')
  //       .select('*')
  //       .eq('event_id', eventId);

  //     if (error) {
  //       throw new Error(`Error fetching tickets_with_shares: ${error.message}`);
  //     }

  //     setData(data);
  //     return data;
  //   } catch (err) {
  //     console.error(err);
  //     return [];
  //   }
  // };

  // useEffect(() => {
  //   fetchTicketsWithShares(ticket.id);
  // }, []);

  const eventDetails = {
    title: ticket?.name,
    startDate: ticket?.starts_at,
    endDate: ticket?.ends_at,
    details: ticket?.description || 'Event desc',
    location: ticket?.address,
  };

  const ticketCountMap =
    ticket?.tickets?.reduce(
      (acc: { [key: string]: { count: number; tableId: string } }, curr) => {
        const title = curr.title || 'Untitled';
        const tableId = curr.table_id || 'Unknown';
        if (!acc[title]) {
          acc[title] = { count: 0, tableId };
        }
        acc[title].count += 1;
        return acc;
      },
      {}
    ) || {};

  const uniqueTickets = Object.entries(ticketCountMap).map(
    ([title, { count, tableId }]) => ({
      title,
      count,
      tableId,
    })
  );

  return (
    <div>
      <div
        className={classNames(
          sessionType
            ? ''
            : 'lg:bg-generic-06 border lg:border-generic-light lg:p-[2.5rem] lg:rounded-tl-[30px] lg:rounded-br-[30px]'
        )}
      >
        {!sessionType && (
          <h2
            className={classNames(
              'text-generic uppercase text-[1.3125rem]/[1.3125rem] font-light',
              'max-lg:hidden'
            )}
          >
            {translations.ticketDetails.tickets}
          </h2>
        )}
        <div
          className={classNames(
            'flex flex-col gap-[1rem] mt-[1.5rem]',
            'max-lg:mt-0'
          )}
        >
          {uniqueTickets.map((ticketItem, index) => (
            <div
              key={index}
              className="bg-gray-dark border border-generic-light p-[1.25rem] rounded-tl-[30px] rounded-br-[30px] flex items-center justify-between hover:bg-gray-dark hover:border-gray-light transition-colors duration-300  cursor-pointer"
              onClick={toggleModal}
            >
              <div>
                <div
                  className={classNames(
                    'flex items-center gap-[0.25rem] font-light text-[1.3125rem]/[1.3125rem]',
                    'max-lg:text-[1.0625rem]/[1.375rem]',
                    sessionType ? 'text-white' : 'text-generic'
                  )}
                >
                  <span>
                    {`${ticketItem?.title}${!sessionType && ticketItem?.tableId !== 'Unknown' ? `, Table ${ticketItem.tableId}` : ''}, `}
                  </span>
                  <span>{ticketItem?.count} Tickets</span>
                </div>
                {/* <div
                  className={classNames(
                    'flex items-center font-light text-[1.0625rem]/[1.375rem] mt-[1.5rem]',
                    'max-lg:text-[0.75rem]/[1.125rem] max-lg:font-medium'
                  )}
                >
                  <span
                    className={classNames(
                      'pr-[0.75rem]',
                      sessionType ? 'text-white' : 'text-generic'
                    )}
                  >
                    {ticket.ticketsAvailable} Tickets Available
                  </span>
                  <span className="text-gray-light pl-[0.75rem] border-l border-l-gray-light">
                    {ticket.quantitySent} Sent
                  </span>
                </div> */}
              </div>
              <div>
                <ArrowRightSVG
                  style={{
                    stroke: sessionType ? '#ec67f7' : '#B8CAD9',
                    width: '20px',
                    height: '20px',
                  }}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="mt-[1.5rem] flex flex-col gap-[1.5rem]">
          <h3
            className={classNames(
              'font-light text-[1.3125rem]/[1.3125rem]',
              sessionType ? 'text-ticket' : 'text-generic'
            )}
          >
            {translations.ticketDetails.whatShareTickets}
          </h3>
          <p className="text-white text-[1rem]/[1.5rem] font-light">
            Tickets will be available 2 hours before the event and can only be
            shared via {sessionType ? 'Cene' : 'Cene+'}.
            {/* To get your ticket,  by entering the recipient’s
            email address. If they’re not a {sessionType ? 'Cene' : 'Cene+'}{' '}
            member, they’ll need to{' '}
            <span
              className="underline cursor-pointer"
              onClick={() => navigate(ROUTE_DOWNLOAD)}
            >
              download the app
            </span>{' '}
            to access their ticket. */}
          </p>
          <a
            href="https://cenexyz.zendesk.com/"
            target="_blank"
            rel="noopener noreferrer"
            className={classNames(
              'cursor-pointer w-fit text-[1.1875rem]/[1.1875rem] font-light pb-[1rem] self-end border-b',
              sessionType
                ? 'text-ticket border-b-ticket'
                : 'text-generic border-b-generic'
            )}
          >
            {translations.ticketDetails.needHelp}
          </a>
        </div>
      </div>
      <div
        className={classNames(
          'lg:sticky bottom-0 flex items-center gap-[1rem] flex-wrap bg-black z-[8] lg:p-[1rem] mt-[2rem]',
          'max-lg:mt-[3rem]'
        )}
      >
        <AddToCalendar event={eventDetails} sessionType={sessionType} />
      </div>
      <div className="max-lg:block hidden pt-[1.5rem] mt-[1.5rem] border-t border-t-generic-light">
        <p
          className={classNames(
            'uppercase',
            sessionType ? 'text-ticket' : 'text-white'
          )}
        >
          {translations.ticketDetails.date}
        </p>
        <p
          className={classNames(
            'flex items-center gap-[1.5rem] text-[1.3125rem]/[1.3125rem] font-light mt-[0.5rem]',
            sessionType ? 'text-white' : 'text-my-ticket'
          )}
        >
          <CalendarSVG />
          Sunday, August 4 · 12 / 10pm WAT
        </p>
      </div>
      <div className="mt-[1.5rem] lg:hidden">
        <OrganizerSection sessionType={sessionType} event={ticket} />
      </div>
      {showShareOptions && (
        <ShareOptions onCopyLink={() => ''} onClose={toggleShareOptions} />
      )}
      <TicketModal
        session={sessionType}
        isOpen={isTicketModalOpen}
        onClose={toggleModal}
      />
      {/* <TicketSuccessModal
        session={session?.type}
        isOpen={isTicketModalOpen}
        onClose={toggleModal}
      /> */}

      {/* {isTicketModalOpen && (
        <TicketsModal
          onClose={toggleModal}
          sessionType={sessionType}
          isOpen={isTicketModalOpen}
          onSendClick={handleSendClick}
          ticket={ticket}
          date={date}
        />
      )}

      {showShareTicketModal && (
        <ShareTicketModal
          sessionType={sessionType}
          onClose={() => setShowShareTicketModal(false)}
          isOpen={showShareTicketModal}
          onSendClick={handleSendClickSuccess}
          onSendTicket={handleSendClickTransferred}
          ticket={ticket}
          date={date}
          ticketId={ticketId}
        />
      )}

      {showTicketInviteModal && (
        <InviteUserModal
          onClose={() => setShowTicketInviteModal(false)}
          isOpen={showTicketInviteModal}
          name={name}
          sessionType={sessionType}
        />
      )}

      {showTicketTransferredModal && (
        <TicketSuccessModal
          onClose={() => setShowTicketTransferredModal(false)}
          isOpen={showTicketTransferredModal}
          sessionType={sessionType}
        />
      )} */}
    </div>
  );
};

export default TicketsSection;
