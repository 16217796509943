import { WaitlistNotification } from '../../components/notification/waitlistNotification';
import translations from '../../constants/translations.json';
import { useSupabase } from '../../providers/supabase';
import { DiscoverSection } from '../../sections/events/cene/discover';
import EventDetailsSection from '../../sections/eventsDetails/cene/eventDetails';
import EventDetailsPlusSection from '../../sections/eventsDetails/cenePlus/eventDetails';
import { PersonalizedSection } from '../../sections/home/personalized';
import { supabaseCeneClient } from '../../supabase/supabase';
import { ReactComponent as ArrowLeftSVG } from '../../svg/caretLeft.svg';
import { Event } from '@ceneteam/namespace';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const EventsDetailsPage = () => {
  const { session } = useSupabase();
  const { id: eventId } = useParams();
  const navigate = useNavigate();
  const [showWaitlistNotification, setShowWaitlistNotification] =
    useState(false);

  // setMessage
  const [message] = useState('');
  const [, setSupabaseError] = useState<string | null>('');
  const [, setEvent] = useState<Event | null>(null);
  const [, setLoading] = useState(false);

  const sessionType = session?.type == 'cene' || session?.type == undefined;

  useEffect(() => {
    fetchCenePlusEvent();
  }, [eventId, sessionType]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const fetchCenePlusEvent = async () => {
    try {
      setSupabaseError(null);
      setLoading(true);

      const { data, error } = await supabaseCeneClient
        .from('events')
        .select('*')
        .eq('id', eventId)
        .single();

      if (error) throw error;
      setEvent(data);
    } catch (error) {
      if (error instanceof Error) {
        setSupabaseError(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="pt-[1.5rem] pb-[5rem] mx-auto relative max-lg:pb-[1.5rem] flex flex-col items-center">
        <div
          className={classNames(
            'items-center mb-[1.5rem] flex max-w-[1092px] lg:w-[1092px]',
            'max-lg:absolute max-lg:top-[40px] max-lg:left-[16px] max-lg:z-[4]'
          )}
        >
          <button
            className={classNames([
              'text-event mr-[1rem] text-[0.875rem]/[0.875rem] px-[1rem] py-[1rem] bg-gray-dark max-md:absolute max-md:left-[0.15rem] max-md:top-[0.15rem]',
              'rounded-tl-[8px] rounded-br-[8px]',
            ])}
            onClick={handleBackClick}
          >
            {sessionType ? (
              <ArrowLeftSVG
                style={{ width: '12px', height: '12px', stroke: '#91DBBF' }}
              />
            ) : (
              <ArrowLeftSVG
                style={{ width: '12px', height: '12px', stroke: '#B8CAD9' }}
              />
            )}
          </button>
          <p className="text-white text-[0.875rem] leading-[14px] max-lg:hidden">
            {translations.ticketDetails.backAllTickets}
          </p>
        </div>
        {sessionType ? <EventDetailsSection /> : <EventDetailsPlusSection />}
      </div>

      {sessionType ? (
        <div className="w-full flex justify-center">
          <PersonalizedSection />
        </div>
      ) : (
        <DiscoverSection
          sessionType={session?.type}
          title={translations.eventDiscover.titleTwoPlus}
          desc={translations.eventDiscover.descTwoPlus}
          similar={true}
          buttonText={translations.eventDiscover.textButton}
        />
      )}

      <WaitlistNotification
        visible={showWaitlistNotification}
        message={
          showWaitlistNotification
            ? `Yay! You’ve added ${message} to your favourite events`
            : ''
        }
        onClose={() => setShowWaitlistNotification(false)}
      />
    </>
  );
};
