import { ReactComponent as UpSvg } from '../../../src/svg/ChartLineUp.svg';
import { ReactComponent as PieSvg } from '../../../src/svg/ChartPie.svg';
import { ReactComponent as CrownSvg } from '../../../src/svg/CrownSimple.svg';
import { ReactComponent as DeviceSvg } from '../../../src/svg/MobileCamera.svg';
import { ReactComponent as QrSvg } from '../../../src/svg/QrCode.svg';
import { ReactComponent as TicketSvg } from '../../../src/svg/tickets.svg';
import translations from '../../constants/translations.json';
import classNames from 'classnames';

const icons = [DeviceSvg, TicketSvg, CrownSvg, UpSvg, PieSvg, QrSvg];

export const EventToolkit = () => {
  const { toolkitItems } = translations.eventToolkit;

  return (
    <div
      className={
        'w-full h-auto flex flex-col justify-center items-center lg:px-[113px] lg:pt-[120px] pt-[72px] '
      }
    >
      <h1
        className={classNames([
          'text-[2rem] leading-[40px] font-light text-event text-center lg:w-[873px]',
          'lg:text-[4rem] lg:leading-[72px] ',
        ])}
      >
        {translations.eventToolkit.title}
      </h1>
      <h3 className="text-[1.0625rem]  text-white text-center mt-[24px] font-normal w-full max-w-[537.92px] leading-[22px]">
        {translations.eventToolkit.description}
      </h3>

      <div
        className={classNames([
          'w-full max-w-[1106px] grid grid-cols-1 gap-x-6 mt-[64px]',
          'sm:grid-cols-2 md:grid-cols-3 mx-[167px] lg:my-[120px] my-[40px]',
        ])}
      >
        {toolkitItems.map((item, index) => {
          const IconComponent = icons[index % icons.length];
          return (
            <div
              key={index}
              className={classNames([
                'flex flex-col items-center mb-8 text-center',
                'mb-[32px] lg:mb-[64px] ',
              ])}
            >
              <div className="w-12 h-12 lg:mb-[24px] mb-[16px]">
                <IconComponent />
              </div>
              <h4 className="lg:text-[2rem] font-light lg:leading-[40px] leading-[26px] text-[1.125rem] mb-2 text-white whitespace-nowrap">
                {item.title}
              </h4>
              <p className="text-[1rem] font-light leading-[24px] text-generic lg:mt-2 mt-0">
                {item.description}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
