export const FeeItem = ({
  label,
  amount,
}: {
  label: string;
  amount?: string;
}) => (
  <div className="mb-[12px]">
    <div className="w-full flex flex-row justify-between items-center">
      <div className="flex items-center mt-[4px]">
        <div className="text-generic text-[1rem] font-light">{label}</div>
      </div>

      <div className="text-generic text-[21px] font-light">{`₦ ${amount}`}</div>
    </div>
  </div>
);
