import { Button } from '../../components/button/button';
import { PreferenceCard } from '../../components/preferenceCard/cene/preferenceCard';
import { PreferenceCard as PreferenceCardCenePlus } from '../../components/preferenceCard/cenePlus/preferenceCard';
import Toast from '../../components/toast/toast';
import { ROUTE_SETTINGS } from '../../constants/routes';
import translation from '../../constants/translations.json';
import { useSupabase } from '../../providers/supabase';
import {
  supabaseCeneClient,
  supabaseCenePlusClient,
} from '../../supabase/supabase';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  selectedGenres: string[] | null;
}

interface FormValues {
  musicGenres: string[];
}

export const MusicForm = ({ selectedGenres }: Props) => {
  const { session } = useSupabase();
  const navigate = useNavigate();
  const id = session?.user?.id;

  const isCene = session?.type === 'cene';

  const sessionType = session?.type === 'cene' ? 'cene' : 'cenePlus';
  const [error, setError] = useState<string>('');
  const [isVisibleToast, setVisibleToast] = useState(false);
  const musicGenres = GENRES[sessionType];

  const formik = useFormik<FormValues>({
    initialValues: {
      musicGenres: selectedGenres || [],
    },
    onSubmit: async (values) => {
      const client = isCene ? supabaseCeneClient : supabaseCenePlusClient;

      const { error } = await client
        .from('customers')
        .update({ genres: values.musicGenres })
        .eq('id', id);

      if (error) {
        setError(error.message);
        return;
      }

      setVisibleToast(true);

      setTimeout(() => {
        setVisibleToast(false);
        navigate(ROUTE_SETTINGS);
      }, 3000);
    },
  });

  useEffect(() => {
    if (selectedGenres) formik.setFieldValue('musicGenres', selectedGenres);
  }, [selectedGenres]);

  const handleMusicGenreSelect = (title: string) => {
    const { musicGenres } = formik.values;
    if (musicGenres.includes(title)) {
      formik.setFieldValue(
        'musicGenres',
        musicGenres.filter((interest) => interest !== title)
      );
    } else if (musicGenres.length < 5) {
      formik.setFieldValue('musicGenres', [...musicGenres, title]);
    }
  };

  const selectedCount = formik.values.musicGenres.length;

  return (
    <div className="pt-[24px] pb-[20px]">
      {isVisibleToast && (
        <Toast
          message={
            'Your music genres preferences has been successfully updated.'
          }
          bgColor={'bg-content'}
          onClose={() => setVisibleToast(false)}
        />
      )}
      <h1 className="text-[2rem] text-white font-light mb-[12px]">
        {isCene
          ? translation.steps.genres.title
          : translation.steps.genres.titleCenePlus}
      </h1>
      <p
        className={classNames([
          'text-[0.875rem] text-generic font-light mb-[40px]',
          'max-lg:mb-[24px]',
        ])}
      >
        {isCene
          ? translation.steps.genres.description
          : translation.steps.genres.descriptionCenePlus}
      </p>
      <Grid
        container
        sx={{
          width: { xs: '100%', md: '456px' },
          gap: { xs: '12px', md: '16px' },
        }}
      >
        {musicGenres.map(({ title, icon }) => (
          <Grid item xs={3.65157} sm={3.65157} md={2.684} key={title}>
            {isCene ? (
              <PreferenceCard
                selected={formik.values.musicGenres.includes(title)}
                onClick={() => handleMusicGenreSelect(title)}
                href={icon}
              >
                {title}
              </PreferenceCard>
            ) : (
              <PreferenceCardCenePlus
                selected={formik.values.musicGenres.includes(title)}
                onClick={() => handleMusicGenreSelect(title)}
                href={icon}
              >
                {title}
              </PreferenceCardCenePlus>
            )}
          </Grid>
        ))}
      </Grid>
      {error && (
        <div className="text-[0.875rem] text-red font-light mt-[8px]">
          {error}
        </div>
      )}
      <div
        className={classNames([
          'mt-[40px] max-w-[456px] flex items-center gap-[40px]',
          'max-lg:gap-[24px]',
        ])}
      >
        <div className={classNames(['w-full', 'max-lg:w-2/3'])}>
          <p className="text-[1rem] text-white font-medium">
            {selectedCount}/5 {translation.selected}
          </p>
          <p className="text-[0.875rem] text-generic font-light">
            {translation.selectionInfo}
          </p>
        </div>
        <Button
          onClick={() => formik.handleSubmit()}
          className={classNames(['w-full', 'max-lg:w-1/3'])}
          color={isCene ? 'content' : 'generic'}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

const GENRES = {
  cene: [
    { icon: '/pictures/genre-cene/afrobeats-cene.jpg', title: 'Afrobeats' },
    { icon: '/pictures/genre-cene/alte-cene.jpg', title: 'Alté' },
    { icon: '/pictures/genre-cene/amapiano-cene.jpg', title: 'Amapiano' },
    { icon: '/pictures/genre-cene/country-cene.jpg', title: 'Country' },
    { icon: '/pictures/genre-cene/dancehall-cene.jpg', title: 'Dancehall' },
    { icon: '/pictures/genre-cene/drill-cene.jpg', title: 'Drill' },
    { icon: '/pictures/genre-cene/electronic-cene.jpg', title: 'Electronic' },
    { icon: '/pictures/genre-cene/hip-hop-cene.jpg', title: 'Hip-Hop' },
    { icon: '/pictures/genre-cene/house-cene.jpg', title: 'House' },
    { icon: '/pictures/genre-cene/jazz-cene.jpg', title: 'Jazz' },
    { icon: '/pictures/genre-cene/pop-cene.jpg', title: 'Pop' },
    { icon: '/pictures/genre-cene/rap-cene.jpg', title: 'Rap' },
    { icon: '/pictures/genre-cene/rnb-cene.jpg', title: 'RnB' },
    { icon: '/pictures/genre-cene/rock-cene.jpg', title: 'Rock' },
    { icon: '/pictures/genre-cene/soul-cene.jpg', title: 'Soul' },
    { icon: '/pictures/genre-cene/trap-cene.jpg', title: 'Trap' },
  ],
  cenePlus: [
    {
      icon: '/pictures/music-ceneplus/afrobeats-ceneplus.jpg',
      title: 'Afrobeats',
    },
    {
      icon: '/pictures/music-ceneplus/alternative-ceneplus.jpg',
      title: 'Altérnative',
    },
    {
      icon: '/pictures/music-ceneplus/amapiano-ceneplus.jpg',
      title: 'Amapiano',
    },
    {
      icon: '/pictures/music-ceneplus/classical-ceneplus.jpg',
      title: 'Classical',
    },
    { icon: '/pictures/music-ceneplus/country-ceneplus.jpg', title: 'Country' },
    {
      icon: '/pictures/music-ceneplus/dancehall-ceneplus.jpg',
      title: 'Dancehall',
    },
    {
      icon: '/pictures/music-ceneplus/electronic-ceneplus.jpg',
      title: 'Electronic',
    },
    { icon: '/pictures/music-ceneplus/hip-hop-ceneplus.jpg', title: 'Hip-Hop' },
    { icon: '/pictures/music-ceneplus/house-ceneplus.jpg', title: 'House' },
    { icon: '/pictures/music-ceneplus/jazz-ceneplus.jpg', title: 'Jazz' },
    {
      icon: '/pictures/music-ceneplus/neo-soul-ceneplus.jpg',
      title: 'Neo-Soul',
    },
    { icon: '/pictures/music-ceneplus/pop-ceneplus.jpg', title: 'Pop' },
    { icon: '/pictures/music-ceneplus/rnb-ceneplus.jpg', title: 'R&B' },
    { icon: '/pictures/music-ceneplus/rap-ceneplus.jpg', title: 'Rap' },
    { icon: '/pictures/music-ceneplus/reggae-ceneplus.jpg', title: 'Reggae' },
    { icon: '/pictures/music-ceneplus/rock-ceneplus.jpg', title: 'Rock' },
  ],
};
