interface PasswordRequirementsListTypes {
  passwordValid: {
    uppercase: boolean;
    lowercase: boolean;
    number: boolean;
    specialChar: boolean;
  };
  isPremium?: boolean;
}

const PasswordRequirementsList = ({
  passwordValid,
  isPremium = false,
}: PasswordRequirementsListTypes) => {
  const isPasswordValid =
    passwordValid.uppercase &&
    passwordValid.lowercase &&
    passwordValid.number &&
    passwordValid.specialChar;

  return (
    <div className="flex flex-col gap-[8px]">
      <p
        className={`text-[0.875rem] leading-[] ${
          isPasswordValid
            ? isPremium
              ? 'text-generic'
              : 'text-content'
            : 'text-gray-light'
        }`}
      >
        <span
          className={
            passwordValid.uppercase
              ? isPremium
                ? 'text-generic'
                : 'text-content'
              : 'text-gray-light'
          }
        >
          Uppercase letters
        </span>
        ,{' '}
        <span
          className={
            passwordValid.lowercase
              ? isPremium
                ? 'text-generic'
                : 'text-content'
              : 'text-gray-light'
          }
        >
          Lowercase letters
        </span>
        ,{' '}
        <span
          className={
            passwordValid.number
              ? isPremium
                ? 'text-generic'
                : 'text-content'
              : 'text-gray-light'
          }
        >
          Numbers
        </span>
        , and{' '}
        <span
          className={
            passwordValid.specialChar
              ? isPremium
                ? 'text-generic'
                : 'text-content'
              : 'text-gray-light'
          }
        >
          {' '}
          Special Characters
        </span>
        .
      </p>
    </div>
  );
};

export default PasswordRequirementsList;
