import translations from '../../constants/translations.json';
import { useSupabase } from '../../providers/supabase';
import ActiveRequestsSection from '../../sections/myBasket/active';
import ExpiredRequestsSection from '../../sections/myBasket/expired';
import {
  supabaseCeneClient,
  supabaseCenePlusClient,
} from '../../supabase/supabase';
import { Event } from '@ceneteam/namespace';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

export interface Ticket {
  name: string;
  ticket_name_id: string;
  quantity: number;
}

export interface TicketRequest {
  id: string;
  customer_id: string;
  customer_name: string;
  event_id: string;
  app: string;
  details: Ticket[];
  status: string;
  message?: string;
  type: string;
  event?: Event;
  expires_at: string;
  transaction: string;
}

const MyBasketPage = () => {
  const [ticketRequests, setTicketRequests] = useState<TicketRequest[]>([]);
  const [, setLoading] = useState(false);
  const { session } = useSupabase();

  const userId = session?.user?.id;
  const sessionType = session?.type == 'cene';

  useEffect(() => {
    sessionType ? fetchTicketRequestsCene() : fetchTicketRequestsCenePlus();
  }, [userId]);

  const fetchTicketRequestsCene = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabaseCeneClient
        .from('my_basket')
        .select('*');
      if (error) throw error;

      const requestsWithEventData = await Promise.all(
        data.map(async (request: TicketRequest) => {
          const { data: eventData, error: eventError } =
            await supabaseCeneClient
              .from('events')
              .select('*')
              .eq('id', request.event_id)
              .single();

          if (eventError) {
            console.error('Error fetching event data:', eventError);
          }

          return {
            ...request,
            event: eventData,
          };
        })
      );

      setTicketRequests(requestsWithEventData || []);
    } catch (error) {
      console.error('Error fetching Cene requests events:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTicketRequestsCenePlus = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabaseCenePlusClient
        .from('my_basket')
        .select('*');
      if (error) throw error;

      const requestsWithEventData = await Promise.all(
        data.map(async (request: TicketRequest) => {
          const { data: eventData, error: eventError } =
            await supabaseCenePlusClient
              .from('events')
              .select('*')
              .eq('id', request.event_id)
              .single();

          if (eventError) {
            console.error('Error fetching event data:', eventError);
          }

          return {
            ...request,
            event: eventData,
          };
        })
      );

      setTicketRequests(requestsWithEventData || []);
    } catch (error) {
      console.error('Error fetching Cene+ requests events:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={classNames('py-[5rem]', 'max-lg:pt-[1rem] max-lg:pb-[4.5rem]')}
    >
      <h1
        className={classNames(
          'text-[2.25rem]/[2.75rem] font-light',
          'lg:text-[4.5rem]/[5rem]',
          sessionType ? 'text-ticket' : ' text-generic'
        )}
      >
        {translations.myBasket.title}
      </h1>
      <ActiveRequestsSection
        ticketRequests={ticketRequests}
        sessionType={sessionType}
      />
      <ExpiredRequestsSection
        ticketRequests={ticketRequests}
        sessionType={sessionType}
      />
    </div>
  );
};

export default MyBasketPage;
