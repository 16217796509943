import { ReactComponent as FilledHeartSvg } from '../../../src/svg/filledHeart.svg';
import { ReactComponent as HeartSvg } from '../../../src/svg/heart.svg';
import { ReactComponent as LockSvg } from '../../../src/svg/lock.svg';
import { ReactComponent as ShareSvg } from '../../../src/svg/share.svg';
import { ROUTE_EVENTS_DETAILS } from '../../constants/routes';
import translations from '../../constants/translations.json';
import { useCardImgContainer } from '../../hooks/useCardImgContainer';
import { useClickOutside } from '../../hooks/useClickOutside';
import { useEventActions } from '../../hooks/useEventActions';
import { useSupabase } from '../../providers/supabase';
import { ShareOptions } from '../shareOptions/shareOptions';
import { StatusBadge } from './statusBadge';
import { LineUpItem, Ticket } from '@ceneteam/namespace';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Image {
  url: string;
}

interface CardProps {
  title: string;
  event_id: string;
  description: string;
  status: string;
  state: string;
  place: string;
  country: string;
  starts_at: string;
  background: Image[];
  line_up?: LineUpItem[];
  quantity?: number;
  trending?: boolean;
  isWaitlisted?: boolean;
  liked?: boolean;
  textStatusEvent?: string;
  waitlist?: boolean;
  tickets: Ticket[] | null;
  attended?: boolean;
  setIsPaused?: (paused: boolean) => void;
  handleHeartClick: (title: string) => void;
  setShowLogInModal?: () => void | undefined;
}

export const Card: React.FC<CardProps> = ({
  title,
  place,
  country,
  starts_at,
  background,
  status,
  quantity,
  state,
  description,
  setIsPaused,
  handleHeartClick,
  setShowLogInModal,
  event_id,
  attended,
  textStatusEvent,
  trending,
  tickets,
  waitlist,
  liked,
  line_up,
}) => {
  const cardImgContainer = useCardImgContainer(trending || false);
  const navigate = useNavigate();
  const { session } = useSupabase();
  const id = String(session?.user?.id || '');
  const { setLike } = useEventActions({ userId: id || null });
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [isHeartFilled, setIsHeartFilled] = useState(liked || false);
  const shareOptionsRef = useRef<HTMLDivElement | null>(null);

  useClickOutside(shareOptionsRef, () => handleCloseShareOptions());

  const handleShareClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const newShowShareOptions = !showShareOptions;
    setShowShareOptions(newShowShareOptions);
    setIsPaused?.(newShowShareOptions);
  };

  const handleCloseShareOptions = (event?: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
    }
    setShowShareOptions(false);
    setIsPaused?.(false);
  };

  const handleCopyLink = (e: React.MouseEvent) => {
    e.stopPropagation();
    const textToCopy = `https://cene.xyz/event-details/${event_id}`;
    navigator.clipboard
      .writeText(textToCopy)
      .catch((err) => console.error('Failed to copy link:', err));
  };

  const getDaySuffix = (day: number) => {
    if (day >= 11 && day <= 13) return 'th';
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };
  const formatTime = (dateString: string) => {
    const dateObj = new Date(dateString);
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
  };

  const formatDate = (dateString: string) => {
    const dateObj = new Date(dateString);
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString('en-En', { month: 'long' });

    const suffix = getDaySuffix(day);
    return `${day}${suffix} ${month}`;
  };

  const toggleHeart = (event: React.MouseEvent<SVGElement>) => {
    event.stopPropagation();
    if (!session) {
      setShowLogInModal?.();
      return;
    }
    setIsHeartFilled((prev) => {
      const newIsHeartFilled = !prev;
      setLike(event_id, !newIsHeartFilled);

      if (newIsHeartFilled) {
        // The heart is full (true), the event is added
        handleHeartClick(title);
        console.log(`Event ${title} added to the list`);
      } else {
        // The heart is empty (false), the event is deleted
        console.log(`Event ${title} removed from the list`);
      }

      return newIsHeartFilled;
    });
  };

  const buttonClasses: Record<string, string> = {
    CONCERT: 'text-event',
    'EVENT CLOSED':
      'bg-gray-medium text-white px-[10px] py-[10px] text-[10px] leading-[10px] ',
    'COMING SOON': 'bg-content text-white px-[15px] py-[16px]',
  };

  const buttonClass =
    buttonClasses[textStatusEvent || ''] || 'bg-transparent text-event';
  const icon =
    textStatusEvent === translations.organizer.close ? (
      <LockSvg className="w-[12px] h-[12px] mr-[4px]" stroke="white" />
    ) : null;

  const cardHeightClass = trending
    ? 'h-[558px]'
    : '2xl:h-[558px] h-[335px] lg:h-[558px]';

  const cardTextClass = trending
    ? 'text-[2rem] leading-[40px]'
    : ' lg:text-[2rem] lg:leading-[40px] text-[1.3125rem] leading-[21px]';
  const cardTextCityClass = trending
    ? 'text-[1.0625rem] leading-[22px]'
    : 'lg:text-[1.0625rem] lg:leading-[22px] text-[0.875rem] leading-[14px]';

  const firstImage = background?.[0]?.url || '/pictures/person-three.png';

  const truncateText = (text: string, maxLength: number) => {
    return text?.length > maxLength
      ? `${text?.substring(0, maxLength)}...`
      : text;
  };

  return (
    <div
      className={`relative  ${cardHeightClass}  transition-opacity duration-300 hover:opacity-70 cursor-pointer flex-shrink-0 `}
      onClick={() => {
        if (!showShareOptions) {
          navigate(ROUTE_EVENTS_DETAILS.replace(':id', event_id), {
            state: {
              status,
              quantity,
              title,
              description,
              background,
              line_up,
              place,
              state,
              country,
              id,
              starts_at,
              event_id,
              waitlist,
              liked,
              tickets,
            },
          });
        }
      }}
    >
      <div className={`${cardImgContainer} relative`}>
        {waitlist && (
          <div className="absolute left-0 top-0 mt-4 ml-4">
            <StatusBadge
              status={status || ''}
              quantity={quantity}
              waitlist={waitlist}
            />
          </div>
        )}
        {textStatusEvent && !waitlist && (
          <div
            className={classNames(
              `absolute lg:left-[16px] lg:top-[16px] left-[12px] top-[12px] text-[0.625rem] leading-[10px] rounded-big flex items-center justify-center px-[10px] py-[10px]`,
              textStatusEvent === 'EVENT CLOSED'
                ? 'inline-block max-w-max bg-gray-medium text-white'
                : buttonClass,
              {
                'lg:text-[0.875rem] lg:leading-[14px]':
                  textStatusEvent !== 'EVENT CLOSED',
              }
            )}
          >
            <div className="flex items-center">
              {icon}
              <span>{textStatusEvent}</span>
            </div>
          </div>
        )}
        <img
          src={firstImage}
          alt={title}
          className={`${cardImgContainer} object-cover w-full `}
        />
        <div className="absolute bottom-0 right-0 mr-[24px] mb-[24.87px]">
          <div className={classNames('flex flex-row items-end', 'lg:flex-col')}>
            <button onClick={handleShareClick}>
              <ShareSvg
                className={classNames(
                  'mr-[12px] lg:mr-0 transition-transform duration-200 ease-in-out hover:scale-110'
                )}
              />
            </button>
            {isHeartFilled ? (
              <FilledHeartSvg
                className="mt-[12px] transition-transform duration-200 ease-in-out hover:scale-110"
                onClick={toggleHeart}
              />
            ) : (
              <HeartSvg
                className="mt-[12px] transition-transform duration-200 ease-in-out hover:scale-110"
                onClick={toggleHeart}
              />
            )}
          </div>
        </div>
      </div>

      <div
        className="absolute bottom-0 flex flex-col items-start text-start mt-4 overflow-hidden"
        style={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 3,
          lineClamp: 3,
          textOverflow: 'ellipsis',
        }}
      >
        <p className={`${cardTextClass} font-normal text-event`}>
          {truncateText(title, 10)}
        </p>
        <p
          className={classNames(
            `${cardTextCityClass} font-normal mt-4 whitespace-pre-line`,
            {
              'text-event': !attended,
              'text-gray-light': attended,
            }
          )}
        >
          {place}, {country}
        </p>
        <p
          className={classNames(
            `${cardTextCityClass} font-normal mt-[9px]`,
            'lg:mt-[4px]',
            {
              'text-event': !attended,
              'text-gray-light': attended,
            }
          )}
        >
          {formatDate(starts_at)} at {formatTime(starts_at)}
        </p>
      </div>

      {showShareOptions && (
        <div
          ref={shareOptionsRef}
          className="absolute top-0 right-0 z-10"
          onClick={(event) => event.stopPropagation()}
        >
          <ShareOptions
            onCopyLink={handleCopyLink}
            onClose={(e) => handleCloseShareOptions(e)}
          />
        </div>
      )}
    </div>
  );
};
