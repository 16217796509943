import { ReactComponent as NotFoundSvg } from '../../../../src/svg/MagnifyingGlassError.svg';
// import { ReactComponent as SlidersSvg } from '../../../../src/svg/slidersHorizontal.svg';
import { Loader } from '../../../components/loader/cene/loader';
// import { SearchInput } from '../../../components/searchInput/searchInput';
import translations from '../../../constants/translations.json';
import { useAutoCloseFilters } from '../../../hooks/useAutoCloseFilters';
import { useDisableBodyScroll } from '../../../hooks/useDisableBodyScroll';
import { useGetLikedEvents } from '../../../hooks/useGetUserLikedEvents';
import { useSupabase } from '../../../providers/supabase';
import { DiscoverSection } from '../../../sections/events/cene/discover';
// import { EventsFilter } from '../../../sections/events/cene/eventsFilter';
// import { FilterSection } from '../../../sections/events/cene/filter';
// import { FiltersMobile } from '../../../sections/events/cene/filtersMobile';
// import { GenreSelector } from '../../../sections/events/cene/genreSelector';
// import GetOnTheWaitlistSection from '../../../sections/events/cene/getOnTheWaitlist';
import HotRightNowSection from '../../../sections/events/cene/hotRightNow';
// import JustAnnouncedSection from '../../../sections/events/cene/justAnnounced';
// import { StatusFilter } from '../../../sections/events/cene/statusFilter';
import { supabaseCeneClient } from '../../../supabase/supabase';
import { Event } from '@ceneteam/namespace';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

const Loading = () => (
  <div className="flex justify-center items-center h-screen">
    <Loader />
  </div>
);

const EventsCenePage = () => {
  const { session } = useSupabase();
  // setSearchTerm
  const [searchTerm] = useState('');
  const { loadEvents, eventsLiked } = useGetLikedEvents();
  // setSelectedStatus
  const [selectedStatus] = useState<
    | typeof translations.eventPage.active
    | typeof translations.eventPage.comingSoon
  >(translations.eventPage.active);
  // setPriceRange
  const [priceRange] = useState<number[]>([0, 10000]);
  // setSelectedGenres
  const [selectedGenres] = useState<string[]>([]);

  const [selectedCities] = useState<string[]>(['Nigeria']);
  // setShowSaved
  const [showSaved] = useState(false);
  const [showFiltersPage, setShowFiltersPage] = useState(false);
  // setIsWaitlistActive
  const [isWaitlistActive] = useState(false);
  // filtersChanged
  const [, setFiltersChanged] = useState(false);
  // setDateRange
  const [dateRange] = useState<(Date | null)[]>([null, null]);

  // const [resetFilters, setResetFilters] = useState(false);
  const [startDate, endDate] = dateRange;
  const [events, setEvents] = useState<Event[]>([]);
  const [isLoading, setLoading] = useState(false);

  console.log(startDate, endDate);

  useEffect(() => {
    fetchEventsCene();
    loadEvents();
  }, []);

  const fetchEventsCene = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabaseCeneClient
        .from('events')
        .select('*');

      const now = new Date();
      const finishedEvents =
        data?.filter((event: Event) => new Date(event?.ends_at || '') > now) ||
        [];
      if (error) throw error;
      setEvents(finishedEvents || []);
    } catch (error) {
      console.error('Error fetching Cene events:', error);
    } finally {
      setLoading(false);
    }
  };

  useDisableBodyScroll(showFiltersPage);

  const handleCloseFilters = () => {
    setShowFiltersPage(false);
  };

  useAutoCloseFilters({ showFiltersPage, handleCloseFilters });

  // const handleShowFilters = () => {
  //   setShowFiltersPage(true);
  // };

  // const handleApplyFilters = () => {
  //   setShowFiltersPage(false);
  // };

  // const handleClearFilters = () => {
  //   handleResetFilters();
  // };

  const sessionType = session?.type === 'cene+';

  // const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearchTerm(event.target.value);
  // };

  // const handleStatusChange = (
  //   status:
  //     | typeof translations.eventPage.active
  //     | typeof translations.eventPage.comingSoon
  // ) => {
  //   setSelectedStatus(status);
  // };

  const checkFiltersChanged = () => {
    const isChanged =
      selectedGenres.length > 0 ||
      searchTerm !== '' ||
      priceRange[0] !== 0 ||
      priceRange[1] !== 10000 ||
      dateRange[0] !== null ||
      dateRange[1] !== null ||
      selectedCities.length > 0 ||
      selectedStatus !== translations.eventPage.active ||
      showSaved ||
      isWaitlistActive;

    setFiltersChanged(isChanged);
  };

  useEffect(() => {
    checkFiltersChanged();
  }, [
    selectedGenres,
    searchTerm,
    priceRange,
    dateRange,
    selectedCities,
    selectedStatus,
    showSaved,
    isWaitlistActive,
  ]);

  // const handleGenreChange = (genre: string) => {
  //   setSelectedGenres((prev) =>
  //     prev.includes(genre)
  //       ? prev.filter((item) => item !== genre)
  //       : [...prev, genre]
  //   );
  // };

  // const handleCityChange = (cities: string[]) => {
  //   setSelectedCities(cities);
  // };

  // const handlePriceRangeChange = (newRange: number[]) => {
  //   setPriceRange(newRange);
  // };

  // const handleShowSaved = () => {
  //   setShowSaved((prev) => !prev);
  // };

  // const handleWaitlistChange = (isWaitlistActive: boolean) => {
  //   setIsWaitlistActive(isWaitlistActive);
  // };

  // const handleDateRangeConfirm = (
  //   startDate: Date | null,
  //   endDate: Date | null
  // ) => {
  //   if (startDate && !endDate) {
  //     setDateRange([startDate, startDate]);
  //   } else {
  //     setDateRange([startDate, endDate]);
  //   }
  // };

  // const handleResetFilters = () => {
  //   setSelectedCities(['Nigeria']);
  //   setSelectedGenres([]);
  //   setSearchTerm('');
  //   setPriceRange([0, 10000]);
  //   setDateRange([null, null]);
  //   setShowSaved(false);
  //   setIsWaitlistActive(false);
  //   setSelectedStatus(translations.eventPage.active);
  //   setResetFilters(true);
  //   setTimeout(() => setResetFilters(false), 0);
  //   setFiltersChanged(false);
  // };

  const noEventsFound = searchTerm && events.length === 0;

  // const isDefaultFilters = () => {
  //   return (
  //     selectedGenres.length === 0 &&
  //     !searchTerm &&
  //     priceRange[0] === 0 &&
  //     priceRange[1] === 10000 &&
  //     dateRange[0] === null &&
  //     dateRange[1] === null &&
  //     !showSaved &&
  //     !isWaitlistActive &&
  //     selectedStatus === translations.eventPage.active &&
  //     Array.isArray(selectedCities) &&
  //     selectedCities.length === 1 &&
  //     selectedCities[0] === 'Nigeria'
  //   );
  // };

  const Guest = session === null;
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <h1
            className={classNames(
              'text-white text-[2.25rem] leading-[64px] mt-[16px] font-light mb-[24px]',
              'lg:text-[4.5rem] lg:leading-[80px] ',
              Guest ? '' : 'lg:mt-[80px]'
            )}
          >
            {translations.eventPage.titleOne}
          </h1>

          {!Guest && (
            <>
              {/* <div
                className={classNames([
                  'flex-row items-center lg:space-x-[16px] mb-6',
                  'lg:w-full lg:flex ',
                ])}
              >
                <div className={classNames(['lg:block hidden '])}>
                  <FilterSection
                    defaultCities={selectedCities}
                    onCityChange={handleCityChange}
                    priceRange={priceRange}
                    onPriceRangeChange={handlePriceRangeChange}
                    onDateRangeConfirm={handleDateRangeConfirm}
                    resetFilters={resetFilters}
                  />
                </div>
                <div className={classNames(['lg:block hidden'])}>
                  <StatusFilter
                    selectedStatus={selectedStatus}
                    onStatusChange={handleStatusChange}
                  />
                </div>
                <div className={classNames(['lg:block hidden'])}>
                  <EventsFilter
                    onShowSaved={handleShowSaved}
                    onWaitlistChange={handleWaitlistChange}
                    resetFilters={resetFilters}
                  />
                </div>
                <SearchInput
                  searchTerm={searchTerm}
                  onSearchChange={handleSearchChange}
                />
              </div>
              <div className={classNames(['lg:block hidden'])}>
                <GenreSelector
                  showResetButton={filtersChanged}
                  selectedGenres={selectedGenres}
                  onGenreChange={handleGenreChange}
                  onResetFilters={handleResetFilters}
                  isDefaultFilters={isDefaultFilters()}
                />
              </div> */}
            </>
          )}

          {noEventsFound ? (
            <div className="flex flex-col items-center lg:my-[120px] my-[64px] ">
              <div className="lg:h-[398px] flex flex-col justify-center items-center">
                <NotFoundSvg className="lg:mb-[8px] mb-[16px]" />
                <h2
                  className={classNames(
                    'text-white mb-[8px]',
                    'lg:text-[3rem] lg:leading-[56px] text-[2rem] leading-[40px] font-light'
                  )}
                >
                  {translations.eventPage.notFoundTitle}
                </h2>
                <p className="text-white text-[1.0625rem] leading-[22px] text-center font-light">
                  {translations.eventPage.notFondDesc}
                </p>
              </div>
            </div>
          ) : searchTerm ? (
            <h2 className="text-event text-[2rem] leading-[40px] mb-[44px] font-normal flex items-start">
              {translations.eventPage.title}
            </h2>
          ) : (
            <div className="mb-[72px]">
              <HotRightNowSection
                events={events}
                eventsLiked={eventsLiked}
                isLoading={isLoading}
                sessionType={sessionType}
              />
              {/* <JustAnnouncedSection
                events={events}
                isLoading={isLoading}
                sessionType={sessionType}
              /> */}
              <div className={classNames('mt-[72px]')}>
                <DiscoverSection
                  sessionType={session?.type}
                  title={translations.eventDiscover.title}
                  desc={translations.eventDiscover.desc}
                  buttonText={translations.eventDiscover.textButton}
                />
              </div>
              {/* <GetOnTheWaitlistSection
                events={events}
                isLoading={isLoading}
                sessionType={sessionType}
              /> */}
            </div>
          )}

          {/* <div className="fixed bottom-0 left-1/2 z-10 transform -translate-x-1/2 mb-4 flex bg-black h-[56px] space-x-[4px] justify-center items-center p-[4px] rounded-tl-[20px] rounded-br-[20px] lg:hidden whitespace-nowrap">
            <button
              onClick={() => handleStatusChange(translations.eventPage.active)}
              className={classNames(
                'px-[16px] h-[48px] rounded-tl-[16px] rounded-br-[16px]',
                selectedStatus === translations.eventPage.active
                  ? 'bg-event'
                  : 'bg-gray-medium text-event'
              )}
            >
              {translations.eventPage.active}
            </button>
            <button
              onClick={() =>
                handleStatusChange(translations.eventPage.comingSoon)
              }
              className={classNames(
                'px-[16px] h-[48px] rounded-tl-[16px] rounded-br-[16px]',
                selectedStatus === translations.eventPage.comingSoon
                  ? 'bg-event'
                  : 'bg-gray-medium text-event'
              )}
            >
              {translations.eventPage.comingSoon}
            </button>
            <button
              onClick={handleShowFilters}
              className="bg-black border border-gray-medium rounded-tl-[16px] rounded-br-[16px] w-[48px] h-[48px] flex items-center justify-center"
            >
              <SlidersSvg />
            </button>
          </div> */}
          {/* {showFiltersPage && (
            <div
              className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
              style={{ zIndex: 200 }}
            >
              <div className="h-full max-w-md mx-4 bg-black overflow-auto scrollbar-hidden">
                <FiltersMobile
                  onApplyFilters={handleApplyFilters}
                  onClearFilters={handleClearFilters}
                  onClose={handleCloseFilters}
                  priceRange={priceRange}
                  onPriceRangeChange={handlePriceRangeChange}
                  selectedStatus={selectedStatus}
                  onStatusChange={handleStatusChange}
                  onShowSaved={handleShowSaved}
                  onWaitlistChange={handleWaitlistChange}
                  selectedGenres={selectedGenres}
                  onGenreChange={handleGenreChange}
                  showResetButton={resetFilters}
                  onResetFilters={handleResetFilters}
                  selectedCity={selectedCities}
                  onCityChange={handleCityChange}
                  dateRange={dateRange}
                  onDateRangeConfirm={handleDateRangeConfirm}
                  resetFilters={resetFilters}
                />
              </div>
            </div>
          )} */}
        </>
      )}
    </>
  );
};

export default EventsCenePage;
