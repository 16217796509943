import Container from '../../components/container/container';
import ContainerWithHeader from '../../components/container/containerWithHeader';
import {
  ROUTER_SETTINGS_INTERESTS,
  ROUTER_SETTINGS_MUSIC,
  ROUTER_SETTINGS_NOTIFICATIONS,
  ROUTER_SETTINGS_RESET_PASSWORD,
  ROUTE_CONTACT_WITH_US,
  ROUTE_DELETE_ACCOUNT,
} from '../../constants/routes';
import translations from '../../constants/translations.json';
import { useSupabase } from '../../providers/supabase';
import { ReactComponent as ArrowRightSVG } from '../../svg/arrow-right.svg';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

const SettingsPage = () => {
  const { session } = useSupabase();
  const navigate = useNavigate();
  const sessionType = session?.type === 'cene';

  const preferences = [
    {
      id: 1,
      title: translations.settings.myPreferences.notifications,
      link: ROUTER_SETTINGS_NOTIFICATIONS,
    },
    {
      id: 2,
      title: translations.settings.myPreferences.interests,
      link: ROUTER_SETTINGS_INTERESTS,
    },
    {
      id: 3,
      title: translations.settings.myPreferences.music,
      link: ROUTER_SETTINGS_MUSIC,
    },
  ];

  const manageAccountList = [
    {
      id: 1,
      title: translations.settings.manageAccount.contactSupport,
      link: ROUTE_CONTACT_WITH_US,
    },
    {
      id: 2,
      title: translations.settings.manageAccount.knowledgebase,
      link: 'https://cenexyz.zendesk.com/hc/en-us',
    },
    {
      id: 3,
      title: translations.settings.manageAccount.resetPassword,
      link: ROUTER_SETTINGS_RESET_PASSWORD,
    },
    {
      id: 4,
      title: translations.settings.manageAccount.deleteAccount,
      link: ROUTE_DELETE_ACCOUNT,
    },
  ];

  const content = [
    <div key={1} className="w-full flex flex-col items-center gap-[1.5rem]">
      <h2
        className={classNames(
          'text-[1.3125rem]/[1.3125rem] font-light text-white max-lg:text-[1.0625rem]/[1.375rem] cursor-default'
        )}
      >
        {translations.settings.myPreferences.title}
      </h2>
      <div className="flex flex-col items-center gap-[1rem] w-full">
        {preferences.map((item) => (
          <div
            key={item.id}
            className="w-full flex items-center justify-between cursor-pointer"
            onClick={() => navigate(item.link)}
          >
            <h3
              className={classNames(
                'text-[1rem]/[1.3125rem] font-light',
                'max-lg:text-[1rem]/[1.5rem]',
                'text-generic'
              )}
            >
              {item.title}
            </h3>
            <ArrowRightSVG style={{ stroke: '#B8CAD9' }} />
          </div>
        ))}
      </div>
    </div>,
    <div key={2} className="w-full flex flex-col items-center gap-[1.5rem]">
      <h2 className="text-[1.3125rem]/[1.3125rem] font-light text-white max-lg:text-[1.0625rem]/[1.375rem]">
        {translations.settings.manageAccount.title}
      </h2>
      <div className="flex flex-col items-center gap-[1rem] w-full">
        {manageAccountList.map((item) => (
          <div
            key={item.id}
            className="w-full flex items-center justify-between cursor-pointer"
            onClick={() => {
              if (item.link.startsWith('http')) {
                window.open(item.link, '_blank');
              } else {
                navigate(item.link);
              }
            }}
          >
            <h3
              className={classNames(
                'text-[1rem]/[1.3125rem] font-light',
                'max-lg:text-[1rem]/[1.5rem]',
                'text-generic'
              )}
            >
              {item.title}
            </h3>
            <ArrowRightSVG style={{ stroke: '#B8CAD9' }} />
          </div>
        ))}
      </div>
    </div>,
  ];
  return (
    <ContainerWithHeader>
      <div className="!w-[420px] max-lg:w-full flex flex-col gap-[1rem] cursor-default">
        <Container
          title={translations.settings.title}
          contentList={content}
          sessionType={sessionType}
        />
      </div>
    </ContainerWithHeader>
  );
};

export default SettingsPage;
