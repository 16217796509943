import { ReactComponent as TrashSvg } from '../../../src/svg/TrashRed.svg';
import { Button } from '../../components/button/button';
import Container from '../../components/container/container';
import { CropModal } from '../../components/cropModal';
import { ProfileInput } from '../../components/profileInput/profileInput';
import Toast from '../../components/toast/toast';
import { ROUTE_HOME } from '../../constants/routes';
import { ICropedPicture, useCrop } from '../../hooks/useCrop';
import { useSupabase } from '../../providers/supabase';
import {
  profileCenePlusSchema,
  profileCeneSchema,
} from '../../schemas/profileSchema';
import {
  supabaseCeneClient,
  supabaseCenePlusClient,
} from '../../supabase/supabase';
import { ReactComponent as CloseSVG } from '../../svg/icon-close.svg';
import { ReactComponent as LogoSVG } from '../../svg/logo.svg';
import { ReactComponent as LogoPlusSVG } from '../../svg/logotype.svg';
import { ReactComponent as EditSVG } from '../../svg/pencilSimple.svg';
import { ReactComponent as AvatarPlaceholderSVG } from '../../svg/sdoer.svg';
import { User } from '../../types/user';
import { ataUrlToFile } from '../../utils/fileManger';
import CustomDatePicker from './datepicker';
import { CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Crop } from 'react-image-crop';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
  NOT_TO_SAY = 'Prefer not to say',
}

const ProfilePage = () => {
  const { session } = useSupabase();
  const sessionType = session?.type === 'cene';
  const id = session?.user?.id;
  const [customer, setCustomer] = useState<User | null>(null);
  const [isSuccess, setSuccess] = useState(false);
  const [isOpenCropper, setOpenCropper] = useState<ICropedPicture[]>([]);
  const [isVisibleToast, setVisibleToast] = useState(false);
  const [avatar, setAvatar] = useState<string | null>(null);
  const [isUploadingAvatar, setUploadingAvatar] = useState(false);
  const [isDeletingAvatar, setDeletingAvatar] = useState(false);

  const navigate = useNavigate();

  const { choosePictures, initialCrop } = useCrop({
    onChange: (value) => {
      setOpenCropper(value);
    },
  });

  useEffect(() => {
    if (customer?.metadata?.profilePictureUrl) {
      setAvatar(customer?.metadata?.profilePictureUrl);
    }
  }, [customer]);

  const formik = useFormik({
    initialValues: {
      gender: customer?.gender || Gender.MALE,
      // phone: customer?.phone_number || '',
      companyName: customer?.metadata?.company_name || '',
      jobTitle: customer?.metadata?.job_title || '',
      birthdate: customer?.birthdate || null,
    },
    validationSchema: sessionType ? profileCeneSchema : profileCenePlusSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const client = sessionType
          ? supabaseCeneClient
          : supabaseCenePlusClient;

        const updatedData = {
          gender: values.gender,
          birthdate: values.birthdate,
          metadata: sessionType
            ? undefined
            : {
                job_title: values.jobTitle,
                company_name: values.companyName,
              },
        };

        const { error } = await client
          .from('customers')
          .update(updatedData)
          .eq('id', id);

        if (error) {
          setSuccess(false);
        }
        setSuccess(true);

        setVisibleToast(true);

        setTimeout(() => {
          setVisibleToast(false);
        }, 3000);
      } catch (error) {
        console.log(error, 'error');

        setSuccess(false);
        setVisibleToast(true);

        setTimeout(() => {
          setVisibleToast(false);
        }, 3000);
      }
    },
  });

  const handleCropPicture = async (
    pictureId: string,
    picture: string,
    crop: Crop
  ) => {
    setUploadingAvatar(true);

    try {
      const response = await initialCrop(picture, crop);

      const client = sessionType ? supabaseCeneClient : supabaseCenePlusClient;

      const file = ataUrlToFile(response.cropedImage, `${uuidv4}.png`);

      let profilePictureUrl = '';

      if (file) {
        const path = `${id}/${uuidv4()}.png`;

        if (avatar) {
          await client.storage.from('avatars').remove([avatar]);
        }

        await client.storage
          .from('avatars')
          .upload(path, file, { upsert: true, cacheControl: '0' });

        const { data: publicUrl } = client.storage
          .from('avatars')
          .getPublicUrl(path as string);

        profilePictureUrl = publicUrl.publicUrl as string;

        setAvatar(profilePictureUrl);
      }

      await client
        .from('customers')
        .update({ metadata: { profilePictureUrl } })
        .eq('id', id);
    } catch (error) {
      console.log(error);
    } finally {
      setUploadingAvatar(false);
      setOpenCropper([]);
    }
  };

  const gotoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    if (id) {
      fetchCustomer();
    }
  }, [id]);

  const handleRemoveAvatar = async () => {
    try {
      if (!avatar) return;

      setDeletingAvatar(true);

      const client = sessionType ? supabaseCeneClient : supabaseCenePlusClient;

      await client.storage.from('avatars').remove([avatar]);

      await client
        .from('customers')
        .update({ metadata: { profilePictureUrl: null } })
        .eq('id', id);

      setAvatar(null);
    } catch (error) {
      console.log(error);
    } finally {
      setDeletingAvatar(false);
    }
  };

  const fetchCustomer = async () => {
    try {
      const client = sessionType ? supabaseCeneClient : supabaseCenePlusClient;

      const { data, error } = await client
        .from('customers')
        .select('*')
        .eq('id', id)
        .single();
      if (error) throw error;

      setCustomer(data);
    } catch (error) {
      console.error('Error fetching customer:', error);
    }
  };

  const content = [
    <div key={1} className="w-full flex flex-col items-center gap-[1.5rem]">
      <h2 className="text-[1.3125rem]/[1.3125rem] font-light text-white max-lg:text-[1.0625rem]/[1.375rem]">
        My details
      </h2>
      <div className="flex items-center gap-[1rem] w-full">
        <div className="relative">
          {avatar ? (
            <div className="relative">
              <img
                width="64px"
                height="64px"
                src={avatar}
                alt="User photo"
                className="rounded-tl-[20px] rounded-br-[20px] border border-gray-medium"
              />
            </div>
          ) : (
            <AvatarPlaceholderSVG
              style={{ stroke: sessionType ? '#EC76F7' : '#B8CAD9' }}
            />
          )}
          <div className="absolute bottom-[-10px] justify-between z-10 flex w-full">
            {avatar ? (
              <div
                onClick={() => handleRemoveAvatar()}
                color="black"
                className="cursor-pointer z-10 !w-[20px] !h-[20px] rounded-none"
              >
                {isDeletingAvatar ? (
                  <CircularProgress size="10px " />
                ) : (
                  <TrashSvg width={20} height={20} />
                )}
              </div>
            ) : (
              <div />
            )}
            <div
              onClick={() => choosePictures(1)}
              color="black"
              className="cursor-pointer z-10 !w-[20px] !h-[20px] rounded-none"
            >
              <EditSVG width={20} height={20} />
            </div>
          </div>
        </div>
        <div>
          <p
            className={`text-[1.1875rem]/[1.1875rem] font-light ${sessionType ? 'text-white' : 'text-generic'}`}
          >
            {customer?.first_name} {customer?.last_name}
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col gap-[1rem]">
        {/* <ProfileInput
          label="Phone"
          id="phone"
          sessionType={sessionType}
          value={formik.values.phone}
          disabled={!!customer?.phone_number}
        /> */}
        <ProfileInput
          label="E-mail"
          id="e-mail"
          sessionType={sessionType}
          value={customer?.email}
          disabled={!!customer?.email}
        />

        <ProfileInput
          label="Gender"
          id="gender"
          sessionType={sessionType}
          value={formik.values.gender}
          onChange={(e) =>
            formik.setFieldValue(
              'gender',
              (e.target as HTMLSelectElement).value
            )
          }
          onBlur={formik.handleBlur}
          options={[
            { value: Gender.MALE, label: Gender.MALE },
            { value: Gender.FEMALE, label: Gender.FEMALE },
            { value: Gender.NOT_TO_SAY, label: Gender.NOT_TO_SAY },
          ]}
        />

        <div className="flex flex-col text-start">
          <label className="uppercase text-[0.75rem]/[0.75rem] text-gray-light">
            Birth date
          </label>
          <CustomDatePicker
            date={formik.values.birthdate}
            sessionType={sessionType}
            onChange={(newValue) => {
              if (newValue) {
                const formattedDate = format(newValue, 'yyyy-MM-dd');
                formik.setFieldValue('birthdate', formattedDate);
              } else {
                formik.setFieldValue('birthdate', null);
              }
            }}
            disabled={!!customer?.birthdate}
          />
        </div>

        {!sessionType && (
          <>
            <ProfileInput
              label="Company name"
              id="companyName"
              sessionType={sessionType}
              value={formik.values.companyName}
              onChange={(e) =>
                formik.setFieldValue(
                  'companyName',
                  (e.target as HTMLInputElement).value
                )
              }
              onBlur={formik.handleBlur}
              error={formik.errors.companyName}
              touched={Boolean(formik.touched.companyName)}
            />

            <ProfileInput
              label="Job title"
              id="jobTitle"
              sessionType={sessionType}
              value={formik.values.jobTitle}
              onChange={(e) =>
                formik.setFieldValue(
                  'jobTitle',
                  (e.target as HTMLInputElement).value
                )
              }
              onBlur={formik.handleBlur}
              error={formik.errors.jobTitle}
              touched={Boolean(formik.touched.jobTitle)}
            />
          </>
        )}
      </div>
    </div>,
  ];

  return (
    <div className="relative">
      {isVisibleToast && (
        <Toast
          message={
            isSuccess ? 'Profile update successfully' : 'Error updating profile'
          }
          bgColor={isSuccess ? 'bg-event' : 'bg-error'}
          onClose={() => setVisibleToast(false)}
        />
      )}
      <div className="container mx-auto h-[88px] flex items-center justify-between px-[20px]">
        {sessionType ? (
          <LogoSVG
            onClick={() => navigate(ROUTE_HOME)}
            className="min-w-[164px] min-h-[48px] cursor-pointer"
          />
        ) : (
          <LogoPlusSVG
            onClick={() => navigate(ROUTE_HOME)}
            className="min-w-[164px] min-h-[48px] cursor-pointer"
          />
        )}
        <div
          onClick={gotoBack}
          className="cursor-pointer border border-gray-medium w-[48px] h-[48px] flex items-center justify-center rounded-tl-[20px] rounded-br-[20px]"
        >
          <CloseSVG stroke="#B8CAD9" />
        </div>
      </div>
      <div className="lg:w-full  flex justify-center items-center mb-[2.5rem] max-lg:mt:[1rem] max-lg:w-full">
        <div className="!w-[420px] max-lg:w-full flex flex-col gap-[1rem]">
          <Container
            title="Profile"
            contentList={content}
            sessionType={sessionType}
          />
          <div className="w-full flex items-center gap-[1rem]">
            <Button color="outlined" className="w-1/2" onClick={gotoBack}>
              Cancel
            </Button>
            <Button
              color={sessionType ? 'content' : 'generic'}
              className="w-1/2"
              onClick={() => formik.handleSubmit()}
              disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      {isOpenCropper.length !== 0 && (
        <CropModal
          isUploadingAvatar={isUploadingAvatar}
          src={isOpenCropper[0].originalPicture}
          crop={isOpenCropper[0].crop}
          aspect={1}
          onCropComplete={(e) =>
            handleCropPicture(
              isOpenCropper[0].id,
              isOpenCropper[0].originalPicture,
              e as Crop
            )
          }
          onClose={() => setOpenCropper([])}
        />
      )}
    </div>
  );
};

export default ProfilePage;
