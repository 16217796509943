import { ROUTE_HOME } from '../../constants/routes';
import { useSupabase } from '../../providers/supabase';
import { ReactComponent as CloseSVG } from '../../svg/icon-close.svg';
import { ReactComponent as LogoSVG } from '../../svg/logo.svg';
import { ReactComponent as LogoPlusSVG } from '../../svg/logotype.svg';
import { ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  children: ReactNode;
}

const ContainerWithHeader = ({ children }: Props) => {
  const { session } = useSupabase();
  const navigate = useNavigate();
  const sessionType = session?.type === 'cene';

  const gotoBack = useCallback(() => {
    navigate(ROUTE_HOME);
  }, [navigate]);
  return (
    <div className="relative">
      <div className="container mx-auto h-[88px] flex items-center justify-between px-[20px]">
        {sessionType ? (
          <LogoSVG
            onClick={() => navigate(ROUTE_HOME)}
            className="min-w-[164px] min-h-[48px] cursor-pointer"
          />
        ) : (
          <LogoPlusSVG
            onClick={() => navigate(ROUTE_HOME)}
            className="min-w-[164px] min-h-[48px] cursor-pointer"
          />
        )}
        <div
          onClick={gotoBack}
          className="cursor-pointer border border-gray-medium w-[48px] h-[48px] flex items-center justify-center rounded-tl-[20px] rounded-br-[20px]"
        >
          <CloseSVG stroke="#B8CAD9" />
        </div>
      </div>
      <div className="lg:w-full flex justify-center items-center mb-[2.5rem] max-lg:mt:[1rem]">
        {children}
      </div>
    </div>
  );
};

export default ContainerWithHeader;
