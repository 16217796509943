import { useSupabase } from '../../providers/supabase';
import { CardArtist } from './cardArtist';
import { Event } from '@ceneteam/namespace';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';

interface CarouselProps {
  cards: Event[];
  eventsLiked: Event[];
  handleHeartClick: (title: string) => void;
}

export const CarouselArtists: React.FC<CarouselProps> = ({
  cards,
  eventsLiked,
  handleHeartClick,
}) => {
  const { session } = useSupabase();
  const sessionType = session?.type === 'cene+';

  const getSlidesToShow = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1440) return 4;
    if (screenWidth >= 1024) return 3.5;
    if (screenWidth >= 768) return 3;
    return 1;
  };

  const [slidesToShow, setSlidesToShow] = useState(getSlidesToShow());
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef<Slider>(null);

  const calculateIndicators = (slidesToShow: number) => {
    const numberOfIndicators = Math.ceil(cards.length / slidesToShow);
    return Array.from({ length: numberOfIndicators - 1 }, (_, index) => index);
  };

  const disableScroll = cards.length <= slidesToShow;

  const settings = {
    dots: false,
    infinite: !disableScroll,
    speed: 300,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    arrows: false,
    autoplay: !disableScroll && slidesToShow < cards.length,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    afterChange: (current: number) => setCurrentSlide(current),
    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          centerPadding: '24px',
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          centerPadding: '24px',
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          centerPadding: '24px',
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2.5,
        },
      },
      {
        breakpoint: 480,
        settings: {
          autoplay: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const handleResize = () => {
      setSlidesToShow(getSlidesToShow());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const indicators = calculateIndicators(slidesToShow);

  const handleIndicatorClick = (index: number) => {
    sliderRef.current?.slickGoTo(index * slidesToShow);
  };

  return (
    <div className="relative w-full">
      <div className="hidden md:block absolute top-0 left-0 w-[300px] h-full bg-gradient-to-r from-[#000000] via-transparent to-transparent pointer-events-none z-[4]" />
      <div className="hidden md:block absolute top-0 right-0 w-[300px] h-full bg-gradient-to-l from-[#000000] via-transparent to-transparent pointer-events-none z-[4]" />

      <div className="slider-container">
        <Slider {...settings} className="slick-slider w-full">
          {cards.map((event) => (
            <div
              key={event.id}
              className={classNames('flex-shrink-0 px-4', 'max-md:px-0')}
            >
              <CardArtist
                event={event}
                handleHeartClick={handleHeartClick}
                eventsLiked={eventsLiked}
              />
            </div>
          ))}
        </Slider>
      </div>
      <div className="absolute bottom pt-[24px] w-full h-[12px] flex justify-center items-center space-x-[10px] z-[4] ">
        {indicators.map((_, index) => (
          <button
            key={index}
            className={`rounded-big ${
              Math.floor(currentSlide / slidesToShow) === index
                ? `${sessionType ? 'bg-generic' : 'bg-event'} w-[12px] h-[12px]`
                : 'bg-gray-medium w-[6px] h-[6px]'
            }`}
            onClick={() => handleIndicatorClick(index)}
          />
        ))}
      </div>
    </div>
  );
};
