import Steps from '../../../../components/steps/steps';
import { usePreventUnload } from '../../../../hooks/usePreventUpload';
import { useSupabase } from '../../../../providers/supabase';
import {
  RegisterSchema,
  newRegisterSchema,
} from '../../../../schemas/registerSchema';
import { supabaseCeneClient } from '../../../../supabase/supabase';
// import { CheckEmail } from '../../../checkEmail/checkEmail';
import { EmailForm } from '../../emailForm/emailForm';
import { GenderAndBirthForm } from '../../genderAndBirthStep/cene/genderAndBirthForm';
// import { GenrePreferencesForm } from '../../genrePreferencesStep/cene/genrePreferences';
// import { InterestPreferencesForm } from '../../interestPreferencesStep/cene/interestPreferences';
import { NameForm } from '../../nameStep/cene/nameForm';
// import { Notifications } from '../../notificationStep/notificationForm';
// import { VerifyForm } from '../../otpStep/cene/verifyForm';
import { PasswordForm } from '../../passwordStep/cene/passwordForm';
import { PhoneForm } from '../../phoneStep/cene/phoneForm';
import { countryCodes } from '../../phoneStep/countryCodes';
import { Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface RegisterFormProps {
  onLoading: () => void;
}

export const RegisterForm = ({ onLoading }: RegisterFormProps) => {
  const [activeStep, setStep] = useState(1);
  const navigate = useNavigate();
  const { session } = useSupabase();

  const [searchParams] = useSearchParams();
  const [isFormDirty, setIsFormDirty] = useState(false);
  usePreventUnload(isFormDirty);

  useEffect(() => {
    if (
      activeStep !== 7 &&
      !session?.onboardingStatus &&
      searchParams.get('verify_email') === 'verified'
    ) {
      setStep(7);
    }
  }, [session]);

  const onBackStep = useCallback(() => {
    if (activeStep === 1) return navigate(-1);

    setStep(activeStep - 1);
  }, [activeStep]);

  const nextStep = useCallback(
    (values?: RegisterSchema) => {
      if (activeStep === 10 && values) {
        return onSubmit(values);
      }

      setStep(activeStep + 1);
    },
    [activeStep]
  );

  const defaultCountry = countryCodes.find((_, index) => index === 0);

  const onSubmit = async (values: RegisterSchema) => {
    const { data: userData } = await supabaseCeneClient.auth.updateUser({
      password: values.password,
    });

    const { error } = await supabaseCeneClient
      .from('customers')
      .update([
        {
          interests: values.interests,
          genres: values.genres,
          notifications: values?.notifications,
          onboarding_finished: true,
        },
      ])
      .eq('user_id', userData.user?.id);

    if (!error) onLoading();
  };

  const isSkip = activeStep === 8 || activeStep === 9 || activeStep === 10;

  return (
    <Formik<RegisterSchema>
      initialValues={{
        firstName: '',
        lastName: '',
        gender: 'Male',
        dateOfBirth: null,
        country: `${defaultCountry?.title}`,
        countryCode: '',
        phone: '',
        email: '',
        genres: [],
        interests: [],
        password: '',
        confirmPassword: '',
        agreement: false,
        notifications: [],
      }}
      onSubmit={() => {}}
      validationSchema={newRegisterSchema}
    >
      {/* setFieldValue */}
      {({ values, dirty }) => {
        useEffect(() => {
          setIsFormDirty(dirty);
        }, [dirty]);

        return (
          <Steps
            hideBackButton={activeStep === 6 || activeStep === 7}
            activeStep={activeStep}
            onBack={onBackStep}
            onSkip={() => {
              // if (activeStep === 8) setFieldValue('genres', []);
              // if (activeStep === 9) setFieldValue('interests', []);
              if (activeStep === 5) {
                return onSubmit({ ...values, notifications: [] });
              }
              nextStep();
            }}
            canSkip={isSkip}
          >
            <NameForm onNext={nextStep} />
            <GenderAndBirthForm onNext={nextStep} />
            <PhoneForm onNext={nextStep} />
            {/* <VerifyForm onNext={nextStep} /> */}
            <EmailForm onNext={nextStep} />
            <PasswordForm />
            {/* <CheckEmail />
            <GenrePreferencesForm onNext={nextStep} />
            <InterestPreferencesForm onNext={nextStep} />
            <Notifications onNext={() => onSubmit(values)} /> */}
          </Steps>
        );
      }}
    </Formik>
  );
};
