import FaviconManager from '../../../../components/faviconManager/faviconManager';
import { RegisterGuestForm } from '../../../../forms/signup/registerForm/cene/registerGuest';
import { ReactComponent as LogoSVG } from '../../../../svg/logotype.svg';
import { LoadingPage } from '../../../loading/guest/loadingPage';
import classNames from 'classnames';
import { useState } from 'react';

export const SignupCeneGuestPage = () => {
  const [isLoading, setLoading] = useState(false);

  if (isLoading) return <LoadingPage />;
  return (
    <>
      <FaviconManager isCenePlus />
      <div
        className={classNames([
          'container mx-auto h-[88px] flex items-center justify-between mb-[40px] px-[20px]',
        ])}
      >
        <LogoSVG className="w-[164px] h-[48px]" />
      </div>
      <div className="container mx-auto flex flex-col items-center max-w-[422px]">
        <RegisterGuestForm onLoading={() => setLoading(true)} />
      </div>
    </>
  );
};
