import { ReactComponent as CheckBlackSvg } from '../../../src/svg/checkBlack.svg';
import { useSupabase } from '../../providers/supabase';
import React from 'react';

interface TermsCheckboxProps {
  isChecked: boolean;
  onChange: (checked: boolean) => void;
  label: string;
}

export const TermsCheckbox: React.FC<TermsCheckboxProps> = ({
  isChecked,
  onChange,
  label,
}) => {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };
  const { session } = useSupabase();
  const sessionType = session?.type === 'cene';

  return (
    <div className="flex items-center mt-[24px]">
      <div className="relative flex items-center">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
          id="terms"
          className={`appearance-none border border-gray-light w-[24px] h-[24px] rounded-[4px] ${
            isChecked ? (sessionType ? 'bg-event' : 'bg-generic') : 'bg-black'
          } flex items-center justify-center cursor-pointer mr-[16px]`}
        />
        {isChecked && (
          <CheckBlackSvg className="absolute w-[16px] h-[16px] ml-[4px] pointer-events-none" />
        )}
      </div>
      <label
        htmlFor="terms"
        className="text-generic text-[0.875rem] leading-[14px] font-light"
      >
        {label}
      </label>
    </div>
  );
};
