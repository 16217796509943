import { ReactComponent as LeftArrowSvg } from '../../../src/svg/btn-left.svg';
import { ReactComponent as RightArrowSvg } from '../../../src/svg/btn-right.svg';
import { ReactComponent as LeftQuoteSvg } from '../../../src/svg/leftQuote.svg';
import PersonOneSvg from '../../../src/svg/personOne.svg';
import { ReactComponent as RightQuoteSvg } from '../../../src/svg/rightQuote.svg';
import { Icon } from '../../components/icon/icon';
import translations from '../../constants/translations.json';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

const iconMap = [PersonOneSvg];

interface Testimonial {
  text: string;
  name: string;
  role: string;
}

export const BurstTestimonial = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const testimonials: Testimonial[] =
    translations.testimonials.burstTestimonials;

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [testimonials.length]);

  const isActive = (index: number) => activeIndex === index;

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setActiveIndex(
      (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
    );
  };

  return (
    <div
      className={classNames([
        'lg:my-[120px] lg:mx-[171px] mx-[20px] my-[72px]',
      ])}
    >
      <div className={classNames('text-white flex flex-col items-center')}>
        <h2
          className={classNames([
            'text-[2rem] leading-[40px] text-center text-event lg:w-[892px]',
            'lg:text-[4rem] lg:leading-[72px] lg:mb-[64px] mb-[80px] font-light',
          ])}
        >
          {translations.testimonials.title}
        </h2>

        <div
          className={classNames(
            'relative mx-auto',
            'max-w-full sm:max-w-[1098px] xl:max-w-[1098px]'
          )}
        >
          <LeftQuoteSvg
            className={classNames([
              'sm:h-[56px] sm:w-[56px]',
              'absolute xl:-top-4 xl:-left-[48px] xl:-ml-5',
              'xl:pb-0 pb-[8px] left-1/2 transform -translate-x-1/2 -translate-y-full xl:transform-none',
            ])}
          />
          <h1
            className={
              'lg:text-[2rem] lg:leading-[40px] text-[1.5rem] leading-[36px] font-light text-center'
            }
          >
            {testimonials[activeIndex].text}
          </h1>
          <RightQuoteSvg
            className={classNames([
              'h-[56px] w-[56px]',
              'absolute xl:bottom-10 xl:right-0 xl:-mr-[48px]',
              'xl:pt-0 pt-[8px] bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full xl:left-auto xl:translate-x-0',
            ])}
          />
        </div>
        <div className="max-xl:hidden xl:flex items-center xl:flex-row xl:items-start xl:space-y-0 xl:mt-[64px] mt-[80px]">
          {testimonials.map((_, index) => (
            <div
              key={index}
              className={classNames(
                'rounded-full cursor-pointer',
                {
                  'bg-gray-medium': !isActive(index),
                  'bg-white': isActive(index),
                },
                'w-[130px] xl:w-[366px] h-[1px]',
                testimonials.length < 2 && 'hidden'
              )}
              onClick={() => setActiveIndex(index)}
            ></div>
          ))}
        </div>
        <div className="mt-4 flex flex-col items-center sm:flex-row sm:items-start xl:space-x-4">
          <div className="xl:hidden max-xl:flex flex-col items-center justify-center xl:mt-0 mt-[80px]">
            <div className="flex items-center">
              <img
                src={iconMap[activeIndex]}
                className="w-[48px] h-[48px] mb-2 mr-[20px]"
              />
              <div className="text-center">
                <h3 className="text-[1.0625rem] font-normal leading-[22px]">
                  {testimonials[activeIndex].name}
                </h3>
                <h6 className="text-gray-light text-[0.875rem] font-light leading-[14px] mt-[4px]">
                  {testimonials[activeIndex].role}
                </h6>
              </div>
            </div>
            <div
              className={classNames(
                'flex items-center justify-center w-full gap-[16px] mt-[24px]',
                testimonials.length < 2 ? 'hidden' : 'flex'
              )}
            >
              <Icon
                onClick={handlePrev}
                className="p-2 !rounded-tl-[16px] !rounded-br-[16px]"
              >
                <LeftArrowSvg />
              </Icon>
              <Icon
                onClick={handleNext}
                className="p-2 !rounded-tl-[16px] !rounded-br-[16px]"
              >
                <RightArrowSvg />
              </Icon>
            </div>
          </div>

          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className={classNames(
                'max-xl:hidden xl:flex flex-col items-center w-full',
                {
                  block: isActive(index),
                  hidden: !isActive(index),
                }
              )}
            >
              <div className="flex items-center mb-2 rounded-lg w-[350px] justify-center">
                <img
                  src={iconMap[index]}
                  className="lg:w-12 lg:h-12 lg:mr-3 lg:ml-3 w-9 h-9 ml-3 mr-2"
                />
                <div className="text-center sm:text-left">
                  <h3 className="text-[0.75rem] lg:text-[1.0625rem] font-normal leading-[22px]">
                    {testimonial.name}
                  </h3>
                  <h6 className="text-gray-light text-[0.625rem] lg:text-[0.875rem] font-light leading-[14px]">
                    {testimonial.role}
                  </h6>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
