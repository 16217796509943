import { Button } from '../../../components/button/button';
import { ErrorBox } from '../../../components/errorBox/errorBox';
import FaviconManager from '../../../components/faviconManager/faviconManager';
import { Input } from '../../../components/input/cene/input';
import { Label } from '../../../components/label/cene/label';
import { ROUTER_FORGOT_PASSWORD, ROUTE_HOME } from '../../../constants/routes';
import { LoginSchema, loginSchema } from '../../../schemas/loginSchema';
import { supabaseCeneClient } from '../../../supabase/supabase';
import { AuthError } from '@supabase/supabase-js';
import { useFormik } from 'formik';
import { SyntheticEvent, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const redirectPath = location.state?.from || ROUTE_HOME;

  const onSubmit = async (values: LoginSchema) => {
    try {
      setLoading(true);
      setError(null);

      const payload = {
        email: values.email,
        password: values.password,
      };

      const { error } =
        await supabaseCeneClient.auth.signInWithPassword(payload);
      if (error) throw error;

      navigate(redirectPath, { state: { type: 'cene' } });
    } catch (error) {
      if (error instanceof AuthError) {
        setError(error.message);
      }

      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit,
  });

  const handleChangePassword = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    formik.setFieldValue('password', value);
  };

  return (
    <div className="w-[100%] flex flex-col">
      <FaviconManager />

      <div className="flex flex-col gap-[32px]">
        <div>
          <Label id="email" isRequired={true}>
            E-mail
          </Label>
          <Input
            id="email"
            type="email"
            placeholder="Enter your e-mail..."
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.email}
            touched={Boolean(formik.touched.email)}
          />
        </div>
        <div>
          <Label id="password" isRequired={true}>
            Password
          </Label>
          <Input
            id="password"
            type="password"
            placeholder="Enter password..."
            value={formik.values.password}
            onChange={handleChangePassword}
            onBlur={formik.handleBlur}
            error={formik.errors.password}
            touched={Boolean(formik.touched.password)}
          />
          {formik.errors.password && formik.touched.password && (
            <div className="text-[0.875rem] text-red font-light mt-[8px]">
              {formik.errors.password}
            </div>
          )}
          <div className="w-full mt-[8px]  text-end text-gray-light text-[0.875rem] font-light">
            {'Lost your password? '}
            <Link
              to={ROUTER_FORGOT_PASSWORD}
              state={{ type: 'cene' }}
              className=" text-gray-light text-[0.875rem] font-light hover:underline"
            >
              {"Don't worry, we can help"}
            </Link>
          </div>
        </div>
        {error && <ErrorBox message={error} />}

        <Button
          color="content"
          type="button"
          onClick={formik.handleSubmit}
          disabled={loading}
        >
          Login
        </Button>
      </div>
    </div>
  );
};
