import translation from '../../constants/translations.json';
import classNames from 'classnames';
import { useState } from 'react';

interface Props {
  isCene: boolean;
  onFilterChange: (tooltype: string[]) => void;
}

interface Notification {
  title: string;
  isSelected: boolean;
}

export const NotificationsFilter = ({ isCene, onFilterChange }: Props) => {
  const [isAll, setAll] = useState(true);
  const [notificationSwitch, setNotificationSwitch] = useState<Notification[]>(
    isCene ? notifications : notificationsPlus
  );

  const handleSelectAllButtonClick = () => {
    setAll(true);
    onFilterChange(['All']);

    const updatedNotifications = notificationSwitch.map((item) => ({
      ...item,
      isSelected: false,
    }));

    setNotificationSwitch(updatedNotifications);
  };

  const handleSelectElement = (index: number) => {
    const updatedNotifications = notificationSwitch.map((item, idx) =>
      idx === index ? { ...item, isSelected: !item.isSelected } : item
    );

    const allSelected = updatedNotifications.every((item) => item.isSelected);
    setAll(allSelected);
    setNotificationSwitch(updatedNotifications);

    const selectedTitles = allSelected
      ? ['All']
      : updatedNotifications
          .filter((item) => item.isSelected)
          .map((item) => item.title);

    onFilterChange(selectedTitles);
  };

  return (
    <div className={classNames(['pt-[24px] pb-[20px]'])}>
      <p
        className={classNames(
          'text-[1rem]/[1.3125rem] font-light mb-[1rem] text-gray-light'
        )}
      >
        Select categories to filter notifications
      </p>
      <div className={classNames('flex flex-wrap gap-3 w-full mx-auto')}>
        <div
          key="all"
          onClick={() => handleSelectAllButtonClick()}
          className={classNames([
            'relative cursor-pointer font-medium text-[1rem] rounded-tl-[1rem] rounded-br-[1rem] px-[1rem] py-[0.5rem] flex items-center',
            isCene && isAll && 'bg-gray-dark text-event',
            isCene && !isAll && 'bg-gray-dark text-gray-light',

            !isCene && isAll && 'text-black bg-generic border !border-generic',
            !isCene &&
              !isAll &&
              'text-gray-light bg-black border border-gray-medium',
          ])}
        >
          All
        </div>

        {notificationSwitch.map(({ title, isSelected }, index) => (
          <div
            key={index}
            onClick={() => handleSelectElement(index)}
            className={classNames([
              'relative cursor-pointer font-medium text-[1rem] rounded-tl-[1rem] rounded-br-[1rem] px-[1rem] py-[0.5rem] flex items-center border',
              isAll &&
                !isSelected &&
                isCene &&
                'bg-gray-dark text-gray-light !border-none',
              isAll &&
                !isSelected &&
                !isCene &&
                'text-gray-light bg-black border border-gray-medium',
              isAll &&
                isSelected &&
                isCene &&
                'bg-gray-dark text-gray-light !border-none',
              isAll &&
                isSelected &&
                !isCene &&
                'text-gray-light bg-black border border-gray-medium',

              !isAll &&
                isCene &&
                isSelected &&
                'bg-gray-dark text-event !border-none',
              !isAll &&
                isCene &&
                !isSelected &&
                'bg-gray-dark text-gray-light !border-none',
              !isAll &&
                !isCene &&
                !isSelected &&
                '!text-gray-light !bg-black border !border-gray-medium',
              !isAll &&
                !isCene &&
                isSelected &&
                '!text-black !bg-generic !border-generic',
            ])}
          >
            {title}
          </div>
        ))}
      </div>
    </div>
  );
};

const notifications = [
  { title: translation.steps.notifications.events, isSelected: false },
  { title: translation.steps.notifications.ceneNews, isSelected: false },
  { title: translation.steps.notifications.newsletter, isSelected: false },
  { title: translation.steps.notifications.inapp, isSelected: false },
];

const notificationsPlus = [
  { title: translation.steps.notifications.events, isSelected: false },
  { title: translation.steps.notifications.interests, isSelected: false },
  { title: translation.steps.notifications.memberBenefits, isSelected: false },
  { title: translation.steps.notifications.cenePlusUpdates, isSelected: false },
  { title: translation.steps.notifications.newsletter, isSelected: false },
  { title: translation.steps.notifications.inapp, isSelected: false },
];
