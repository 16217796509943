/* eslint-disable @typescript-eslint/no-explicit-any */
// import { ReactComponent as MinusBlackSvg } from '../../../src/svg/minusBlack.svg';
// import { ReactComponent as PlusBlackSvg } from '../../../src/svg/plusBlack.svg';
import { ReactComponent as BackPlusSVG } from '../../../src/svg/arrowLeftCenePlus.svg';
import { Button } from '../../components/button/button';
import { Icon } from '../../components/icon/icon';
import { ReactComponent as CloseSVG } from '../../svg/close.svg';
import { ReactComponent as InfoSVG } from '../../svg/info.svg';
import { SelectableObject } from '../../types/seatsio';
import { TicketInformation } from './ticketInformation';
import { Event, Ticket } from '@ceneteam/namespace';
import classNames from 'classnames';
import { useState } from 'react';

interface IselectableObjectProps {
  selectableObject: SelectableObject;
  onCloseModal: () => void;
  onAddToBasket: () => void;
  event: Event;
}

export const SelectionModal = ({
  selectableObject,
  onCloseModal,
  onAddToBasket,
  event,
}: IselectableObjectProps) => {
  const [isOpenTableInformation, setTableInformation] = useState(false);

  const labelClassName =
    'text-generic text-[1rem] font-light mb-[8px] text-center';

  const getTicketInformation = event.tickets?.find(
    (item2) => item2.name === selectableObject.labels.section
  );

  return (
    <>
      <div className="p-[20px] z-[10] right-[16px] top-[16px] bg-black border-solid border border-[rgba(184,202,217,0.16)] absolute w-[340px] h-[calc(100%-32px)] rounded-br-[30px] rounded-tl-[30px]">
        <div className="flex relative justify-between items-center mb-[25px]">
          {isOpenTableInformation && (
            <div className="z-[1] absolute">
              <Icon onClick={() => setTableInformation(false)}>
                <BackPlusSVG />
              </Icon>
            </div>
          )}
          <div />

          <div className="flex w-full absolute justify-center text-white text-[1rem] font-light">
            {isOpenTableInformation ? 'Included on Table' : 'Your Selection'}
          </div>
          <div
            onClick={onCloseModal}
            className="cursor-pointer z-[1] opacity-[0.8] hover:opacity-[1]"
          >
            <CloseSVG />
          </div>
        </div>
        {isOpenTableInformation ? (
          <TicketInformation information={getTicketInformation as Ticket} />
        ) : (
          <>
            <div className="flex relative justify-between items-center mb-[12px]">
              <div className="text-generic text-[0.97rem] font-light">
                This table comes with
              </div>
              <div
                onClick={() => setTableInformation(true)}
                className="cursor-pointer opacity-[0.8] hover:opacity-[1]"
              >
                <InfoSVG width="20" height="20" />
              </div>
            </div>
            <div className="flex justify-between items-center bg-[rgba(184,202,217,0.06)] border-solid border border-[rgba(184,202,217,0.16)] py-[16px] px-[20px] rounded-br-[30px] rounded-tl-[30px]">
              <div className="mt-[5px]">
                <label className={labelClassName}>Zone</label>
                <div className={classNames(labelClassName, 'text-white mb-0')}>
                  {selectableObject.labels.section}
                </div>
              </div>
              <div className="mt-[5px]">
                <label className={labelClassName}>Table</label>
                <div className={classNames(labelClassName, 'text-white mb-0')}>
                  {selectableObject.objectType === 'Table'
                    ? selectableObject.labels.own
                    : selectableObject.labels.parent}
                </div>
              </div>
              <div className="mt-[5px]">
                <label className={labelClassName}>Seats</label>
                <div className={classNames(labelClassName, 'text-white mb-0')}>
                  {selectableObject.objectType === 'Table'
                    ? `1-${selectableObject.seats.length}`
                    : selectableObject.labels.own}
                </div>
              </div>
            </div>
            <div className="mt-[12px]">
              <div className="flex justify-between">
                <div>
                  <div className="text-gray-light text-[14px] font-light">
                    Price per Seat
                  </div>
                  <div className="text-generic text-[25px] font-light">
                    ₦
                    {selectableObject.objectType === 'Table'
                      ? ((selectableObject as any & { seats: object[] }).pricing
                          .ticketTypes[0].price as number)
                      : ((selectableObject.pricing.ticketTypes as any)[0]
                          .price as number)}
                  </div>
                </div>
              </div>
            </div>
            <Button
              className={`mt-4 bg-generic w-full flex justify-center items-center lg:mb-[40px] mb-[16px]`}
              onClick={onAddToBasket}
            >
              <p className="text-black">Add to basket</p>
            </Button>
          </>
        )}
      </div>
      <div className="absolute w-full h-full z-[1] top-[0] left-[0] bg-[rgba(0,0,0,0.66)]" />
    </>
  );
};
