import ContainerWithHeader from '../../../components/container/containerWithHeader';
import { Icon } from '../../../components/icon/icon';
import { ROUTE_SETTINGS } from '../../../constants/routes';
import { MusicForm } from '../../../forms/settings/music';
import { useSupabase } from '../../../providers/supabase';
import {
  supabaseCeneClient,
  supabaseCenePlusClient,
} from '../../../supabase/supabase';
import { ReactComponent as BackSVG } from '../../../svg/arrow-left.svg';
import { ReactComponent as BackPlusSVG } from '../../../svg/arrowLeftCenePlus.svg';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const MusicPage = () => {
  const { session } = useSupabase();
  const navigate = useNavigate();

  const isCene = session?.type === 'cene';
  const id = session?.user?.id;
  const [genres, setGenres] = useState<string[] | null>(null);

  useEffect(() => {
    if (id) fetchCustomerGenres();
  }, [id]);

  const fetchCustomerGenres = async () => {
    try {
      const client = isCene ? supabaseCeneClient : supabaseCenePlusClient;

      const { data, error } = await client
        .from('customers')
        .select('genres')
        .eq('id', id)
        .single();

      if (error) throw error;

      setGenres(data.genres as string[]);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <ContainerWithHeader>
      <div
        className={classNames(
          'max-lg:w-full max-lg:mt-[1rem]',
          'w-[456px] mt-[2.5rem]'
        )}
      >
        <div className={classNames('w-full flex items-center text-center')}>
          <Icon onClick={() => navigate(ROUTE_SETTINGS)}>
            {isCene ? <BackSVG /> : <BackPlusSVG />}
          </Icon>
          <h1
            className={classNames(
              'text-[1.3125rem]/[1.3125] font-light ml-[calc(100%/3.5)]',
              'max-lg:ml-[calc(100%/5)]',
              isCene ? 'text-content' : 'text-generic'
            )}
          >
            Music genres
          </h1>
        </div>
        <MusicForm selectedGenres={genres} />
      </div>
    </ContainerWithHeader>
  );
};

export default MusicPage;
