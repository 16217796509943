import { ReactComponent as EyeClosedSVG } from '../../../svg/eye-closed.svg';
import { ReactComponent as EyeOpenSVG } from '../../../svg/eye-open.svg';
import { ReactComponent as SuccessSVG } from '../../../svg/success.svg';
import { ReactComponent as SuccessTicketSVG } from '../../../svg/successTicket.svg';
import classNames from 'classnames';
import { KeyboardEvent, LegacyRef, SyntheticEvent, useState } from 'react';

interface IInputProps {
  id: string;
  placeholder?: string;
  value?: string;
  type?: 'text' | 'password' | 'email' | 'phone' | 'otp';
  error?: string[] | string | undefined;
  contactUs?: boolean;
  touched?: boolean | undefined;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void;
  onBlur?: (e: SyntheticEvent<HTMLInputElement>) => void;
  onFocus?: (e: SyntheticEvent<HTMLInputElement>) => void;
  className?: string;
  inputRef?: LegacyRef<HTMLInputElement>;
  autocomplete?: string;
  isPremium?: boolean;
}

export const Input = ({
  placeholder,
  className,
  value,
  autocomplete,
  id,
  type = 'text',
  error,
  contactUs = false,
  onChange = () => null,
  onBlur = () => null,
  onFocus = () => null,
  onKeyDown = () => null,
  touched,
  inputRef,
  isPremium = false,
}: IInputProps) => {
  const [isShowPassword, setShowPassword] = useState(false);
  const isTypePassword = type === 'password';
  const isPhone = id === 'phone';
  const isTypeOtp = type === 'otp';

  const roundedInput =
    id === 'footer' ? 'rounded-tl-[20px] rounded-br-[20px]' : 'rounded-big';

  return (
    <div className={classNames([className, 'w-full relative'])}>
      <input
        ref={inputRef}
        type={isShowPassword ? 'text' : type}
        id={id}
        name={id}
        autoComplete={autocomplete ? autocomplete : 'off'}
        className={classNames([
          'placeholder:text-gray-light w-full placeholder:tracking-[0px] h-[56px] p-[16px] pl-[24px] text-white font-light bg-black border border-gray-medium outline-0 rounded-none rounded-tl-[20px] rounded-br-[20px]',
          'text-[1rem]',
          isPremium ? 'focus:border-generic' : 'focus:border-content',
          touched && error && 'border-red',
          isTypeOtp &&
            'focus:shadow-otp-shadow w-[56px] h-[72px] max-lg:text-center max-sm:h-[64px] max-md:p-[12px] md:pl-[16px] text-center align-center rounded-none rounded-tl-[30px] rounded-br-[30px]',
          isTypePassword &&
            'tracking-[8px] text-[0.875rem] sm:tracking-[0px] sm:text-[1rem] md:tracking-[0px] md:text-h4 lg:tracking-[0px] lg:text-[1rem]',
          isTypePassword && isShowPassword && 'text-[1rem] sm:text-[1rem]',
          roundedInput,
        ])}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={value}
        inputMode={isTypeOtp ? 'numeric' : undefined}
        maxLength={(isTypeOtp && 1) || undefined}
      />
      {!isTypePassword && !isTypeOtp && touched && !error && (
        <div className="absolute cursor-pinter right-[16px] top-[16px]">
          {contactUs ? <SuccessTicketSVG /> : <SuccessSVG />}
        </div>
      )}
      {isTypePassword && (
        <div
          onClick={() => {
            setShowPassword(!isShowPassword);
          }}
          className="absolute cursor-pinter right-[16px] top-[16px]"
        >
          {isShowPassword ? <EyeOpenSVG /> : <EyeClosedSVG />}
        </div>
      )}
      {!isPhone && !isTypeOtp && !isTypePassword && touched && error && (
        <div className="text-[0.875rem] text-red font-light mt-[8px]">
          {error}
        </div>
      )}
    </div>
  );
};
