import { Loader } from '../../../components/loader/cene/loader';
import ReusableTabs from '../../../components/reusableTabs/reusableTabs';
import { useGetLikedEvents } from '../../../hooks/useGetUserLikedEvents';
import { supabaseCeneClient } from '../../../supabase/supabase';
import AboutEventSection from './tabs/about';
import ActionsSection from './tabs/actions';
import LineupSection from './tabs/lineup';
import MediaSection from './tabs/media';
import OrganizerSection from './tabs/organizer';
import SimilarSection from './tabs/similar';
import VenueSection from './tabs/venue';
import { Event } from '@ceneteam/namespace';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

enum Tabs {
  ABOUT = 'About',
  LINEUP = 'Line up',
  VENUE = 'Venue',
  SIMILAR_EVENTS = 'Similar events',
}

const EventDetailsSection = () => {
  const { id: eventId } = useParams();
  const { loadEvents, eventsLiked } = useGetLikedEvents();
  const [event, setEvent] = useState<Event | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [supabaseError, setSupabaseError] = useState<string | null>('');
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.ABOUT);

  const tabs = {
    [Tabs.ABOUT]: Tabs.ABOUT,
    [Tabs.LINEUP]: Tabs.LINEUP,
    [Tabs.VENUE]: Tabs.VENUE,
    [Tabs.SIMILAR_EVENTS]: Tabs.SIMILAR_EVENTS,
  };

  useEffect(() => {
    fetchCeneEvent();
    loadEvents();
  }, [eventId]);

  const fetchCeneEvent = async () => {
    try {
      setSupabaseError(null);
      setLoading(true);

      const { data, error } = await supabaseCeneClient
        .from('events')
        .select('*')
        .eq('id', eventId)
        .single();

      if (error) throw error;

      setEvent(data as Event);
    } catch (error) {
      if (error instanceof Error) {
        setSupabaseError(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case Tabs.ABOUT:
        return (
          <div className="flex flex-col gap-[1.5rem] mt-[1.5rem] md:hidden">
            <h1
              className={classNames(
                'text-[4.5rem]/[5rem] font-light text-white',
                'max-md:text-[2.25rem]/[2.75rem]'
              )}
            >
              {event?.name}
            </h1>
            <AboutEventSection event={event} />
            <OrganizerSection event={event} />
          </div>
        );
      case Tabs.LINEUP:
        return (
          <div className="flex flex-col gap-[1.5rem] mt-[1.5rem] md:hidden">
            <LineupSection event={event} />
            <OrganizerSection event={event} />
          </div>
        );
      case Tabs.VENUE:
        return (
          <div className="flex flex-col gap-[1.5rem] mt-[1.5rem] md:hidden">
            <div>
              <p className="text-[0.875rem]/[0.875rem] text-event font-light uppercase">
                {event?.category}
              </p>
              <h1
                className={classNames(
                  'text-[4.5rem]/[5rem] font-light text-white',
                  'max-md:text-[2.25rem]/[2.75rem] mt-[1rem]'
                )}
              >
                {event?.name}
              </h1>
            </div>
            <VenueSection event={event} />
            <OrganizerSection event={event} />
          </div>
        );
      case Tabs.SIMILAR_EVENTS:
        return <SimilarSection />;
      default:
        return null;
    }
  };

  return (
    <>
      <Grid container className="max-w-[1092px] ">
        {supabaseError ? (
          <div className="flex flex-col justify-center items-center h-[558px] w-full text-center">
            <h1 className="text-lg font-light text-event text-[2rem]/[2.5rem]">
              Oops! Something went wrong.
            </h1>
            <p className="text-gray-light mt-4 max-w-[400px]">
              We couldn&apos;t load the event details at the moment. Please
              check your internet connection or try again later.
            </p>
          </div>
        ) : isLoading ? (
          <div className="flex justify-center items-center h-[558px] w-full">
            <Loader />
          </div>
        ) : (
          <div className="w-full">
            <Grid container spacing={4}>
              <Grid item xs={12} lg={5}>
                <MediaSection event={event} />
              </Grid>

              <Grid item xs={12} lg={7}>
                <div className="max-md:block hidden">
                  <ReusableTabs
                    tabs={tabs}
                    activeTab={activeTab}
                    onTabClick={(tab) => setActiveTab(tab)}
                    sessionType={true}
                    activeTabColor="!bg-event"
                    activeTextColor="text-black"
                    inactiveTabColor="bg-gray-medium"
                    inactiveTextColor="text-event"
                  />
                </div>

                {renderTabContent()}

                <div className="hidden lg:block">
                  <h1
                    className={classNames(
                      'text-[4.5rem]/[5rem] font-light text-white',
                      'max-md:text-[2.25rem]/[2.75rem]'
                    )}
                  >
                    {event?.name}
                  </h1>

                  <div className="hidden lg:block">
                    <VenueSection event={event} />
                  </div>
                  <div className="h-[1px] bg-gray-medium my-[32px]" />

                  <div className="hidden lg:block">
                    <AboutEventSection event={event} />
                  </div>
                  <div className="h-[1px] bg-gray-medium my-[32px]" />

                  <div className="hidden lg:block">
                    <LineupSection event={event} />
                  </div>
                  <div className="h-[1px] bg-gray-medium my-[32px]" />

                  <div className="hidden lg:block">
                    <OrganizerSection event={event} />
                  </div>
                </div>

                <ActionsSection event={event} eventsLiked={eventsLiked} />
              </Grid>
            </Grid>
          </div>
        )}
      </Grid>
      <SimilarSection />
    </>
  );
};

export default EventDetailsSection;
