import { Button } from '../../components/button/button';
import Toast from '../../components/toast/toast';
import { ROUTE_SETTINGS } from '../../constants/routes';
import translation from '../../constants/translations.json';
import { useSupabase } from '../../providers/supabase';
import {
  supabaseCeneClient,
  supabaseCenePlusClient,
} from '../../supabase/supabase';
import { ReactComponent as DoneSVG } from '../../svg/icon-done.svg';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  isCene: boolean;
  selectedNotifications: string[] | null;
}

interface FormValues {
  notifications: string[];
}

interface Notification {
  title: string;
  isSelected: boolean;
}

export const NotificationsForm = ({ isCene, selectedNotifications }: Props) => {
  const [isAll, setAll] = useState(true);
  const [notificationSwitch, setNotificationSwitch] = useState<Notification[]>(
    isCene ? notifications : notificationsPlus
  );
  const { session } = useSupabase();
  const navigate = useNavigate();
  const id = session?.user?.id;
  const [error, setError] = useState<string>('');
  const [isVisibleToast, setVisibleToast] = useState(false);

  const formik = useFormik<FormValues>({
    initialValues: {
      notifications: selectedNotifications || [],
    },
    onSubmit: async (values) => {
      const client = isCene ? supabaseCeneClient : supabaseCenePlusClient;

      const { error } = await client
        .from('customers')
        .update({ notifications: values.notifications })
        .eq('id', id);

      if (error) {
        setError(error.message);
        return;
      }

      setVisibleToast(true);

      setTimeout(() => {
        setVisibleToast(false);
        navigate(ROUTE_SETTINGS);
      }, 3000);
    },
  });

  useEffect(() => {
    if (selectedNotifications) {
      const updatedNotifications = notificationSwitch.map((item) => ({
        ...item,
        isSelected: selectedNotifications.includes(item.title),
      }));

      setNotificationSwitch(updatedNotifications);

      const areAllSelected = updatedNotifications.every(
        (item) => item.isSelected
      );
      setAll(areAllSelected);
    }
  }, [selectedNotifications]);

  const handleSelectAllButtonClick = () => {
    setAll(true);

    const updatedNotifications = notificationSwitch.map((item) => ({
      ...item,
      isSelected: false,
    }));

    setNotificationSwitch(updatedNotifications);
    if (isAll) {
      formik.setFieldValue('notifications', []);
    } else {
      const allNotifications = notificationSwitch.map((item) => item.title);

      formik.setFieldValue('notifications', allNotifications);
    }
  };

  const handleSelectElement = (index: number) => {
    setAll(false);

    const updatedNotifications = notificationSwitch.map(
      (item, indexElement) => {
        if (indexElement === index) {
          return { ...item, isSelected: !item.isSelected };
        }
        return item;
      }
    );

    const selectedNotifications = updatedNotifications
      .filter((item) => item.isSelected)
      .map((item) => item.title);

    formik.setFieldValue('notifications', selectedNotifications);

    const allSelected = updatedNotifications.every((item) => item.isSelected);
    setAll(allSelected);
    setNotificationSwitch(updatedNotifications);

    if (allSelected) {
      const updatedNotifications = notificationSwitch.map((item) => ({
        ...item,
        isSelected: false,
      }));
      setNotificationSwitch(updatedNotifications);
    }
  };

  return (
    <div
      className={classNames([
        'pt-[24px] pb-[20px]',
        'sm:px-0 px-5',
        'max-lg:px-0',
      ])}
    >
      {isVisibleToast && (
        <Toast
          message={
            'Your notifications preferences has been successfully updated.'
          }
          bgColor={'bg-content'}
          onClose={() => setVisibleToast(false)}
        />
      )}
      <h1 className="text-[2rem] text-white font-light mb-[12px]">
        {isCene
          ? translation.steps.notifications.title
          : translation.steps.notifications.titlePlus}
      </h1>
      <p
        className={classNames([
          'text-[0.875rem] text-generic font-light mb-[40px]',
          'max-lg:mb-[24px]',
        ])}
      >
        {isCene
          ? translation.steps.notifications.description
          : translation.steps.notifications.descriptionPlus}
      </p>
      <div>
        <div className={classNames('flex flex-wrap gap-4 w-full mx-auto')}>
          <div
            key="all"
            onClick={() => handleSelectAllButtonClick()}
            className={classNames([
              'relative cursor-pointer font-medium text-[1rem] rounded-tl-[20px] rounded-br-[20px] px-[22px] py-[12px]',
              isCene && isAll && 'bg-gray-dark text-content',
              isCene && !isAll && 'bg-gray-dark text-gray-light',

              !isCene &&
                isAll &&
                'text-black bg-generic border !border-generic',
              !isCene &&
                !isAll &&
                'text-gray-light bg-black border border-gray-medium',
            ])}
          >
            All
            {isAll && isCene && (
              <DoneSVG className="w-[20px] h-[20px] border-black border-[2px] rounded-big absolute top-[0px] right-[0px]" />
            )}
          </div>

          {notificationSwitch.map(({ title, isSelected }, index) => (
            <div
              key={index}
              onClick={() => handleSelectElement(index)}
              className={classNames([
                'relative cursor-pointer font-medium text-[1rem] rounded-tl-[20px] rounded-br-[20px] px-[22px] py-[12px] border',
                isAll &&
                  !isSelected &&
                  isCene &&
                  'bg-gray-dark text-gray-light !border-none',
                isAll &&
                  !isSelected &&
                  !isCene &&
                  'text-gray-light bg-black border border-gray-medium',
                isAll &&
                  isSelected &&
                  isCene &&
                  'bg-gray-dark text-gray-light !border-none',
                isAll &&
                  isSelected &&
                  !isCene &&
                  'text-gray-light bg-black border border-gray-medium',

                !isAll &&
                  isCene &&
                  isSelected &&
                  'bg-gray-dark text-content !border-none',
                !isAll &&
                  isCene &&
                  !isSelected &&
                  'bg-gray-dark text-gray-light !border-none',
                !isAll &&
                  !isCene &&
                  !isSelected &&
                  '!text-gray-light !bg-black border !border-gray-medium',
                !isAll &&
                  !isCene &&
                  isSelected &&
                  '!text-black !bg-generic !border-generic',
              ])}
            >
              {title}
              {!isAll && isSelected && isCene && (
                <DoneSVG className="w-[20px] h-[20px] border-black border-[2px] rounded-big absolute top-[0px] right-[0px]" />
              )}
            </div>
          ))}
        </div>
        {error && (
          <div className="text-[0.875rem] text-red font-light mt-[8px]">
            {error}
          </div>
        )}
        <Button
          color={isCene ? 'content' : 'generic'}
          onClick={() => formik.handleSubmit()}
          className={classNames(['w-full', 'mt-[34vh] sm:mt-[40px]'])}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

const notifications = [
  { title: translation.steps.notifications.events, isSelected: false },
  { title: translation.steps.notifications.ceneNews, isSelected: false },
  { title: translation.steps.notifications.newsletter, isSelected: false },
  { title: translation.steps.notifications.inapp, isSelected: false },
];

const notificationsPlus = [
  { title: translation.steps.notifications.events, isSelected: false },
  { title: translation.steps.notifications.interests, isSelected: false },
  { title: translation.steps.notifications.memberBenefits, isSelected: false },
  { title: translation.steps.notifications.cenePlusUpdates, isSelected: false },
  { title: translation.steps.notifications.newsletter, isSelected: false },
  { title: translation.steps.notifications.inapp, isSelected: false },
];
