// import { ReactComponent as CheckSvg } from '../../../src/svg/check.svg';
import { ReactComponent as DividerSvg } from '../../../src/svg/divider.svg';
import { ReactComponent as InfoSvg } from '../../../src/svg/info.svg';
import { CheckoutForm } from '../../components/modalTicketing/stripe';
// import { ReactComponent as PencilSvg } from '../../../src/svg/pencilSimple.svg';
// import { ReactComponent as TrashSvg } from '../../../src/svg/trashGeneric.svg';
// import { Button } from '../../components/button/button';
import { TermsCheckbox } from '../../components/termsCheckbox/termsCheckbox';
import translations from '../../constants/translations.json';
import { getStripe } from '../../utils/getStripe';
import {
  LocalOrderDetails,
  Ticket as OriginalTicket,
} from '@ceneteam/namespace';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';
import React, { useState } from 'react';

interface Ticket extends OriginalTicket {
  title: OriginalTicket['name'];
}

interface TicketSummaryProps {
  ticketTypes: Ticket[] | null;
  quantity: { [key: string]: number };
  totalPrice: number;
  id?: string;
  stripe?: boolean;
  formatPriceString: (totalPrice: number) => string;
  isTermsAccepted?: boolean;
  onTermsAccepted: (accepted: boolean) => void;
  tax?: LocalOrderDetails;
}

// const promoCodes = [
//   { code: 'H367K2J', discount: 10 },
//   { code: 'A1B2C3', discount: 15 },
//   { code: 'X4Y5Z6', discount: 20 },
//   { code: 'SAVE25', discount: 25 },
//   { code: 'DISCOUNT30', discount: 30 },
//   { code: 'PROMO35', discount: 35 },
// ];

export const TicketSummary: React.FC<TicketSummaryProps> = ({
  tax,
  ticketTypes,
  quantity,
  totalPrice,
  id,
  stripe,
  onTermsAccepted,
  formatPriceString,
  isTermsAccepted,
}) => {
  const FeeItem = ({ label, amount }: { label: string; amount?: string }) => (
    <div className="flex items-center mb-[14px]">
      <div className="flex flex-col">
        <div className="flex items-center mt-[4px]">
          <p className="text-white text-[0.75rem] leading-[18px] font-medium">
            {label}
          </p>
        </div>
      </div>
      <p className="text-white text-[1.3125rem] ml-auto leading-[21px] font-light">
        {`₦ ${formatPriceString(Number(amount))}`}
      </p>
    </div>
  );

  const fees = [
    {
      label: translations.ticketSummary.service,
      amount: tax?.service_fee.deduction_amount || 0,
    },
    {
      label: translations.ticketSummary.order,
      amount: tax?.local_processing_fee.deduction_amount || 0,
    },
    {
      label: translations.ticketSummary.tax,
      amount: tax?.tax.deduction_amount || 0,
    },
  ];
  // const [promoCode, setPromoCode] = useState('');
  // const [isPromoValid, setIsPromoValid] = useState(false);
  // const [discount, setDiscount] = useState(0);
  // setIsPromoApplied

  const [isPromoApplied] = useState(false);
  const stripePromise = getStripe();

  const options: StripeElementsOptions = {
    mode: 'payment',
    amount: totalPrice, // your calculated price
    currency: 'usd', // or the currency you are using
    paymentMethodTypes: ['card'], // Ensure this matches the type you're collecting
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: '#3498db', // Primary button color, can be customized
        colorBackground: '#000', // Black background
        colorText: '#fff', // White text
        colorDanger: '#e74c3c', // Danger color (for error messages)
      },
    },
  };
  // const [errorMessage, setErrorMessage] = useState('');

  // const getDiscountedPrice = (price: number) => price * (1 - discount / 100);

  // const handlePromoCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setPromoCode(e.target.value);
  //   setIsPromoValid(false);
  //   setIsPromoApplied(false);
  //   setErrorMessage('');
  // };

  // const applyPromoCode = () => {
  //   const promo = promoCodes.find((p) => p.code === promoCode);
  //   if (promo) {
  //     setDiscount(promo.discount);
  //     setIsPromoValid(true);
  //     setIsPromoApplied(true);
  //     setErrorMessage('');
  //   } else {
  //     setDiscount(0);
  //     setIsPromoValid(false);
  //     setIsPromoApplied(false);
  //     setErrorMessage(translations.ticketSummary.invalidPromoCode);
  //   }
  // };

  // const displayedTotalPrice = isPromoApplied
  //   ? getDiscountedPrice(totalPrice)
  //   : totalPrice;

  // Define border classes based on promo code validation
  // const borderClass = isPromoValid
  //   ? isPromoApplied
  //     ? 'border-event'
  //     : 'border-gray-medium'
  //   : errorMessage
  //     ? 'border-red'
  //     : 'border-gray-medium';

  return (
    <div className="lg:mt-[24px] mt-[39px] flex flex-col ">
      {stripe ? (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm
            totalPrice={totalPrice}
            tickets={ticketTypes}
            quantity={quantity}
            id={id || ''}
          />
        </Elements>
      ) : (
        <>
          <div className="flex items-center justify-between mb-[16px]">
            <p className="text-white text-[0.9375rem] leading-[20px] text-center">
              {translations.ticketSummary.basket}
            </p>
            {/* <button className="w-[40px] h-[40px] border border-gray-medium rounded-tl-[12px] rounded-br-[12px] flex justify-center items-center">
          <PencilSvg />
        </button> */}
          </div>
          <div className="flex flex-col ">
            <div className="w-full">
              {ticketTypes?.map((ticket, index) =>
                quantity[index] > 0 ? (
                  <div key={index} className="flex items-center mb-[16px]">
                    <div className="flex flex-col">
                      <p className="lg:text-[1.0625rem] lg:leading-[22px] text-white font-light text-[1rem] leading-[21px]">
                        {quantity[index]} x {translations.ticketSummary.access}
                      </p>
                      <div className="flex items-center mt-[15px]">
                        <InfoSvg className="mr-[10px] w-[20px] h-[20px]" />
                        <p className="text-generic text-[0.75rem] leading-[18px] font-medium">
                          {ticket.name}
                          {quantity[index] > 1 ? 's' : ''}
                        </p>
                      </div>
                    </div>
                    <div className="ml-auto flex flex-col items-end">
                      <p className="text-white lg:text-[1.3125rem] font-light text-[1.1875rem] leading-[19px]">
                        {`₦ ${formatPriceString(ticket.NGN ?? 0)}`}
                      </p>
                      {/* <button className="w-[32px] h-[32px] border border-gray-medium rounded-tl-[6px] rounded-br-[6px] flex justify-center items-center mt-[8px]">
                    <TrashSvg />
                  </button> */}
                    </div>
                  </div>
                ) : null
              )}
            </div>
            <div>
              {/* <p className="text-generic text-[0.875rem] leading-[14px] mb-[8px] font-light">
            {translations.ticketSummary.promo}
          </p>
          <div className="relative flex flex-row">
            <input
              type="text"
              value={promoCode}
              onChange={handlePromoCodeChange}
              className={`bg-gray-dark w-full font-light text-white border ${borderClass} rounded-tl-[16px] rounded-br-[16px] placeholder:text-gray-light px-[24px] py-[12px]`}
              placeholder="H367K2J"
            />
            {isPromoApplied && isPromoValid && (
              <CheckSvg className="absolute right-[100px] top-[50%] transform -translate-y-[50%] w-[20px] h-[20px] text-gray-light" />
            )}
            <Button
              className={`w-[73px] h-[48px] ml-[16px] ${
                isPromoValid ? 'bg-event' : 'bg-gray-light'
              }`}
              onClick={applyPromoCode}
            >
              {translations.ticketSummary.apply}
            </Button>
          </div> */}
              {/* {errorMessage && (
            <p className="text-red text-[0.875rem] mt-[8px]">{errorMessage}</p>
          )} */}
              {/* {isPromoApplied && isPromoValid && (
            <div className="flex items-center mt-[8px]">
              <p className="text-event text-[0.875rem] leading-[14px]">
                {`${translations.ticketSummary.promoTextFirst} ${discount}% ${translations.ticketSummary.promoTextSecond}`}
              </p>
            </div>
          )} */}
              <DividerSvg className="lg:my-[24px] my-[32px] w-full" />
              <div>
                <p className="text-white text-[0.9375rem] leading-[20px] font-normal mb-[28px]">
                  {translations.ticketSummary.fees}
                </p>
                {fees.map((fee, index) => (
                  <FeeItem
                    key={index}
                    label={fee.label}
                    amount={fee.amount ? String(fee.amount) : ''}
                  />
                ))}
                <p className="lg:text-[1.0625rem] text-white lg:mt-[24px] mt-[32px] lg:leading-[22px] font-light leading-[19px] text-[1.1875rem]">
                  {translations.ticketSummary.summary}
                </p>

                <div className="flex justify-between mt-[14.5px]">
                  <p className="text-gray-light text-[0.875rem] leading-[14px]">
                    {translations.ticketSummary.amount}
                  </p>
                  <p className="text-white lg:text-[1.3125rem] lg:leading-[21px] text-[1.1875rem] leading-[19px]">{`₦ ${formatPriceString(tax?.total_net_amount as number)}`}</p>
                </div>

                {isPromoApplied && (
                  <div className="flex justify-between mt-[14.5px]">
                    <p className="text-gray-light text-[0.875rem] leading-[14px]">
                      {translations.ticketSummary.discount}
                    </p>
                    <p className="text-white lg:text-[1.3125rem] lg:leading-[21px] text-[1.1875rem] leading-[19px]">
                      {`₦ ${formatPriceString(tax?.total_gross_amount as number)}`}
                    </p>
                  </div>
                )}

                {!isPromoApplied && (
                  <div className="flex justify-between mt-[0.75rem]">
                    <p className="text-gray-light text-[0.875rem] leading-[14px]">
                      {translations.ticketSummary.discount}
                    </p>
                    <p className="text-white lg:text-[1.3125rem] lg:leading-[21px] text-[1.1875rem] leading-[19px]">
                      ₦ 0
                    </p>
                  </div>
                )}

                <div className="flex items-center justify-between lg:mt-[12px] mt-[16px]">
                  <p className="text-white text-[1rem] leading-[24px] font-medium">
                    {translations.ticketSummary.total}
                  </p>
                  <p className="text-white text-[2rem] leading-[40px]">{`₦ ${formatPriceString(Number(tax?.total_gross_amount))}`}</p>
                </div>
                <TermsCheckbox
                  isChecked={isTermsAccepted ?? false}
                  onChange={(checked) => {
                    onTermsAccepted(checked);
                  }}
                  label={translations.ticketSummary.terms}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
