import { Buffer } from 'buffer';

/**
 * Convert base64 to File
 * @param dataUrl string
 * @param filename string
 * @returns File
 */
export const ataUrlToFile = (dataUrl: string, filename: string) => {
  const arr = dataUrl.split(',');
  if (arr.length < 2) {
    return undefined;
  }
  const mimeArr = arr[0].match(/:(.*?);/);
  if (!mimeArr || mimeArr.length < 2) {
    return undefined;
  }
  const mime = mimeArr[1];
  const buff = Buffer.from(arr[1], 'base64');
  return new File([buff], filename, { type: mime });
};
