import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';
import { ReactComponent as BellCenePlusSVG } from '../../svg/bell-icon-plus.svg';
import { ReactComponent as BellCeneSVG } from '../../svg/bell-icon.svg';
import { ReactComponent as CloseSVG } from '../../svg/close-icon.svg';
import { Icon } from '../icon/icon';
import NotificationBlock from '../notificationBlock/notificationBlock';
import { NotificationsFilter } from '../notificationsFilter/notificationsFilter';
import classNames from 'classnames';
import { differenceInDays, isToday, isYesterday } from 'date-fns';
import { useState } from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  isCene: boolean;
}

interface Notification {
  tooltype: string;
  title: string;
  date: string;
  picture: string;
}

const mockNotifications = [
  {
    tooltype: 'Events',
    title:
      'Tickets for VOL 5 concert are now available! Buy now at the best price',
    date: '21.12.2024 23:29',
    picture: 'https://picsum.photos/200',
  },
  {
    tooltype: 'Newsletter',
    title:
      'Tickets for VOL 6 concert are now available! Buy now at the best price',
    date: '23.12.2024 1:25',
    picture: 'https://picsum.photos/200',
  },
  {
    tooltype: 'In-app',
    title:
      'Tickets for VOL 9 concert are now available! Buy now at the best price',
    date: '19.12.2024 23:29',
    picture: 'https://picsum.photos/200',
  },
  {
    tooltype: 'Cene News',
    title:
      'Tickets for VOL 4 concert are now available! Buy now at the best price',
    date: '19.12.2024 23:29',
    picture: 'https://picsum.photos/200',
  },
  {
    tooltype: 'Newsletter',
    title:
      'Tickets for VOL 2 concert are now available! Buy now at the best price',
    date: '19.12.2024 23:29',
    picture: 'https://picsum.photos/200',
  },
  {
    tooltype: 'Events',
    title:
      'Tickets for VOL 7 concert are now available! Buy now at the best price',
    date: '14.12.2024 23:29',
    picture: 'https://picsum.photos/200',
  },
  {
    tooltype: 'Newsletter',
    title:
      'Tickets for VOL 10 concert are now available! Buy now at the best price',
    date: '22.12.2024 23:29',
    picture: 'https://picsum.photos/200',
  },
  {
    tooltype: 'Cene News',
    title:
      'Tickets for VOL 1 concert are now available! Buy now at the best price',
    date: '19.12.2024 23:29',
    picture: 'https://picsum.photos/200',
  },
  {
    tooltype: 'In-app',
    title:
      'Tickets for VOL 8 concert are now available! Buy now at the best price',
    date: '21.12.2024 23:29',
    picture: 'https://picsum.photos/200',
  },
  {
    tooltype: 'Events',
    title:
      'Tickets for VOL 3 concert are now available! Buy now at the best price',
    date: '14.12.2024 23:29',
    picture: 'https://picsum.photos/200',
  },
];

const groupNotificationsByDate = (notifications: Notification[]) => {
  const today = new Date();

  const groups: {
    Today: Notification[];
    Yesterday: Notification[];
    'Last 7 days': Notification[];
    'Last 30 days': Notification[];
  } = {
    Today: [],
    Yesterday: [],
    'Last 7 days': [],
    'Last 30 days': [],
  };

  notifications.forEach((notification: Notification) => {
    const notificationDate = new Date(
      notification.date.split(' ')[0].split('.').reverse().join('-')
    );

    if (isToday(notificationDate)) {
      groups.Today.push(notification);
    } else if (isYesterday(notificationDate)) {
      groups.Yesterday.push(notification);
    } else if (differenceInDays(today, notificationDate) <= 7) {
      groups['Last 7 days'].push(notification);
    } else if (differenceInDays(today, notificationDate) <= 30) {
      groups['Last 30 days'].push(notification);
    }
  });

  return groups;
};

const NotificationModal = ({ isCene, isOpen, onClose }: ModalProps) => {
  const [activeTooltype, setActiveTooltype] = useState<string[]>(['All']);

  useDisableBodyScroll(isOpen);

  const handleClose = () => {
    setActiveTooltype(['All']);
    onClose();
  };

  if (!isOpen) return null;

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const groupedNotifications = groupNotificationsByDate(
    activeTooltype.includes('All')
      ? mockNotifications
      : mockNotifications.filter((notif) =>
          activeTooltype.includes(notif.tooltype)
        )
  );

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-[16px] flex justify-center items-center z-[100]"
      onClick={handleOverlayClick}
    >
      <div className="absolute right-[32px] top-[32px] max-lg:hidden">
        <Icon onClick={handleClose}>
          <CloseSVG style={{ stroke: isCene ? '#91dbbf' : '#b8cad9' }} />
        </Icon>
      </div>
      <div
        className={classNames(
          'bg-black lg:w-[532px] w-full relative lg:rounded-tl-[30px] lg:rounded-br-[30px] border border-gray-medium  h-full overflow-y-auto p-[2.5rem] max-lg:p-[1.25rem]',
          mockNotifications.length > 1 ? 'lg:h-[682px]' : 'lg:h-fit'
        )}
        onClick={(e) => e.stopPropagation()}
      >
        {mockNotifications.length < 1 ? (
          <div
            className={classNames(
              'flex flex-col items-center gap-[1rem] text-center'
            )}
          >
            {isCene ? <BellCeneSVG /> : <BellCenePlusSVG />}
            <h2
              className={classNames(
                'text-[2rem]/[2.5rem] font-light',
                isCene ? 'text-event' : 'text-generic'
              )}
            >
              No notification yet
            </h2>
            <p
              className={classNames(
                'text-[1rem]/[1.3125rem] font-light text-gray-light'
              )}
            >
              Your notifications will appear here as soon as you receive
              something new
            </p>
          </div>
        ) : (
          <>
            <div>
              <div className="flex justify-center items-center w-full relative">
                <h1
                  className={classNames(
                    'text-[1.3125rem]/[1.3125rem] font-light',
                    isCene ? 'text-event' : 'text-generic'
                  )}
                >
                  Notifications
                </h1>
                <div className="absolute -right-2 -top-2 lg:hidden">
                  <Icon onClick={handleClose}>
                    <CloseSVG
                      style={{ stroke: isCene ? '#91dbbf' : '#b8cad9' }}
                    />
                  </Icon>
                </div>
              </div>
              <NotificationsFilter
                isCene={isCene}
                onFilterChange={setActiveTooltype}
              />
            </div>
            <div>
              {Object.entries(groupedNotifications).map(
                ([section, notifications], index, array) => (
                  <div key={section}>
                    {notifications.length > 0 && (
                      <>
                        <div className="border-b border-gray-500 py-2">
                          <p className="text-[1.3125rem]/[1.3125rem] text-white font-light">
                            {section}
                          </p>
                        </div>
                        {notifications.map(
                          ({ tooltype, title, picture, date }) => (
                            <NotificationBlock
                              key={title + date}
                              isCene={isCene}
                              tooltype={tooltype}
                              title={title}
                              picture={picture}
                              date={date}
                            />
                          )
                        )}
                        {index < array.length - 1 && (
                          <div className="border-t border-generic-light my-4"></div>
                        )}
                      </>
                    )}
                  </div>
                )
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NotificationModal;
