import translations from '../../constants/translations.json';
import { formatEventDate } from '../../helpers/formatDate';
import { formatTicketDate } from '../../helpers/formatDate';
import { supabaseCeneClient } from '../../supabase/supabase';
import { ReactComponent as MapPinSVG } from '../../svg/MapPinLineGeneric.svg';
import { ReactComponent as CalendarSVG } from '../../svg/calendarEvents.svg';
import { Event } from './aboutEvent';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

interface Ticket {
  eventData: Event;
  sessionType?: boolean;
}
interface Tax {
  local_processing_fee: {
    value: number;
    strategy?: string;
    deduction_amount?: number;
  };
  tax: {
    value: number;
  };
}

interface Props {
  eventData: Event;
  title: string;
  value: string | string[] | undefined;
  sessionType?: boolean;
}

const ListItem = ({ value, sessionType, title }: Props) => {
  return (
    <div className="flex flex-col gap-[0.5rem] pb-[1rem] border-b border-b-generic-light mt-[2rem] max-lg:mt-[1.5rem]">
      <h4
        className={classNames(
          'uppercase text-[1.0625rem]/[1.375rem] text-gray-light font-light',
          'text-[0.9375rem]/[1.25rem] font-normal'
        )}
      >
        {title}
      </h4>
      <p
        className={classNames(
          'text-[1.0625rem]/[1.375rem] font-light flex flex-col gap-[0.75rem]',
          'text-[1rem]/[1.5rem] font-medium',
          sessionType ? 'text-white' : 'text-generic'
        )}
      >
        {typeof value === 'string'
          ? value
          : value?.map((item, index) => <span key={index}>{item}</span>)}
      </p>
    </div>
  );
};

const ReceiptSection = ({ sessionType, eventData }: Ticket) => {
  const [tax, setTax] = useState<Tax | null>(null);
  const selectedCurrency = 'NGN';
  const getTicketCounts = () => {
    if (!eventData?.tickets) return [];
    const counts = eventData?.tickets
      .map((ticket) => ticket?.title)
      .reduce<Record<string, number>>((acc, title) => {
        acc[title] = (acc[title] || 0) + 1;
        return acc;
      }, {});

    return Object.entries(counts).map(
      ([title, count]) => `${title} × ${count}`
    );
  };

  const purchasedDate =
    eventData?.tickets?.[0]?.purchased_at || 'Not Available';
  const formattedTicketDate = formatTicketDate(purchasedDate || '');
  const formatPriceString = (totalPrice: number) => {
    return new Intl.NumberFormat('en-US').format(totalPrice);
  };
  const ticketCounts = getTicketCounts();
  const formattedDate = formatEventDate(eventData?.starts_at || '');
  const getTax = async () => {
    const ticketDetails =
      eventData?.tickets?.map((ticket) => ({
        quantity: ticket.quantity,
        ticket_name_id: ticket.id,
      })) || [];

    try {
      const { data } = await supabaseCeneClient.rpc(
        'build_event_order_finance_details_v2',
        {
          currency: selectedCurrency,
          event_id: eventData.id,
          ordered_tickets: ticketDetails,
        }
      );
      setTax(data);
    } catch (error) {
      console.error('Error fetching tax:', error);
    }
  };

  useEffect(() => {
    if (eventData?.tickets?.length) {
      getTax();
    }
  }, [eventData]);

  const calculatePrice = () => {
    if (!tax || !eventData?.tickets?.length || ticketCounts.length === 0) {
      console.log('Invalid input data', { tax, eventData, ticketCounts });
      return 0;
    }

    return eventData.tickets.reduce((total, ticket) => {
      const price = ticket.price[selectedCurrency] || 0;
      const taxValue = tax?.tax?.value || 0;
      const taxFee = tax?.local_processing_fee?.value || 0;

      if (price <= 0 || taxValue < 0 || taxFee < 0) {
        console.log('Invalid values detected:', { price, taxValue, taxFee });
        return total;
      }

      const priceAfterTax = price * (1 + taxValue / 100);
      const finalPrice = priceAfterTax + taxFee;

      const totalPriceForTickets = finalPrice * ticketCounts.length;

      return total + totalPriceForTickets;
    }, 0);
  };

  const totalPrice = calculatePrice();

  return (
    <>
      <div
        className={classNames(
          'bg-generic-06 border border-generic-light lg:p-[2.5rem] rounded-tl-[30px] rounded-br-[30px]',
          'max-lg:p-[1.25rem]'
        )}
      >
        <p
          className={classNames(
            'text-[1rem]/[1.3125rem] text-gray-light font-light',
            'max-lg:text-[0.75rem]/[1.125rem] max-lg:font-normal'
          )}
        >
          {translations.ticketDetails.receiptDescription}
        </p>
        <h3
          className={classNames(
            'text-[2rem]/[2.5rem] text-white font-light mt-[0.75rem]',
            'max-lg:text-[1.3125rem]/[1.3125rem] max-lg:mt-[1rem]'
          )}
        >
          {eventData?.name}
        </h3>
        <div
          className={classNames(
            'text-[1.0625rem]/[1.375rem]  font-light flex flex-col gap-[1rem] mt-[2rem]',
            sessionType ? 'text-white' : 'text-generic'
          )}
        >
          <div className="flex gap-[0.75rem]">
            <MapPinSVG
              style={{
                minWidth: '24px',
                minHeight: '24px',
              }}
            />
            <span>{eventData?.address}</span>
          </div>
          <div className="flex gap-[0.75rem]">
            <CalendarSVG
              style={{
                minWidth: '24px',
                minHeight: '24px',
              }}
            />
            <span>{formattedDate}</span>
          </div>
        </div>

        <ListItem
          sessionType={sessionType}
          eventData={eventData}
          title={translations.ticketDetails.purchaseDate}
          value={formattedTicketDate}
        />

        {/* <ListItem
          sessionType={sessionType}
          eventData={eventData}
          title={translations.ticketDetails.orderNumber}
          value="012908075653ue29423"
        /> */}
        <ListItem
          sessionType={sessionType}
          eventData={eventData}
          title={translations.ticketDetails.ticketType}
          value={ticketCounts}
        />
        <ListItem
          eventData={eventData}
          sessionType={sessionType}
          title={translations.ticketDetails.organizer}
          value={eventData?.partner_info?.name}
        />
        {/* <ListItem
         eventData={eventData}
          sessionType={sessionType}
          title={translations.ticketDetails.entryInfo}
          value="GATE 1-2"
        /> */}

        <div className="flex flex-col gap-[0.5rem] pb-[1rem] mt-[2rem] max-lg:mt-[1.5rem]">
          <h4
            className={classNames(
              'uppercase text-[1.0625rem]/[1.375rem] text-gray-light font-light',
              'text-[0.9375rem]/[1.25rem] font-normal'
            )}
          >
            {translations.ticketDetails.ticketPrice}
          </h4>
          <p
            className={classNames(
              'text-[1.0625rem]/[1.375rem] font-light flex flex-col gap-[0.75rem]',
              'text-[1rem]/[1.5rem] font-medium',
              sessionType ? 'text-white' : 'text-generic'
            )}
          >
            ₦ {formatPriceString(totalPrice as number)}
          </p>
        </div>
      </div>
    </>
  );
};

export default ReceiptSection;
