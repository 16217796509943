import { ReactComponent as ClockSVG } from '../../../../../src/svg/Clock.svg';
import { ReactComponent as LockSVG } from '../../../../../src/svg/lock.svg';
import { ReactComponent as TicketSVG } from '../../../../../src/svg/ticket.svg';
import { Icon } from '../../../../components/icon/icon';
import { MediaDisplay } from '../../../../components/mediaDisplay/mediaDisplay';
import { ReactComponent as LeftArrowSVG } from '../../../../svg/caretLeft.svg';
import { ReactComponent as RightArrowSVG } from '../../../../svg/caretRight.svg';
import { Event } from '@ceneteam/namespace';
import classNames from 'classnames';
import { differenceInHours } from 'date-fns';
import { useEffect, useState } from 'react';

interface Props {
  event: Event | null;
}

const getOrderedMedia = (event: Event | null) => {
  if (!event) return [];
  const featuredImage = event.featured_image
    ? [{ type: 'image', src: event.featured_image.url }]
    : [];

  const otherImages = event.images
    .filter((img) => img.url !== event.featured_image?.url)
    .map((img) => ({ type: 'image', src: img.url }));

  const video = event.video_url
    ? [{ type: 'video', src: event.video_url }]
    : [];

  return [...featuredImage, ...otherImages, ...video];
};

export enum StatusSales {
  ON_THE_WAY = 'On the way',
  SELLING_NOW = 'Selling now',
  SOLD_OUT = 'Sold out',
}

// export const getEventStatus = (event: Event | null): StatusSales => {
//   if (!event || !event.tickets || event.tickets.length === 0) {
//     return StatusSales.ON_THE_WAY;
//   }

//   const now = new Date();

//   const allTicketsSoldOut = event.tickets.every(
//     (ticket) => ticket.quantity === 0
//   );

//   const anyTicketSellingNow = event.tickets.some((ticket) => {
//     if (
//       !ticket.sale_starts_at ||
//       !ticket.sale_ends_at ||
//       ticket.quantity === 0
//     ) {
//       return false;
//     }

//     const saleStart = new Date(ticket.sale_starts_at);
//     const saleEnd = new Date(ticket.sale_ends_at);

//     return now >= saleStart && now <= saleEnd;
//   });

//   if (allTicketsSoldOut) {
//     return StatusSales.SOLD_OUT;
//   }

//   if (event.starts_at) {
//     const eventStart = new Date(event.starts_at);
//     if (differenceInHours(eventStart, now) <= 10) {
//       return StatusSales.SOLD_OUT;
//     }
//   }

//   if (anyTicketSellingNow) {
//     return StatusSales.SELLING_NOW;
//   }

//   return StatusSales.ON_THE_WAY;
// };

export const getEventStatus = (event: Event | null): StatusSales => {
  if (!event || !event.tickets || event.tickets.length === 0) {
    return StatusSales.ON_THE_WAY;
  }

  const now = new Date();

  const allTicketsSoldOut =
    event.stock && Object.values(event.stock).every((stock) => stock === 0);

  const anyTicketSellingNow = event.tickets.some((ticket) => {
    if (
      !ticket.sale_starts_at ||
      !ticket.sale_ends_at ||
      ticket.quantity === 0
    ) {
      return false;
    }

    const saleStart = new Date(ticket.sale_starts_at);
    const saleEnd = new Date(ticket.sale_ends_at);

    return now >= saleStart && now <= saleEnd;
  });

  if (allTicketsSoldOut) {
    return StatusSales.SOLD_OUT;
  }

  if (event.starts_at) {
    const eventStart = new Date(event.starts_at);

    if (now < eventStart) {
      return StatusSales.ON_THE_WAY;
    }

    if (differenceInHours(eventStart, now) <= 2) {
      if (!anyTicketSellingNow && allTicketsSoldOut) {
        return StatusSales.SOLD_OUT;
      }
      return StatusSales.SELLING_NOW;
    }
  }

  if (anyTicketSellingNow) {
    return StatusSales.SELLING_NOW;
  }

  return StatusSales.ON_THE_WAY;
};

const MediaSection = ({ event }: Props) => {
  const [status, setStatus] = useState('');
  const [media, setMedia] = useState<{ type: string; src: string }[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setStatus(getEventStatus(event));
    setMedia(getOrderedMedia(event));
    setCurrentIndex(0);
  }, [event?.id]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 >= media.length ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - 1 < 0 ? media.length - 1 : prevIndex - 1
    );
  };

  const isVideo = media[currentIndex]?.type === 'video';

  return (
    <div
      className={classNames(
        'relative lg:w-[422px] h-[544px] rounded-tl-[30px] rounded-br-[30px]',
        'max-md:w-full max-md:h-[336px] overflow-hidden'
      )}
    >
      {media.length > 0 ? (
        <MediaDisplay
          imageSrc={media}
          currentIndex={currentIndex}
          isVideo={isVideo}
        />
      ) : (
        <div className="flex items-center justify-center h-full">
          <span>No Media Available</span>
        </div>
      )}
      <div
        className={classNames(
          'absolute top-[1.5rem] md:left-[1.5rem]',
          'max-md:right-[1rem] max-md:top-[1rem]'
        )}
      >
        {status === StatusSales.SELLING_NOW && (
          <div className="text-black px-[15px] py-[16px] rounded-tl-[16px] rounded-br-[16px] bg-ticket flex items-center text-[0.625rem] leading-[10px]">
            <TicketSVG className="mr-[8px] w-[12px] h-[12px]" />
            <span className="text-green-500">Selling Now</span>
          </div>
        )}
        {status === StatusSales.SOLD_OUT && (
          <div className="text-white px-[15px] py-[16px] rounded-tl-[16px] rounded-br-[16px] bg-gray-medium flex items-center text-[0.625rem] leading-[10px]">
            <LockSVG className="mr-[8px] w-[12px] h-[12px]" stroke="white" />
            <span className="text-red-500">Sold Out</span>
          </div>
        )}
        {status === StatusSales.ON_THE_WAY && (
          <div className="text-black px-[15px] py-[16px] rounded-tl-[16px] rounded-br-[16px] bg-content flex items-center text-[0.625rem] leading-[10px]">
            <ClockSVG className="mr-[8px] w-[12px] h-[12px]" />
            <span className="text-yellow-500">Coming Soon</span>
          </div>
        )}
      </div>
      <div className="bg-gradient absolute w-full h-full top-0" />
      {media.length > 1 && (
        <div className="absolute bottom-[-15px] left-0 right-0 flex justify-between px-[14px] py-[14px] text-event">
          <div className="flex items-center">
            <Icon
              className="bg-gray-dark rounded-tl-[8px] rounded-br-[8px] mr-[8px] w-[40px] h-[40px] flex items-center justify-center"
              onClick={handlePrev}
            >
              <LeftArrowSVG
                style={{ stroke: '#91DBBF', width: '12px', height: '12px' }}
              />
            </Icon>
            <button
              className="bg-gray-dark rounded-tl-[8px] rounded-br-[8px] w-[40px] h-[40px] flex items-center justify-center"
              onClick={handleNext}
            >
              <RightArrowSVG
                style={{ stroke: '#91DBBF', width: '12px', height: '12px' }}
              />
            </button>
          </div>
          <div className="flex items-baseline">
            <span className="text-[1rem]/[1.5rem] text-white">
              {currentIndex + 1}
            </span>
            <span className="text-[0.625rem]/[0.625rem] text-gray-light">
              /{media.length}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default MediaSection;
