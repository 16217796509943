import { JustSection } from '../../components/collapsibleSection/justSection';
import translations from '../../constants/translations.json';
import { BurstOntoDesc } from '../../sections/burstOnto/burstOntoDesc';
import { BurstTestimonial } from '../../sections/burstOnto/burstTestimonial';
import { EventToolkit } from '../../sections/burstOnto/eventToolkit';
import { FeaturesBurst } from '../../sections/burstOnto/featuresBurst';
import { MakeTicketSection } from '../../sections/burstOnto/personalized';
import classNames from 'classnames';

interface SectionData {
  title: string;
  description: string;
}
const sections: SectionData[] = translations.sectionsJust;

export const BurstOntoPage = () => {
  return (
    <div
      className={classNames([
        ' w-full px-0',
        'max-lg:flex-col-reverse max-lg:px-0 max-lg:mt-0 max-lg:gap-0',
      ])}
    >
      <BurstOntoDesc />
      <BurstTestimonial />
      <FeaturesBurst />
      <EventToolkit />

      <MakeTicketSection />

      <div
        className={classNames([
          'flex flex-col items-center justify-center ',
          'lg:my-[120px] mx-0 my-[72px]',
        ])}
      >
        <h1 className="lg:text-[4rem] font-light text-white text-center lg:leading-[72px] whitespace-pre-line text-[2rem] leading-[40px]">
          {translations.questions.title}
        </h1>
        <h3 className="lg:text-[1.3125rem] font-light text-white text-center lg:mb-[64px] mb-[56px] lg:leading-[21px] mt-[24px] leading-[19px] text-[1.1875rem]">
          {translations.questions.description}
        </h3>
        {sections.map((section, index) => (
          <JustSection
            key={index}
            title={section.title}
            description={section.description}
            isLast={index === sections.length - 1}
          />
        ))}
      </div>
    </div>
  );
};
