import translations from '../../constants/translations.json';
import { formatEventDate } from '../../helpers/formatDate';
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';
import { usePayment } from '../../hooks/useSendPayment';
import { Ticket as SelectedTicket } from '../../pages/myBasket/myBasket';
import { useCurrency } from '../../providers/currency';
import { useSupabase } from '../../providers/supabase';
import { HeaderPayment } from '../../sections/modalTicketing/headerPayment';
import { ModalHeader } from '../../sections/modalTicketing/modalHeader';
import { PaymentErrorMessage } from '../../sections/modalTicketing/paymentErrorMessage';
import { PaymentSuccessMessage } from '../../sections/modalTicketing/paymentSuccessMessage';
import { supabaseCeneClient } from '../../supabase/supabase';
import { LoadingPaymentPage } from '../loadingPayment/loadingPayment';
import { TicketDetails } from './ticketDetails';
import {
  Event,
  LocalOrderDetails,
  Ticket as OriginalTicket,
} from '@ceneteam/namespace';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { usePaystackPayment } from 'react-paystack';
import { useLocation, useParams } from 'react-router-dom';

interface Ticket extends OriginalTicket {
  title: OriginalTicket['name'];
}

interface ModalProps {
  isOpen: boolean;
  event: Event | null;
  maxTotalQuantity?: number | undefined;
  isPurchaseDisabled?: boolean;
  onClose: () => void;
}

const formatPriceString = (totalPrice: number) => {
  return new Intl.NumberFormat('en-US').format(totalPrice);
};

export const ModalTicketing: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  event,
  isPurchaseDisabled,
  maxTotalQuantity,
}) => {
  useDisableBodyScroll(isOpen);
  const { session } = useSupabase();
  const { selectedCurrency } = useCurrency();
  const [quantity, setQuantity] = useState<{ [key: string]: number }>({});

  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isPayNowClicked] = useState(false);
  const [isPaymentError, setIsPaymentError] = useState(false);
  const [tax, setTax] = useState<LocalOrderDetails>();
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);

  const [showLoadingPage] = useState(false);
  const location = useLocation();

  const { selectedTickets, transaction, selectedTax } = location.state || {};
  const [isCheckoutClicked, setIsCheckoutClicked] = useState(
    false || selectedTickets
  );
  const sessionType = session?.type === 'cene';
  const { id } = useParams();
  const publicKey = process.env.REACT_APP_PAY_STACK_KEY || '';
  const initializePayment = usePaystackPayment({ publicKey });
  const [tickets] = useState<Ticket[]>(
    event?.tickets?.map((ticket) => ({
      ...ticket,
      title: ticket.name,
      is_visible: ticket.is_visible,
    })) || []
  );

  const formattedDate = formatEventDate(event?.starts_at as string);

  const prepareTicketsForSummary = () => {
    if (selectedTickets) {
      return selectedTickets.map((selectedTicket: SelectedTicket) => {
        const matchedTicket = tickets?.find(
          (eventTicket) => eventTicket.id === selectedTicket.ticket_name_id
        );
        if (matchedTicket) {
          return {
            ...selectedTicket,
            ...matchedTicket,
            quantity: selectedTicket.quantity || 0,
          };
        }
        return selectedTicket;
      });
    }
    return tickets;
  };

  useEffect(() => {
    if (Object.keys(quantity).length > 0) {
      const selectedTickets = getSelectedTickets();
      getTax(selectedTickets);
    }
  }, [quantity]);

  const combinedTickets = prepareTicketsForSummary();
  const ticketQuantities = combinedTickets?.map(
    (ticket: Ticket) => ticket.quantity || 0
  );

  const getTotalPrice = () =>
    tickets.reduce((total, ticket) => {
      const ticketQuantity = quantity[ticket.id] || 0;
      return total + (ticket?.NGN || 0) * ticketQuantity;
    }, 0);

  const totalPrice = React.useMemo(() => getTotalPrice(), [quantity, tickets]);

  const { onSendPayment } = usePayment({
    totalPrice: selectedTickets
      ? selectedTax?.total_gross_amount
      : tax?.total_gross_amount,
    tickets: selectedTickets ? combinedTickets : tickets,
    quantity: selectedTickets ? ticketQuantities : quantity,
    id: id || '',
    initializePayment,
    setIsPaymentSuccessful,
    setIsPaymentError,
    transaction,
  });

  if (!isOpen) return null;

  const paymentProvider = selectedCurrency === 'NGN' ? 'paystack' : 'stripe';

  const handleBackClick = () => {
    if (isCheckoutClicked) {
      setIsCheckoutClicked(false);
    } else {
      onClose();
    }
  };
  const handleCloseClick = () => {
    onClose();
  };

  useEffect(() => {
    if (tickets) {
      const initialQuantity = tickets.reduce(
        (acc, _, index) => {
          acc[index] = 0;
          return acc;
        },
        {} as { [key: string]: number }
      );

      setQuantity(initialQuantity);
    }
  }, [tickets]);

  const getTax = async (
    tickets: Ticket[] | { id: string; title: string; quantity: number }[]
  ) => {
    const ticketDetails = tickets.map((ticket) => ({
      quantity: ticket.quantity,
      ticket_name_id: ticket.id,
    }));

    console.log(ticketDetails, 'ticketDetails');

    try {
      const { data } = await supabaseCeneClient.rpc(
        'build_event_order_finance_details_v2',
        {
          currency: selectedCurrency,
          event_id: event?.id,
          ordered_tickets: ticketDetails,
        }
      );
      setTax(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getTotalQuantity = () =>
    Object.values(quantity).reduce((sum: number, q: number) => sum + q, 0);

  const handleDecrement = (ticketId: string) => {
    setQuantity((prev) => ({
      ...prev,
      [ticketId]: Math.max((prev[ticketId] || 0) - 1, 0),
    }));
  };

  const handleIncrement = (ticketId: string) => {
    if (
      maxTotalQuantity !== undefined &&
      getTotalQuantity() >= maxTotalQuantity
    ) {
      return;
    }
    setQuantity((prev) => ({
      ...prev,
      [ticketId]: (prev[ticketId] || 0) + 1,
    }));
  };

  const isCheckoutButtonActive = totalPrice > 0;

  const checkoutButtonClass = isCheckoutButtonActive
    ? 'bg-event'
    : 'bg-gray-light';
  const termsButtonClass = isTermsAccepted ? 'bg-event' : 'bg-gray-light';
  const isMaxQuantityReached =
    maxTotalQuantity !== undefined && getTotalQuantity() >= maxTotalQuantity;

  const currency = event?.payments;

  const handlePayNowClick = () => {
    if (isTermsAccepted) {
      // setIsPayNowClicked(true);
    }
  };

  const getSelectedTickets = () => {
    return (
      tickets
        ?.map((ticket) => ({
          id: ticket.id,
          title: ticket.title || ticket.name,
          quantity: quantity[ticket.id] || 0,
        }))
        ?.filter((ticket) => ticket.quantity > 0) || []
    );
  };

  const handleCheckoutClick = () => {
    getTax(getSelectedTickets());
    if (isCheckoutButtonActive) {
      setIsCheckoutClicked(true);
    }
  };

  if (showLoadingPage) {
    return <LoadingPaymentPage />;
  }

  const modalProps = {
    isPayNowClicked,
    id: id || '',
    isPaymentSuccessful,
    isCheckoutClicked,
    firstImage: event?.featured_image.url || '',
    title: event?.name || 'Untitled Event',
    place: event?.address || '',
    formattedDate,
    paymentProvider,
    currency,
    tickets,
    quantity,
    totalPrice,
    formatPriceString,
    isTermsAccepted,
    setIsTermsAccepted,
    session,
    isPurchaseDisabled,
    handleIncrement,
    handleDecrement,
    maxTotalQuantity,
    isMaxQuantityReached,
    translations,
    checkoutButtonClass,
    termsButtonClass,
    handlePayNowClick,
    onSendPayment,
    handleCheckoutClick,
    isCheckoutButtonActive,
  };

  return (
    <div
      style={{ zIndex: 200 }}
      className={classNames(
        'fixed inset-0 flex justify-center overflow-y-auto z-[100] ',
        isPaymentSuccessful || isPaymentError
          ? 'bg-black overflow-hidden '
          : 'bg-opacity-50 backdrop-blur-[16px] pt-[70px] lg:pb-[70px] '
      )}
    >
      {isPaymentSuccessful ? (
        <></>
      ) : (
        <ModalHeader
          session={session?.type}
          onBackClick={handleBackClick}
          onCloseClick={handleCloseClick}
          currentStep={isCheckoutClicked ? (isPayNowClicked ? 3 : 1) : 0}
          isPaymentSuccessful={isPaymentSuccessful}
          isPaymentError={isPaymentError}
        />
      )}

      <div
        className={classNames(
          'bg-black relative h-auto lg:rounded-tl-[30px] lg:rounded-br-[30px]',
          {
            'lg:h-screen overflow-hidden overflow-y-auto w-full ':
              isPaymentSuccessful,
            'lg:h-screen w-full ': isPaymentError,
            'border lg:border-gray-medium overflow-y-auto max-w-[532px] max-h-[85vh] xl:max-h-[90vh] 2xl:max-h-[86vh] 3xl:max-h-[70vh]':
              !isPaymentSuccessful && !isPaymentError,
          }
        )}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className={classNames([
            'px-[20px] h-full ',
            {
              'lg:px-[0px] lg:py-[0px] w-full':
                isPaymentSuccessful || isPaymentError,
              'lg:px-[40px] lg:py-[40px] py-[40px]':
                !isPaymentSuccessful && !isPaymentError,
            },
          ])}
        >
          {isPaymentSuccessful ? (
            <div className="flex flex-col items-center ">
              <HeaderPayment
                sessionType={sessionType}
                handleCloseClick={handleCloseClick}
              />

              <PaymentSuccessMessage
                session={session?.type}
                place={event?.address}
                background={event?.featured_image.url}
                selectedTickets={getSelectedTickets()}
                title={event?.name}
                tax={selectedTax ? selectedTax : tax}
                starts_at={event?.starts_at as string}
              />
            </div>
          ) : isPaymentError ? (
            <div className="flex flex-col items-center ">
              <HeaderPayment
                sessionType={sessionType}
                handleCloseClick={handleCloseClick}
              />
              <PaymentErrorMessage sessionType={sessionType} />
            </div>
          ) : (
            <TicketDetails
              {...modalProps}
              onClose={onClose}
              tax={selectedTax ? selectedTax : tax}
            />
          )}
        </div>
      </div>
    </div>
  );
};
