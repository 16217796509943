import { useSupabase } from '../providers/supabase';
import {
  supabaseCeneClient,
  supabaseCenePlusClient,
} from '../supabase/supabase';
import { Event } from '@ceneteam/namespace';
import { useEffect, useState } from 'react';

export const useGetWaitlistEvents = () => {
  const { session } = useSupabase();
  const [eventsWaitlist, setEventsWaitlist] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const sessionType = session?.type === 'cene';

  const client = sessionType ? supabaseCeneClient : supabaseCenePlusClient;

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      setError(null);

      try {
        // Fetch the user's waitlisted events

        const { data: userWaitlistEvents, error: waitlistError } = await client
          .from('waitlist')
          .select('event_id')
          .eq('customer_id', session?.user?.id);

        const data = await client
          .from('events')
          .select()
          .in(
            'id',
            userWaitlistEvents
              ? userWaitlistEvents.map((event) => event.event_id)
              : []
          );

        if (waitlistError) {
          throw waitlistError;
        }

        if (waitlistError) {
          throw waitlistError;
        }

        // Filter events based on the user's waitlisted events

        if (data.data) {
          setEventsWaitlist(data.data);
        } else {
          setEventsWaitlist([]);
        }
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error('An unknown error occurred')
        );
        setEventsWaitlist([]); // Set events to an empty array if there's an error
      } finally {
        setLoading(false);
        if (initialLoad) setInitialLoad(false);
      }
    };

    fetchEvents();
  }, []);

  return { eventsWaitlist, loading: initialLoad || loading, error };
};
