import { Loader } from '../../../components/loader/cenePlus/loader';
import ReusableTabs from '../../../components/reusableTabs/reusableTabs';
import translations from '../../../constants/translations.json';
import { formatEventDate } from '../../../helpers/formatDate';
import { useGetLikedEvents } from '../../../hooks/useGetUserLikedEvents';
import { supabaseCenePlusClient } from '../../../supabase/supabase';
import { ReactComponent as CalendarSVG } from '../../../svg/calendarPlus.svg';
import ActionsSection from './tabs/actions';
import DetailsSection from './tabs/details';
import LineupSection from './tabs/lineup';
import LocationSection from './tabs/location';
import MediaSection from './tabs/media';
import OrganizerSection from './tabs/organizer';
import SimilarSection from './tabs/similar';
import { Event } from '@ceneteam/namespace';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

enum Tabs {
  DETAILS = 'Details',
  LINEUP = 'Lineup',
  LOCATION = 'Location',
}

const EventDetailsPlusSection = () => {
  const { id: eventId } = useParams();
  const { loadEvents, eventsLiked } = useGetLikedEvents();
  const [event, setEvent] = useState<Event | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [supabaseError, setSupabaseError] = useState<string | null>('');
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.DETAILS);

  const formattedDate = formatEventDate(event?.starts_at || '');

  const tabs = {
    [Tabs.DETAILS]: Tabs.DETAILS,
    [Tabs.LINEUP]: Tabs.LINEUP,
    [Tabs.LOCATION]: Tabs.LOCATION,
  };

  useEffect(() => {
    fetchCenePlusEvent();
    loadEvents();
  }, [eventId]);

  const fetchCenePlusEvent = async () => {
    try {
      setSupabaseError(null);
      setLoading(true);

      const { data, error } = await supabaseCenePlusClient
        .from('events')
        .select('*')
        .eq('id', eventId)
        .single();

      if (error) throw error;

      setEvent(data as Event);
    } catch (error) {
      if (error instanceof Error) {
        setSupabaseError(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Grid container className="max-w-[1092px]" spacing={3}>
        {supabaseError ? (
          <div className="flex flex-col justify-center items-center h-[558px] w-full text-center">
            <h1 className="text-lg font-light text-event text-[2rem]/[2.5rem]">
              Oops! Something went wrong.
            </h1>
            <p className="text-gray-light mt-4 max-w-[400px]">
              We couldn&apos;t load the event details at the moment. Please
              check your internet connection or try again later.
            </p>
          </div>
        ) : isLoading ? (
          <div className="flex justify-center items-center h-[558px] w-full">
            <Loader />
          </div>
        ) : (
          <>
            <Grid item xs={12} md={5}>
              <MediaSection event={event} />
              <div className="max-lg:hidden block mt-[2.5rem]">
                <p className={classNames('uppercase', 'text-white')}>
                  {translations.ticketDetails.date}
                </p>
                <p
                  className={classNames(
                    'flex items-center gap-[1.5rem] text-[1.3125rem]/[1.3125rem] font-light mt-[0.5rem]',
                    'text-white'
                  )}
                >
                  <CalendarSVG />
                  {formattedDate}
                </p>
              </div>
              <div className="max-md:hidden">
                <OrganizerSection event={event} />
              </div>
            </Grid>
            <Grid item xs={12} md={7}>
              <div className="max-lg:hidden block">
                <h3
                  className={classNames(
                    'text-[1.0625rem]/[1.375rem] font-light',
                    'text-gray-light'
                  )}
                >
                  {event?.category}
                </h3>
                <h1
                  className={classNames(
                    'text-[3.5rem]/[4rem] font-light mt-[0.5rem]',
                    'text-generic'
                  )}
                >
                  {event?.name}
                </h1>
              </div>

              <ReusableTabs
                tabs={tabs}
                activeTab={activeTab}
                onTabClick={(tab) => setActiveTab(tab)}
                sessionType={false}
                activeTabColor="transparent"
                inactiveTabColor="transparent"
                className="bg-generic"
              />
              <div className="mt-[2.5rem] max-lg:mt-[1.5rem] max-w-full">
                {activeTab === Tabs.DETAILS && <DetailsSection event={event} />}
                {activeTab === Tabs.LINEUP && <LineupSection event={event} />}
                {activeTab === Tabs.LOCATION && (
                  <LocationSection event={event} />
                )}
              </div>

              <ActionsSection event={event} eventsLiked={eventsLiked} />
            </Grid>
          </>
        )}
      </Grid>
      <SimilarSection />
    </>
  );
};

export default EventDetailsPlusSection;
