import { Button } from '../../../../components/button/button';
import { Checkbox } from '../../../../components/checkbox/checkbox';
import { ErrorBox } from '../../../../components/errorBox/errorBox';
import { Input } from '../../../../components/input/cene/input';
import { Label } from '../../../../components/label/cene/label';
import PasswordRequirementsList from '../../../../components/passwordRequirementsList/passwordRequirementsList';
import { ROUTE_HOME } from '../../../../constants/routes';
import translation from '../../../../constants/translations.json';
import { RegisterSchema } from '../../../../schemas/registerSchema';
import {
  lowercaseRule,
  numberRule,
  specialCharacterRule,
  uppercaseRule,
} from '../../../../schemas/validationRules';
// import { supabaseCeneClient } from '../../../../supabase/supabase';
import { signUpWithProfile } from '../../../../utils/user-auth-helper';
import { AuthError } from '@supabase/supabase-js';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// interface FormProps {
//   onNext: () => void;
// }

export const PasswordForm = () => {
  const formik = useFormikContext<RegisterSchema>();
  const [supabaseError, setSupabaseError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const isPasswordValid = formik.values.password
    ? !formik.errors.password
    : false;
  const isPasswordConfirmValid = formik.values.confirmPassword
    ? !formik.errors.confirmPassword
    : false;
  const isTermValid = formik.values.agreement
    ? !formik.errors.agreement
    : false;

  const isFormValid = isPasswordValid && isPasswordConfirmValid && isTermValid;

  const [isChecked, setChecked] = useState(formik.values.agreement);

  const [passwordValid, setPasswordValid] = useState({
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });

  useEffect(() => {
    formik.setFieldValue('agreement', isChecked);
  }, [isChecked]);

  const handleChangePassword = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    formik.setFieldValue('password', value);
    validatePassword(value);
  };

  const validatePassword = (newPassword: string) => {
    const isUppercase = uppercaseRule?.isValidSync(newPassword);
    const isLowercase = lowercaseRule?.isValidSync(newPassword);
    const isNumber = numberRule?.isValidSync(newPassword);
    const isSpecialCharacter = specialCharacterRule?.isValidSync(newPassword);

    setPasswordValid({
      uppercase: isUppercase,
      lowercase: isLowercase,
      number: isNumber,
      specialChar: isSpecialCharacter,
    });
  };

  const handleCheckboxChange = (checked: boolean) => {
    setChecked(checked);
  };

  const handleRegisterUser = async () => {
    //iFormValid is the setloading state
    setIsLoading(true);

    if (!isFormValid) {
      setSupabaseError('Fill in the required fields');
      setIsLoading(false);
      return;
    }

    try {
      const signUpResponse = await signUpWithProfile(
        formik.values.email,
        formik.values.password,
        formik.values.firstName,
        formik.values.lastName,
        formik.values.gender || '',
        formik.values.dateOfBirth || null,
        `+${formik.values.countryCode}${formik.values.phone}`
      );

      if (signUpResponse.user) {
        navigate(ROUTE_HOME, { state: { type: 'cene' } });
      }
    } catch (error) {
      setIsLoading(false);
      if (error instanceof AuthError) {
        setSupabaseError(error.message);
      }
      console.log('err', error);
    }

    // const updateUser = await supabaseCeneClient.auth.updateUser(
    //   {
    //     email: formik.values.email,
    //   },
    //   {
    //     emailRedirectTo: `${window.location.origin}/auth/register/cene?verify_email=verified`,
    //   }
    // );
    // if (updateUser.error) throw updateUser.error;
    // const { error } = await supabaseCeneClient
    //   .from('customers')
    //   .update([
    //     {
    //       first_name: formik.values.firstName,
    //       last_name: formik.values.lastName,
    //       gender: formik.values.gender,
    //       birthdate: formik.values.dateOfBirth,
    //       phone_number: `+${formik.values.countryCode}${formik.values.phone}`,
    //     },
    //   ])
    //   .eq('user_id', updateUser.data?.user?.id);
    // if (error) throw error;
  };

  return (
    <div className="pt-[24px] pb-[20px]">
      {supabaseError && <ErrorBox message={supabaseError} />}
      <h1 className="text-[2rem] text-white font-light mb-[12px]">
        {translation.steps.createPassword.title}
      </h1>
      <p
        className={classNames([
          'text-[0.875rem] text-generic font-light mb-[40px]',
          'max-lg:mb-[24px]',
        ])}
      >
        {translation.steps.createPassword.description}
      </p>
      <div className="flex flex-col">
        <div className="mb-[40px] max-lg:mb-[24px]">
          <Label id="password" isRequired={true}>
            Password
          </Label>
          <Input
            id="password"
            type="password"
            placeholder="Enter password..."
            value={formik.values.password}
            onChange={handleChangePassword}
            onBlur={formik.handleBlur}
            error={formik.errors.password}
            touched={Boolean(formik.touched.password)}
          />
          {formik.errors.password && formik.touched.password && (
            <div className="text-[0.875rem] text-red font-light">
              {formik.errors.password}
            </div>
          )}
        </div>
        <div className="mb-[40px] max-lg:mb-[24px] flex flex-col gap-[12px]">
          <label className="text-[0.875rem] text-generic font-light">
          {translation.createNewPasswordPage.passwordMustHave}
          </label>
          <PasswordRequirementsList passwordValid={passwordValid} />
        </div>
        <div className="mb-[40px] max-lg:mb-[24px]">
          <Label id="confirmPassword" isRequired={true}>
            Confirm password
          </Label>
          <Input
            id="confirmPassword"
            type="password"
            placeholder="Enter password..."
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.confirmPassword}
            touched={Boolean(formik.touched.confirmPassword)}
          />
          {formik.errors.confirmPassword && formik.touched.confirmPassword && (
            <div className="text-[0.875rem] text-red font-light ">
              {formik.errors.confirmPassword}
            </div>
          )}
        </div>
        <Checkbox
          checked={isChecked}
          onChange={handleCheckboxChange}
          label={translation.steps.createPassword.terms}
        />
        <Button
          className="mt-[24px] max-lg:mt-[16px]"
          color="content"
          type="button"
          // onClick={onNext}
          onClick={() => handleRegisterUser()}
          disabled={!isFormValid || isLoading}
        >
          {isLoading ? 'Signing up...' : translation.next}
        </Button>
      </div>
    </div>
  );
};
