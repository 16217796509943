import {
  emailRule,
  lowercaseRule,
  numberRule,
  onlyLatinCharactersRule,
  specialCharacterRule,
  uppercaseRule,
} from './validationRules';
import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
  email: emailRule,
  password: Yup.string()
    .min(6)
    .max(24)
    .required('Password is required')
    .concat(onlyLatinCharactersRule)
    .concat(uppercaseRule)
    .concat(lowercaseRule)
    .concat(numberRule)
    .concat(specialCharacterRule)
    .min(6, 'Password must be at least 6 characters long')
    .max(24, 'Password cannot exceed 24 characters'),
});

export type LoginSchema = Yup.InferType<typeof loginSchema>;
