import { Button } from '../../components/button/button';
import { PreferenceCard } from '../../components/preferenceCard/cene/preferenceCard';
import { PreferenceCard as PreferenceCardCenePlus } from '../../components/preferenceCard/cenePlus/preferenceCard';
import Toast from '../../components/toast/toast';
import { ROUTE_SETTINGS } from '../../constants/routes';
import translation from '../../constants/translations.json';
import { useSupabase } from '../../providers/supabase';
import {
  supabaseCeneClient,
  supabaseCenePlusClient,
} from '../../supabase/supabase';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  selectedInterests: string[] | null;
}

interface FormValues {
  interests: string[];
}

export const InterestsForm = ({ selectedInterests }: Props) => {
  const { session } = useSupabase();
  const navigate = useNavigate();
  const id = session?.user?.id;

  const isCene = session?.type === 'cene';

  const sessionType = session?.type === 'cene' ? 'cene' : 'cenePlus';
  const [error, setError] = useState<string>('');
  const [isVisibleToast, setVisibleToast] = useState(false);
  const interests = INTERESTS[sessionType];

  const formik = useFormik<FormValues>({
    initialValues: {
      interests: [],
    },
    onSubmit: async (values) => {
      const client = isCene ? supabaseCeneClient : supabaseCenePlusClient;

      const { error } = await client
        .from('customers')
        .update({ interests: values.interests })
        .eq('id', id);

      if (error) {
        setError(error.message);
        return;
      }

      setVisibleToast(true);

      setTimeout(() => {
        setVisibleToast(false);
        navigate(ROUTE_SETTINGS);
      }, 3000);
    },
  });

  useEffect(() => {
    if (selectedInterests) formik.setFieldValue('interests', selectedInterests);
  }, [selectedInterests]);

  const handleInterestSelect = (title: string) => {
    const { interests } = formik.values;
    if (interests.includes(title)) {
      formik.setFieldValue(
        'interests',
        interests.filter((interest) => interest !== title)
      );
    } else if (interests.length < 5) {
      formik.setFieldValue('interests', [...interests, title]);
    }
  };

  const selectedCount = formik.values.interests.length;

  return (
    <div className="pt-[24px] pb-[20px]">
      {isVisibleToast && (
        <Toast
          message={'Your interests preferences has been successfully updated.'}
          bgColor={'bg-content'}
          onClose={() => setVisibleToast(false)}
        />
      )}
      <h1 className="text-[2rem] text-white font-light mb-[12px]">
        {isCene
          ? translation.steps.interests.title
          : translation.steps.interests.titleCenePlus}
      </h1>
      <p
        className={classNames([
          'text-[0.875rem] text-generic font-light mb-[40px]',
          'max-lg:mb-[24px]',
        ])}
      >
        {isCene
          ? translation.steps.interests.description
          : translation.steps.interests.descriptionCenePlus}
      </p>

      <Grid
        container
        sx={{
          width: { xs: '100%', md: '456px' },
          gap: { xs: '12px', md: '16px' },
        }}
      >
        {interests.map(({ title, icon }) => (
          <Grid item xs={3.65157} sm={3.65157} md={2.684} key={title}>
            {isCene ? (
              <PreferenceCard
                selected={formik.values.interests.includes(title)}
                onClick={() => handleInterestSelect(title)}
                href={icon}
              >
                {title}
              </PreferenceCard>
            ) : (
              <PreferenceCardCenePlus
                selected={formik.values.interests.includes(title)}
                onClick={() => handleInterestSelect(title)}
                href={icon}
              >
                {title}
              </PreferenceCardCenePlus>
            )}
          </Grid>
        ))}
      </Grid>
      {error && (
        <div className="text-[0.875rem] text-red font-light mt-[8px]">
          {error}
        </div>
      )}
      <div
        className={classNames([
          'mt-[40px] max-w-[456px] flex items-center gap-[40px]',
          'max-lg:gap-[24px]',
        ])}
      >
        <div className={classNames(['w-full', 'max-lg:w-2/3'])}>
          <p className="text-[1rem] text-white font-medium">
            {selectedCount}/5 {translation.selected}
          </p>
          <p className="text-[0.875rem] text-generic font-light">
            {translation.selectionInfo}
          </p>
        </div>
        <Button
          onClick={() => formik.handleSubmit()}
          className={classNames(['w-full', 'max-lg:w-1/3'])}
          color={isCene ? 'content' : 'generic'}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

const INTERESTS = {
  cene: [
    { icon: '/pictures/interests-cene/art-cene.jpg', title: 'Art' },
    { icon: '/pictures/interests-cene/beauty-cene.jpg', title: 'Beauty' },
    {
      icon: '/pictures/interests-cene/celebrities-cene.jpg',
      title: 'Celebrities',
    },
    { icon: '/pictures/interests-cene/events-cene.jpg', title: 'Events' },
    { icon: '/pictures/interests-cene/gaming-cene.jpg', title: 'Gaming' },
    { icon: '/pictures/interests-cene/lifestyle-cene.jpg', title: 'Lifestyle' },
    { icon: '/pictures/interests-cene/tv-cene.jpg', title: 'Movies & TV' },
    { icon: '/pictures/interests-cene/music-cene.jpg', title: 'Music' },
    { icon: '/pictures/interests-cene/podcasts-cene.jpg', title: 'Podcast' },
    {
      icon: '/pictures/interests-cene/pop-culture-cene.jpg',
      title: 'Pop culture',
    },
    { icon: '/pictures/interests-cene/sports-cene.jpg', title: 'Sports' },
    { icon: '/pictures/interests-cene/travel-cene.jpg', title: 'Travel' },
  ],
  cenePlus: [
    { icon: '/pictures/lifestyle-ceneplus/art-ceneplus.jpg', title: 'Art' },
    {
      icon: '/pictures/lifestyle-ceneplus/beauty-ceneplus.jpg',
      title: 'Beauty',
    },
    { icon: '/pictures/lifestyle-ceneplus/cars-ceneplus.jpg', title: 'Cars' },
    {
      icon: '/pictures/lifestyle-ceneplus/entertainment-ceneplus.jpg',
      title: 'Entertainment',
    },
    {
      icon: '/pictures/lifestyle-ceneplus/events-ceneplus.jpg',
      title: 'Events',
    },
    {
      icon: '/pictures/lifestyle-ceneplus/fine-dining-ceneplus.jpg',
      title: 'Fine Dining',
    },
    {
      icon: '/pictures/lifestyle-ceneplus/movies-ceneplus.jpg',
      title: 'Movies/TV',
    },
    { icon: '/pictures/lifestyle-ceneplus/music-ceneplus.jpg', title: 'Music' },
    {
      icon: '/pictures/lifestyle-ceneplus/podcast-ceneplus.jpg',
      title: 'Podcasts',
    },
    {
      icon: '/pictures/lifestyle-ceneplus/pop-culture-ceneplus.jpg',
      title: 'Pop Culture',
    },
    {
      icon: '/pictures/lifestyle-ceneplus/sports-ceneplus.jpg',
      title: 'Sports',
    },
    {
      icon: '/pictures/lifestyle-ceneplus/travel-ceneplus.jpg',
      title: 'Travel',
    },
  ],
};
