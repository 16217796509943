import {
  lowercaseRule,
  numberRule,
  onlyLatinCharactersRule,
  specialCharacterRule,
  uppercaseRule,
} from './validationRules';
import * as Yup from 'yup';

export const createNewPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6)
    .max(32)
    .required('Password is required')
    .concat(onlyLatinCharactersRule)
    .concat(uppercaseRule)
    .concat(lowercaseRule)
    .concat(numberRule)
    .concat(specialCharacterRule)
    .min(6, 'Password must be at least 6 characters long')
    .max(24, 'Password cannot exceed 24 characters'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Password missmatch')
    .required('Please confirm your password'),
});

export type CreateNewPasswordSchema = Yup.InferType<
  typeof createNewPasswordSchema
>;
