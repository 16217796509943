import { ReactComponent as InfoSvg } from '../../../src/svg/info.svg';
import { ReactComponent as TicketErrorSvg } from '../../../src/svg/ticketError.svg';
import TicketSwitch from '../../components/switch/ticketSwitch';
import translations from '../../constants/translations.json';
import { SeatsioPreview } from '../../seatsio/preview';
import AccessibleSection from './accessible';
import { Bulk } from './bulkList';
import { TicketList } from './ticketItem';
import { Ticket as OriginalTicket } from '@ceneteam/namespace';
import classNames from 'classnames';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

interface Ticket extends OriginalTicket {
  title: OriginalTicket['name'];
}

interface TabContentProps {
  activeTab: 'tickets' | 'tables' | 'bulk';
  ticketTypes: Ticket[] | null;
  quantity: { [key: string]: number };
  isPurchaseDisabled?: boolean;
  handleIncrement: (ticketId: string) => void;
  handleDecrement: (ticketId: string) => void;
  maxTotalQuantity: number | undefined;
  isMaxQuantityReached: boolean;
  session: string | undefined;
  handleQuantityChange: (ticketId: string, value: number) => void;
  setAccessible?: Dispatch<SetStateAction<boolean>>;
}

export const TabContent: React.FC<TabContentProps> = ({
  activeTab,
  ticketTypes,
  quantity,
  isPurchaseDisabled,
  handleIncrement,
  handleDecrement,
  session,
  maxTotalQuantity,
  isMaxQuantityReached,
  handleQuantityChange,
  setAccessible,
}) => {
  const [isCombined, setIsCombined] = useState(false);

  useEffect(() => {
    if (setAccessible) {
      setAccessible(isCombined);
    }
  }, [isCombined, setAccessible]);

  const hasAccessibleTickets =
    ticketTypes?.some((ticket) => ticket.is_accessible) ?? false;

  const accessibleTickets =
    ticketTypes?.filter((ticket) => ticket.is_accessible) ?? [];

  const renderSeatsio = (
    <SeatsioPreview eventId="a592c5b4-0869-4ff9-81d9-3e9932087834" />
  );

  return (
    <>
      {activeTab === 'tickets' ? (
        <>
          <div
            className={classNames(
              isCombined &&
                'opacity-70 cursor-not-allowed transition-opacity duration-300'
            )}
          >
            <TicketList
              session={session}
              ticketTypes={ticketTypes}
              isPurchaseDisabled={isPurchaseDisabled}
              quantity={isCombined ? [0, 0] : quantity}
              handleIncrement={isCombined ? () => {} : handleIncrement}
              handleDecrement={isCombined ? () => {} : handleDecrement}
              maxTotalQuantity={isCombined ? 0 : maxTotalQuantity}
            />
            {isMaxQuantityReached && !isCombined && (
              <div className="mt-[16px] flex flex-row items-center">
                <TicketErrorSvg className="lg:mr-[24px] mr-[16px]" />
                <p className="text-red lg:text-[0.875rem] lg:leading-[14px] font-light leading-[18px] text-[0.75rem]">
                  {translations.modalTicketing.error}
                </p>
              </div>
            )}
          </div>

          {hasAccessibleTickets && (
            <div
              className={classNames(
                'border border-generic-light p-[1.25rem] rounded rounded-tl-[30px] rounded-br-[30px] mt-[1rem]'
              )}
            >
              <div className="flex items-center gap-[1.25rem]">
                <TicketSwitch
                  isCombined={isCombined}
                  setIsCombined={setIsCombined}
                />
                <span
                  className={classNames(
                    'text-generic text-[1.0625rem]/[1.375rem] font-light'
                  )}
                >
                  Accessibility & Special Needs
                </span>
              </div>
              {isCombined && (
                <AccessibleSection
                  isCombined={isCombined}
                  session={session}
                  ticketTypes={accessibleTickets}
                  isPurchaseDisabled={isPurchaseDisabled}
                  quantity={quantity}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  maxTotalQuantity={maxTotalQuantity}
                />
              )}
              {isMaxQuantityReached && isCombined && (
                <div className="mt-[16px] flex flex-row items-center">
                  <TicketErrorSvg className="lg:mr-[24px] mr-[16px]" />
                  <p className="text-red lg:text-[0.875rem] lg:leading-[14px] font-light leading-[18px] text-[0.75rem]">
                    {translations.modalTicketing.error}
                  </p>
                </div>
              )}
            </div>
          )}

          <div
            className={`flex flex-row justify-center items-center ${session && 'mt-[16px]'} lg:mt-[30px] mt-[32px]`}
          >
            <InfoSvg className="w-[32px] h-[32px] lg:mr-[24px] mr-[16px]" />
            <p className="text-generic text-center lg:text-[0.875rem] lg:leading-[14px] lg:w-[396px] w-full font-light leading-[18px] text-[0.75rem]">
              {translations.modalTicketing.info}
            </p>
          </div>
        </>
      ) : activeTab === 'tables' ? (
        renderSeatsio
      ) : activeTab === 'bulk' ? (
        <>
          <Bulk
            ticketTypes={ticketTypes}
            handleQuantityChange={handleQuantityChange}
            session={session}
            quantity={quantity}
          />

          <div
            className={`flex flex-row justify-center items-center ${session && 'mt-[16px]'} lg:mt-[30px] mt-[32px]`}
          >
            <InfoSvg className="w-[32px] h-[32px] lg:mr-[24px] mr-[16px]" />
            <p className="text-generic text-start lg:text-[0.875rem] lg:leading-[14px] lg:w-[396px] w-full font-light leading-[18px] text-[0.75rem]">
              {translations.modalTicketing.bulkInfo}
            </p>
          </div>
        </>
      ) : null}
    </>
  );
};
