import { Card } from '../../components/card/card';
import { CardPlus } from '../../components/card/cardPlus';
import { ModalLogIn } from '../../components/modalLogIn/modal';
import { Event } from '@ceneteam/namespace';
import React, { useState } from 'react';

interface CardListProps {
  cards: Event[];
  cardsPlus: Event[];
  handleHeartClick: () => void;
  session: string | undefined;
  filterType: 'upcoming' | 'previous';
}

export const CardListOrganizer: React.FC<CardListProps> = ({
  cards,
  cardsPlus,
  handleHeartClick,
  session,
}) => {
  const firstFourCards = cards?.slice(0, 4);
  const firstFourCardsPlus = cardsPlus?.slice(0, 4);
  const [showLogInModal, setShowLogInModal] = useState(false);
  const sessionType = session == 'cene' || session == undefined;

  const handleShowLogInModal = () => {
    setShowLogInModal(true);
  };

  const handleCloseLogInModal = () => {
    setShowLogInModal(false);
  };

  return (
    <div className="flex flex-col">
      {sessionType && (
        <div className="flex flex-wrap gap-[24px]  mb-[72px]">
          {firstFourCards.map((card, index) => {
            const { starts_at, ...cardProps } = card;
            return (
              <div key={index} className="flex-shrink-0 ">
                <Card
                  event_id={card.id}
                  title={card.name}
                  place={card.address}
                  starts_at={starts_at ?? ''}
                  setShowLogInModal={handleShowLogInModal}
                  background={card.images}
                  key={card.name}
                  handleHeartClick={handleHeartClick}
                  {...cardProps}
                />
              </div>
            );
          })}
        </div>
      )}

      {!sessionType && (
        <div className="flex flex-wrap gap-[24px]  mb-[72px]">
          {firstFourCardsPlus.map((card, index) => {
            const { starts_at, ...cardProps } = card;
            return (
              <div key={index} className="flex-shrink-0">
                <CardPlus
                  event_id={card.id}
                  title={card.name}
                  place={card.address}
                  starts_at={starts_at ?? ''}
                  setShowLogInModal={handleShowLogInModal}
                  background={card.images}
                  key={card.name}
                  handleHeartClick={handleHeartClick}
                  {...cardProps}
                />
              </div>
            );
          })}
        </div>
      )}

      {showLogInModal && (
        <ModalLogIn
          isOpen={showLogInModal}
          onClose={handleCloseLogInModal}
          heartClick={true}
        />
      )}
    </div>
  );
};
