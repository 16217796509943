import { ReactComponent as CalendarBlankSvg } from '../../../src/svg/CalendarBlankSmall.svg';
import { ReactComponent as MapSvg } from '../../../src/svg/MapPinLineSmall.svg';
import { ReactComponent as CloseSvg } from '../../../src/svg/close.svg';
import { ROUTE_AUTH, ROUTE_LOGIN } from '../../constants/routes';
import translations from '../../constants/translations.json';
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';
import { Header } from '../../layout/header/header';
import { Button } from '../button/button';
import { Event } from '@ceneteam/namespace';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  heartClick?: boolean;
  event?: Event | null;
}

export const ModalLogIn: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  heartClick,
  event,
}) => {
  useDisableBodyScroll(isOpen);
  const navigate = useNavigate();
  const location = useLocation();
  if (!isOpen) return null;

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className={classNames([
        'fixed inset-0 bg-black bg-opacity-50 backdrop-blur-[16px] flex justify-center items-center z-[100px]',
      ])}
      onClick={handleOverlayClick}
    >
      <div
        className="bg-black max-w-[532px] w-full relative lg:rounded-tl-[30px] lg:rounded-br-[30px] border lg:border-gray-medium lg:h-auto h-full overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="lg:hidden  px-[20px]">
          <Header />
        </div>
        <div className="lg:hidden flex justify-between items-start px-[20px]  pt-[16px]">
          <h1 className="text-white text-[1.5rem] leading-[32px]">
            {translations.modalLogIn.title}
          </h1>
          <button
            onClick={onClose}
            aria-label="Close"
            className="p-[8px] border border-gray-medium rounded-tl-[16px] rounded-br-[16px] lg:hidden"
          >
            <CloseSvg />
          </button>
        </div>

        <div className="lg:px-[40px] px-[20px] lg:py-[40px]">
          <h1 className="text-white lg:text-center text-[2rem] leading-[40px] text-start hidden lg:block font-light">
            {translations.modalLogIn.title}
          </h1>
          <p className="text-white text-[1.0625rem] leading-[22px] font-normal text-center mt-[24px]">
            {translations.modalLogIn.desc}
          </p>
          {heartClick ? (
            <div></div>
          ) : (
            <div className="lg:overflow-y-auto flex flex-col lg:flex-row mt-[24px] items-center lg:items-start overflow-hidden">
              <img
                src={event?.featured_image.url || ''}
                alt="Organization"
                className="mb-[16px] lg:mb-0 lg:mr-[31px] w-[119px] h-[153.68px] mx-auto lg:mx-0 rounded-tl-[20px] rounded-br-[20px] "
              />
              <div className="w-full lg:w-[301px] text-center lg:text-left">
                <h2 className="text-event text-[1.3125rem] leading-[21px] mb-[16px] font-light">
                  {event?.name}
                </h2>
                <div className="flex flex-col items-start">
                  <div className="flex items-center mb-[16px]">
                    <MapSvg className="mr-[12px]" />
                    <p
                      title={event?.address}
                      className="text-white text-[0.875rem] leading-[14px]  w-[265px] text-start font-light truncate h-[14px]"
                    >
                      {event?.address}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <CalendarBlankSvg className="mr-[12px]" />
                    <p className="text-white text-[0.875rem] leading-[14px] font-light">
                      {moment(event?.starts_at).format('dddd, MMMM D / ha') +
                        ' WAT'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="flex flex-row mt-[24px] justify-center lg:justify-start">
            <Button
              className="w-[214px] mr-[24px]"
              color="ticket"
              onClick={() => navigate(ROUTE_AUTH)}
            >
              {translations.modalLogIn.textOneButton}
            </Button>
            <Button
              className="w-[214px]"
              color="content"
              onClick={() =>
                navigate(ROUTE_LOGIN, { state: { from: location.pathname } })
              }
            >
              {translations.modalLogIn.textTwoButton}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
