import FaviconManager from '../../components/faviconManager/faviconManager';
import {
  ROUTER_REGISTER_CENE,
  ROUTER_REGISTER_CENE_PLUS,
  ROUTE_LOGIN,
} from '../../constants/routes';
import translations from '../../constants/translations.json';
import { useCeneMode } from '../../providers/ceneMode';
import { ReactComponent as RadioCheckedSVG } from '../../svg/radio-checked.svg';
import { ReactComponent as RadioUncheckedSVG } from '../../svg/radio-unchecked.svg';
import { ReactComponent as RadioCheckedGenericSVG } from '../../svg/radiobuttonGeneric.svg';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

type SignupType = 'cene' | 'cene+';

const EntryPage = () => {
  const { ceneMode, setMode } = useCeneMode();

  const navigate = useNavigate();
  const title = translations.entry.title;
  const highlightedText = 'One Memory at a Time.';
  const [beforeHighlight, afterHighlight] = title.split(highlightedText);

  const titlePlus = translations.entry.titlePlus;
  const highlightedTextPlus = 'Experience the Extraordinary.';
  const [beforeHighlightPlus, afterHighlightPlus] =
    titlePlus.split(highlightedTextPlus);

  const signupCene = ceneMode === 'cene';

  const onSetSignupType = useCallback((type: SignupType) => {
    setMode(type);

    // Simulate loading with a timeout
  }, []);

  const onClick = useCallback(
    () =>
      navigate(
        ceneMode === 'cene' ? ROUTER_REGISTER_CENE : ROUTER_REGISTER_CENE_PLUS
      ),
    [ceneMode]
  );

  const gotoLogin = useCallback(
    () =>
      navigate(ROUTE_LOGIN, {
        state: {
          type: ceneMode,
        },
      }),
    [ceneMode]
  );

  const renderRadiobutton = (checked: boolean) => {
    if (!checked) {
      return <RadioUncheckedSVG />;
    }

    return signupCene ? <RadioCheckedSVG /> : <RadioCheckedGenericSVG />;
  };

  return (
    <div
      className={classNames([
        'container mx-auto flex flex-col justify-center pb-[20px] pt-[79px]',
        'max-lg:h-full max-lg:pt-0 max-lg:pb-[24px]',
      ])}
    >
      <div
        className={classNames([
          'flex justify-between',
          'max-lg:flex-col-reverse max-lg:items-center',
        ])}
      >
        <div className="flex-1 max-w-[553px]">
          <h1
            className={classNames([
              'lg:text-[3.5rem] font-light text-white mb-8 ',
              'max-lg:text-h1/[2.5rem] max-lg:mb-[16px] max-lg:text-center leading-[56px]',
              'max-md:text-left',
            ])}
          >
            {signupCene ? (
              <>
                {beforeHighlight}
                <span className="text-content whitespace-nowrap">
                  One Memory
                  <span className="block">at a Time.</span>
                </span>
                {afterHighlight}
              </>
            ) : (
              <>
                {beforeHighlightPlus}
                <span className="text-generic">{highlightedTextPlus}</span>
                {afterHighlightPlus}
              </>
            )}
          </h1>

          <div className={classNames(['max-lg:flex flex-col items-center '])}>
            <div
              onClick={() => onSetSignupType('cene')}
              className={classNames([
                'max-w-[523px] flex cursor-pointer p-8 text-h2 font-light text-white border border-gray-medium rounded-tl-[30px] rounded-br-[30px] mb-[10px] justify-between items-center',
                'max-lg:max-h-[88px] max-lg:w-full',
                'max-md:max-w-full',
              ])}
            >
              <div className="flex text-h2">
                {translations.entry.signupOnCene}
              </div>
              {renderRadiobutton(ceneMode === 'cene')}
            </div>
            <div
              onClick={() => onSetSignupType('cene+')}
              className={classNames([
                'max-w-[523px] flex cursor-pointer lg:p-8 p-[16px] text-h2 font-light text-white border border-gray-medium rounded-tl-[30px] rounded-br-[30px] justify-between items-center',
                'max-lg:max-h-[88px] max-lg:w-full',
                'max-md:max-w-full',
              ])}
            >
              <div
                className={classNames([
                  'flex items-center text-h2',
                  'max-lg:flex-col-reverse max-lg:items-start',
                ])}
              >
                <span className="max-lg:hidden">
                  {translations.entry.getAccessToCenePlus}
                </span>
                <span className="hidden max-lg:inline whitespace-pre-line">
                  {translations.entry.getAccessToCenePlusMobile}
                </span>
                <div
                  className={classNames([
                    'ml-[12px] border rounded-big bg-generic px-[12px] py-[2px] lg:relative  absolute right-[80px] bottom-[231px] lg:right-0  lg:bottom-0',
                    'max-lg:ml-0',
                  ])}
                >
                  <p
                    className={classNames([
                      'font-normal text-h3 text-black',
                      'max-md:text-textSmall max-md:font-medium ',
                    ])}
                  >
                    {translations.entry.inviteOnly}
                  </p>
                </div>
              </div>
              {renderRadiobutton(ceneMode === 'cene+')}
            </div>
          </div>
          <button
            onClick={onClick}
            className={classNames([
              'mt-[40px] px-[24px] py-[12px] text-h4 text-black  font-medium rounded-tl-[20px] rounded-br-[20px]',
              'max-lg:mb-[32px] max-lg:w-full',
              signupCene ? 'bg-content' : 'bg-generic',
            ])}
          >
            {translations.continue}
          </button>
          <div
            className={classNames([
              'text-h2 font-light text-generic',
              'max-lg:text-h5 max-lg:mb-[32px] max-lg:text-center',
              'max-md:text-left lg:mt-[40px]',
            ])}
          >
            {translations.entry.alreadyHaveAccount}
            <div
              onClick={gotoLogin}
              className="font-medium cursor-pointer inline text-white hover:underline"
            >
              {translations.login}
            </div>
          </div>
        </div>
        <div
          className={classNames([
            'flex-1 relative max-w-[644px]',
            'max-md:mx-auto',
            'max-lg:w-full max-lg:-z-1',
          ])}
        >
          <div className="lg:h-[400px] max-lg:w-full h-[400px]">
            <img
              className={classNames([
                'rounded-tl-[30px] rounded-br-[30px] z-1',
                'max-lg:rounded-tl-[16px] max-lg:rounded-br-[16px] max-lg:w-full',
                signupCene ? '' : 'hidden',
              ])}
              src={
                'https://cene-bucket.s3.eu-north-1.amazonaws.com/app/signUpCene.gif'
              }
              alt="Entry picture"
            />

            <img
              className={classNames([
                'rounded-tl-[30px] rounded-br-[30px] z-1',
                'max-lg:rounded-tl-[16px] max-lg:rounded-br-[16px] max-lg:w-full',
                signupCene ? 'hidden' : '',
              ])}
              src={
                'https://cene-bucket.s3.eu-north-1.amazonaws.com/app/signUpCenePlus.gif'
              }
              alt="Entry picture"
            />

            <div className="bg-back-linear-gradient absolute w-full lg:h-[653px] h-full top-0 z-2" />
          </div>
        </div>
      </div>
      <FaviconManager />
    </div>
  );
};

export default EntryPage;
