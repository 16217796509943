import translations from '../../constants/translations.json';
import { formatEventDate } from '../../helpers/formatDate';
import { OrganizerSection } from '../../pages/ticketDetails/organizer';
import { ReactComponent as CalendarSVG } from '../../svg/calendarPlus.svg';
import { Image } from '@ceneteam/namespace';
import classNames from 'classnames';

export type Ticket = {
  id: string;
  title: string;
  table_id?: string;
  floorplan: {
    tables?: number;
    seats?: number;
    instructions?: string;
    food_options?: string;
  };
  share_status?: string;
  shared_to: {
    email?: string;
    first_name?: string;
    last_name?: string;
    profile_picture_url?: string;
    id?: string;
  };
  shared_by: {
    email?: string;
    first_name?: string;
    last_name?: string;
    profile_picture_url?: string;
    id?: string;
  };
  purchased_at?: string;
  sale_starts_at?: string;
  sale_ends_at?: string;
  description?: string;
  price: {
    NGN?: number;
    GBP?: number;
    USD?: number;
    EUR?: number;
  };
  quantity?: number;
  NGN?: number;
  GBP?: number;
  USD?: number;
  EUR?: number;
  is_accessible: boolean | null;
  is_sale_active: boolean | null;
  is_visible: boolean | null;
};
export type Event = {
  id: string;
  name: string;
  description: string;
  floorplan_id?: string;
  featured_image: Image;
  artists?: string[];
  location: string;
  starts_at: string | null;
  ends_at: string | null;
  category: 'Concert';
  tickets: Ticket[] | null;
  country: string;
  state: string;
  address: string;
  tags?: string[];
  stock: Record<string, number> | null;
  partner_info: {
    id: string;
    name: string;
    description: string | null;
    social_handles: {
      tiktok: string | null;
      twitter: string | null;
      website: string | null;
      facebook: string | null;
      instagram: string | null;
    } | null;
    profile_picture_url: string | null;
  };
  saved: boolean;
};

interface Props {
  sessionType: boolean;
  startEvent: string;
  eventData: Event;
}

const AboutEventSection = ({ sessionType, eventData }: Props) => {
  const formattedDate = formatEventDate(eventData?.starts_at || '');

  return (
    <>
      <div className="max-lg:h-[334px] overflow-hidden relative">
        <img
          src={eventData?.featured_image?.url}
          alt={eventData?.name}
          className="rounded-tl-[30px] w-full"
        />
        <div
          className={classNames(
            'hidden absolute z-20 bottom-[1.5rem] left-[1.25rem]',
            sessionType ? '' : 'max-lg:block'
          )}
        >
          <h3
            className={classNames(
              'text-[0.875rem]/[0.875rem] text-gray-light font-light uppercase'
            )}
          >
            {eventData?.category}
          </h3>
          <h1
            className={classNames(
              'text-[1.75rem]/[1.875rem] text-generic font-light mt-[0.5rem]'
            )}
          >
            {eventData?.name}
          </h1>
        </div>
        <div className="auto bg-gradient absolute w-full h-full top-0" />
      </div>
      <div className="max-lg:hidden block mt-[2.5rem]">
        <p
          className={classNames(
            'uppercase',
            sessionType ? 'text-ticket' : 'text-white'
          )}
        >
          {translations.ticketDetails.date}
        </p>
        <p
          className={classNames(
            'flex items-center gap-[1.5rem] text-[1.3125rem]/[1.3125rem] font-light mt-[0.5rem]',
            sessionType ? 'text-white' : 'text-my-ticket'
          )}
        >
          <CalendarSVG />
          {formattedDate}
        </p>
      </div>
      <div className="mt-[1.5rem] max-lg:hidden">
        <OrganizerSection sessionType={sessionType} event={eventData} />
      </div>
    </>
  );
};

export default AboutEventSection;
