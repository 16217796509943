import classNames from 'classnames';

interface Props {
  tooltype: string;
  title: string;
  date: string;
  picture: string;
  isCene: boolean;
}

const NotificationBlock = ({
  tooltype,
  title,
  date,
  picture,
  isCene,
}: Props) => {
  return (
    <div className="flex py-[1rem] items-center gap-[1.5rem]">
      <div className="relative">
        <img
          src={picture}
          alt="notification image"
          className="min-w-[102px] w-[102px] h-[102px] bg-white object-cover rounded-tl-[1.25rem] rounded-br-[1.25rem]"
        />
        <div className="auto bg-card-gradient absolute w-full h-[110%] top-0 -bottom-3" />
      </div>
      <div className={classNames('flex flex-col gap-[0.5rem] justify-center')}>
        <p
          className={classNames(
            'text-[1rem]/[1.3125rem] font-light text-gray-light'
          )}
        >
          {tooltype}
        </p>
        <h3
          className={classNames(
            'text-[1.0625rem]/[1.375rem] font-light',
            isCene ? 'text-white' : 'text-generic'
          )}
        >
          {title}
        </h3>
        <p
          className={classNames(
            'text-[1rem]/[1.3125rem] font-light text-gray-light'
          )}
        >
          {date}
        </p>
      </div>
    </div>
  );
};

export default NotificationBlock;
