import { ModalDate } from '../../../../components/modalDate/modal';
import { ModalLocation } from '../../../../components/modalLocation/modal';
import translations from '../../../../constants/translations.json';
import { ReactComponent as CalendarSVG } from '../../../../svg/CalendarBlankGeneric.svg';
import { ReactComponent as RightArrowSVG } from '../../../../svg/arrowRightCenePlus.svg';
import { ReactComponent as MapPinSVG } from '../../../../svg/mapTrifold.svg';
import OrganizerSection from './organizer';
import { Event } from '@ceneteam/namespace';
import classNames from 'classnames';
import moment from 'moment';
import { useState } from 'react';

interface Props {
  event: Event | null;
}

const VenueSection = ({ event }: Props) => {
  const [isLocationOpen, setLocationOpen] = useState(false);
  const [isDateOpen, setDateOpen] = useState(false);

  const formattedDate =
    moment(event?.starts_at).format('dddd, MMMM D / ha') + ' WAT';

  const toggleModalDate = () => {
    setDateOpen(!isDateOpen);
  };

  const toggleModalLocation = () => {
    setLocationOpen(!isLocationOpen);
  };

  return (
    <>
      <div className="lg:mt-[32px] mt-[24] bg-generic-06 border border-gray-medium rounded-tl-[30px] rounded-br-[30px] flex flex-col lg:gap-[24px] gap-[16px] lg:p-[40px] p-[20px]">
        <div className="max-lg:block hidden max-lg:mb-[1rem]">
          <h3
            className={classNames(
              'text-[1.0625rem]/[1.375rem] font-light',
              'text-gray-light'
            )}
          >
            {event?.category}
          </h3>
          <h1
            className={classNames(
              'text-[2.25rem]/[2.75rem] font-light mt-[0.5rem]',
              'text-generic'
            )}
          >
            {event?.name}
          </h1>
        </div>
        <p
          className={classNames(
            'lg:text-[1.0625rem]/[1.375rem] text-[0.75rem]/[0.75rem] font-light',
            'text-white'
          )}
        >
          {translations.eventDetailsSection.locatioTitlePlus}
        </p>
        <div className="flex items-center gap-[1.25rem]">
          <MapPinSVG style={{ minWidth: '32px', minHeight: '32px' }} />

          <div className="flex items-center w-full">
            <p
              title={event?.address}
              className={classNames(
                'lg:text-[1.3125rem] lg:leading-[21px] flex-grow text-[1.0625rem] leading-[22px] font-light max-md:max-w-[70%] w-[204px] truncate',
                'text-generic'
              )}
            >
              {event?.address}
            </p>
            <button
              className="ml-auto bg-gray-dark w-[40px] h-[40px] rounded-tl-[8px] rounded-br-[8px] flex items-center justify-center"
              onClick={toggleModalLocation}
            >
              <RightArrowSVG />
            </button>
          </div>
        </div>
        <div className="flex items-center max-md:mb-0 mb-[26px] lg:mb-0 gap-[1.25rem]">
          <CalendarSVG style={{ minWidth: '32px', minHeight: '32px' }} />

          <div className="flex items-center w-full">
            <p
              className={classNames(
                'lg:text-[1.3125rem] lg:leading-[21px] flex-grow text-[1.0625rem] font-light leading-[22px] max-md:max-w-[70%] w-[204px]',
                'text-generic'
              )}
            >
              {formattedDate || translations.eventDetailsSection.date}
            </p>
            <button
              className="ml-auto bg-gray-dark w-[40px] h-[40px] rounded-tl-[8px] rounded-br-[8px] flex items-center justify-center"
              onClick={toggleModalDate}
            >
              <RightArrowSVG />
            </button>
          </div>
        </div>
        <div className="md:hidden">
          <OrganizerSection event={event} />
        </div>
      </div>
      <ModalDate
        isOpen={isDateOpen}
        onClose={toggleModalDate}
        session={'cene+'}
      />
      <ModalLocation
        event={event}
        session={'cene+'}
        isOpen={isLocationOpen}
        onClose={toggleModalLocation}
      />
    </>
  );
};

export default VenueSection;
