import { isValidNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

export const phoneNumberRule = Yup.string()
  .transform((value) => (value && !value.startsWith('+') ? `+${value}` : value))
  .test('is-valid-phone', 'Phone number is invalid', (_, context) => {
    const phoneNumber = context.parent.phone;
    if (!phoneNumber) return false;
    console.log(phoneNumber, isValidNumber(phoneNumber as string));

    return isValidNumber(phoneNumber as string);
  });

export const profileCenePlusSchema = Yup.object().shape({
  // phone: phoneNumberRule,
  jobTitle: Yup.string().min(2).required('Job title is required'),
  companyName: Yup.string().min(2).required('Company is required'),
  photo: Yup.string(),
});

export const profileCeneSchema = Yup.object().shape({
  // phone: phoneNumberRule,
  photo: Yup.string(),
});
