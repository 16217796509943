import { Event } from '@ceneteam/namespace';
import { useState } from 'react';
import { supabaseCeneClient, supabaseCenePlusClient } from '../supabase/supabase';

interface UseTicketActionsReturn {
  getAllTickets: (isCene: boolean) => Promise<Event[] | undefined>; // Expecting a parameter here
  getTicketDetails: (id: string, isCene: boolean) => Promise<Event | undefined>;
  eventsUpcoming: Event[];
  isLoadingUpcoming: boolean;
}

export const useTicketActions = (): UseTicketActionsReturn => {
  const [eventsUpcoming, setEvents] = useState<Event[]>([]);
  const [isLoadingUpcoming, setLoading] = useState(false);

  const getAllTickets = async (isCene: boolean): Promise<Event[] | undefined> => {
    setLoading(true);
    try {
      const client = isCene ? supabaseCeneClient : supabaseCenePlusClient;
      const { data, error } = await client
        .from('tickets')
        .select<string, Event>('*');

      const now = new Date();
      const finishedEvents =
        data?.filter((event: Event) => new Date(event?.ends_at || '') > now) || [];

      if (error) throw error;
      setEvents(finishedEvents); 
      return finishedEvents;
    } catch (error) {
      console.error('Error fetching tickets:', error);
    } finally {
      setLoading(false);
    }
  };

  const getTicketDetails = async (id: string, isCene: boolean): Promise<Event | undefined> => {
    try {
      const client = isCene ? supabaseCeneClient : supabaseCenePlusClient;
      const { data, error } = await client
        .from('tickets')
        .select<string, Event>('*')
        .eq('id', id)
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error fetching ticket details:', error);
      // Handle error appropriately
    }
  };
  return {
    eventsUpcoming,
    isLoadingUpcoming,
    getAllTickets,
    getTicketDetails,
  };
};
