import Steps from '../../../../components/steps/steps';
import { ROUTE_AUTH } from '../../../../constants/routes';
import { usePreventUnload } from '../../../../hooks/usePreventUpload';
import {
  genderAndBirthSchema,
  jobFormSchema,
  nameFormSchema,
  phoneSchemaWithoutContry,
  referenceInviteFormSchema,
} from '../../../../schemas/registerSchema';
import { GenderAndBirthForm } from '../../genderAndBirthStep/cenePlus/genderAndBirthForm';
import { InviteVerifyForm } from '../../inviteVerifyStep/inviteVerifyForm';
import { JobForm } from '../../jobStep/jobForm';
import { NameForm } from '../../nameStep/cenePlus/nameForm';
// import { VerifyForm } from '../../otpStep/cenePlus/verifyForm';
import { PhoneForm } from '../../phoneStep/cenePlus/phoneForm';
import { Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const RegisterForm = () => {
  const [activeStep, setStep] = useState(1);
  const [isFormDirty, setIsFormDirty] = useState(false);
  usePreventUnload(isFormDirty);

  const navigate = useNavigate();

  const onBackStep = useCallback(() => {
    if (activeStep === 1) return navigate(ROUTE_AUTH);

    setStep(activeStep - 1);
  }, [activeStep]);

  const nextStep = useCallback(() => setStep(activeStep + 1), [activeStep]);

  const validationSchemas = [
    nameFormSchema,
    genderAndBirthSchema,
    jobFormSchema,
    phoneSchemaWithoutContry,
    '',
    referenceInviteFormSchema,
  ];

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        gender: 'Male',
        dateOfBirth: null,
        jobTitle: '',
        company: '',
        countryCode: '',
        phone: '',
        email: '',
        reference: '',
      }}
      validationSchema={validationSchemas[activeStep - 1]}
      onSubmit={(values) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
        }, 1000);
      }}
    >
      {({ dirty }) => {
        useEffect(() => {
          setIsFormDirty(dirty);
        }, [dirty]);
        return (
          <Steps
            activeStep={activeStep}
            onBack={onBackStep}
            isPremium={true}
            width={168}
            size="px"
          >
            <NameForm onNext={nextStep} />
            <GenderAndBirthForm onNext={nextStep} />
            <JobForm onNext={nextStep} />
            <PhoneForm onNext={nextStep} />
            {/* <VerifyForm onNext={nextStep} /> */}
            <InviteVerifyForm />
          </Steps>
        );
      }}
    </Formik>
  );
};
