import FaviconManager from '../../components/faviconManager/faviconManager';
import { ROUTER_REGISTER_CENE_PLUS_SECOND_PART } from '../../constants/routes';
import translation from '../../constants/translations.json';
import { useSupabase } from '../../providers/supabase';
import { supabaseCenePlusClient } from '../../supabase/supabase';
import { ReactComponent as LogoPlusSVG } from '../../svg/logotype.svg';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const SplashPage = () => {
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const { session } = useSupabase();

  const fetchCustomer = async () => {
    const { data } = await supabaseCenePlusClient
      .from('customers')
      .select('*')
      .eq('user_id', session?.user?.id)
      .single();

    setName((data as { first_name: string })?.first_name as string);
  };

  useEffect(() => {
    fetchCustomer();

    const timeoutId = setTimeout(() => {
      navigate(ROUTER_REGISTER_CENE_PLUS_SECOND_PART);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [navigate]);

  return (
    <div className="w-full h-screen relative">
      <div
        className={classNames([
          'container mx-auto h-[88px] w-full flex items-center mb-[40px] px-[20px] relative z-20',
        ])}
      >
        <LogoPlusSVG className="w-[164px] h-[48px]" />
      </div>
      <div
        className={classNames([
          'w-11/12 flex flex-col justify-center items-center gap-6 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2',
          'max-w-md',
        ])}
      >
        <h1 className="text-h1/[2.5rem] font-light spacing-[-0.4px] text-generic text-center">
          {translation.welcomeCenePlus}
        </h1>
        <p className="text-h1/[2.5rem] font-light spacing-[-0.4px] text-generic text-center">
          {name}
        </p>
      </div>
      <FaviconManager isCenePlus />
    </div>
  );
};
