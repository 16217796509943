import { supabaseCeneClient } from '../supabase/supabase';

export async function signUpWithProfile(
  email: string,
  password: string,
  first_name: string,
  last_name: string,
  gender: string,
  birthdate: string | null,
  phone_number: string
) {
  // First, sign up the user]
  let { data: userData } = await supabaseCeneClient.auth.getUser();

  if (!userData?.user) {
    const { data, error: signUpError } = await supabaseCeneClient.auth.signUp({
      email,
      password,
    });

    if (signUpError) throw signUpError;

    userData = data;
  }

  //if data is returned, update db
  const { error: updateUserError } = await supabaseCeneClient
    .from('customers')
    .update([
      {
        first_name: first_name,
        last_name: last_name,
        gender: gender,
        birthdate: birthdate || null,
        phone_number: phone_number,
        onboarding_finished: true,
      },
    ])
    .eq('user_id', userData?.user?.id);

  if (updateUserError) throw updateUserError;

  return userData;
}
