import translations from '../../../constants/translations.json';
import { ICategory } from '../../../types/category';

const payment: ICategory = {
  label: 'Payments',
  children: [
    {
      block: 'Ticket Purchase',
      items: [
        {
          question: 'How Many Tickets Can I Buy on Cene?',
          answer: (
            <p>
              {translations.payments.answerFirst.split('four (4) tickets')[0]}
              <span className="font-[700]">four (4) tickets</span>
              {translations.payments.answerFirst.split('four (4) tickets')[1]}
            </p>
          ),
        },
        {
          question: 'I Need to Buy Tickets—Where Should I Go?',
          answer: (
            <p>
              {
                translations.payments.answerTwo.split(
                  'cene.xyz/explore-events'
                )[0]
              }
              <a
                href="https://cene.xyz/explore-events"
                target="_blank"
                rel="noreferrer"
                className="text-blue-500 underline"
              >
                cene.xyz/explore-events
              </a>
              {
                translations.payments.answerTwo.split(
                  'cene.xyz/explore-events'
                )[1]
              }
            </p>
          ),
        },
        {
          question: 'What Fees Should I Expect When Buying Tickets?',
          answer: <p>{translations.payments.answerThree}</p>,
        },
        {
          question: 'How Will I Know if My Ticket Purchase Is Successful?',
          answer: <p>{translations.payments.answerFour}</p>,
        },
        {
          question: 'I Bought Tickets, Where Can I Find Them?',
          answer: (
            <p>
              {translations.payments.answerFive.split('2 hours')[0]}
              <span className="font-[700]">2 hours</span>
              {translations.payments.answerFive.split('2 hours')[1]}
            </p>
          ),
        },
        {
          question: 'Help! I Can’t Find My Tickets...',
          answer: (
            <p className="whitespace-pre-line">
              {translations.payments.answerSix.split('support@cene.xyz')[0]}
              <a className="underline" href="mailto:support@cene.xyz">
                support@cene.xyz
              </a>
              {translations.payments.answerSix.split('support@cene.xyz')[1]}
            </p>
          ),
        },
      ],
    },
    {
      block: 'Payment Methods',
      items: [
        {
          question: 'What Payment Methods Can I Use on Cene?',
          answer: <p>{translations.payments.answerSeven}</p>,
        },
        {
          question:
            'Which Currencies Are Accepted for Purchasing Tickets on Cene?',
          answer: <p>{translations.payments.answerEight}</p>,
        },
        {
          question: 'Is There a Time Limit for Completing My Payment on Cene?',
          answer: (
            <p>
              {translations.payments.answerNine.split('15-minute')[0]}
              <span className="font-[700]">15-minute</span>
              {translations.payments.answerNine.split('15-minute')[1]}
            </p>
          ),
        },
        {
          question: 'What Does "Awaiting Payment" Mean on My Purchase Request?',
          answer: <p>{translations.payments.answerTen}</p>,
        },
        {
          question: 'My Payment Didn’t Go Through; What Should I Do?',
          answer: (
            <p className="whitespace-pre-line">
              {translations.payments.answerEleven.split('support@cene.xyz')[0]}
              <a className="underline" href="mailto:support@cene.xyz">
                support@cene.xyz
              </a>
              {translations.payments.answerEleven.split('support@cene.xyz')[1]}
            </p>
          ),
        },
      ],
    },
    {
      block: 'Refunds',
      items: [
        {
          question:
            'I Wasn’t Able to Attend the Event; What Steps Do I Need to Take to Get a Refund?',
          answer: (
            <p className="whitespace-pre-line">
              {translations.payments.answerTwelve.split('support@cene.xyz')[0]}
              <a className="underline" href="mailto:support@cene.xyz">
                support@cene.xyz
              </a>
              {translations.payments.answerTwelve.split('support@cene.xyz')[1]}
            </p>
          ),
        },
      ],
    },
  ],
};

export default payment;
