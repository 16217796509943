import { Icon } from '../../components/icon/icon';
import { ReactComponent as DoorCloseSvg } from '../../svg/DoorClose.svg';
import { ReactComponent as MartiniSVG } from '../../svg/Martini.svg';
import { ReactComponent as DownSVG } from '../../svg/arrow-down.svg';
import { Ticket } from '@ceneteam/namespace';
import { ReactNode, useState } from 'react';

const Information = ({
  children,
  label,
  icon,
}: {
  children: ReactNode;
  label: string;
  icon?: ReactNode;
}) => {
  const [isExpanded, setExpanded] = useState(false);

  return (
    <>
      <div className="justify-between flex items-center">
        <div className="flex items-center gap-2 text-generic font-light text-[1rem]">
          {icon} {label}
        </div>
        <Icon onClick={() => setExpanded((prev) => !prev)}>
          <DownSVG className={isExpanded ? 'rotate-180' : 'rotate-0'} />
        </Icon>
      </div>
      {isExpanded && (
        <div className="text-generic font-light text-[1rem]">
          {children}
        </div>
      )}
    </>
  );
};

export const TicketInformation = ({ information }: { information: Ticket }) => {
  return (
    <div className="gap-2 flex flex-col">
      <Information label="Table Details">{information.description}</Information>
      {information.floorplan.instructions && (
        <Information
          label="Access"
          icon={<DoorCloseSvg width="16" height="16" />}
        >
          {information.floorplan.instructions}
        </Information>
      )}
      {information.floorplan.food_options && (
        <Information label="Food & Drink" icon={<MartiniSVG />}>
          {information.floorplan.food_options}
        </Information>
      )}
    </div>
  );
};
