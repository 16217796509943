import { ReactComponent as FilledHeartSvg } from '../../../src/svg/filledHeart.svg';
import { ReactComponent as HeartSvg } from '../../../src/svg/heart.svg';
import { ReactComponent as ShareSvg } from '../../../src/svg/share.svg';
import { ShareOptions } from '../../components/shareOptions/shareOptions';
import { ROUTE_EVENTS_DETAILS } from '../../constants/routes';
import { useClickOutside } from '../../hooks/useClickOutside';
import { useEventActions } from '../../hooks/useEventActions';
import { useSupabase } from '../../providers/supabase';
import { Event } from '@ceneteam/namespace';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface CardProps {
  event: Event;
  eventsLiked: Event[];
  handleHeartClick: (title: string) => void;
}

export const CardArtist = ({
  event,
  handleHeartClick,
  eventsLiked,
}: CardProps) => {
  const { session } = useSupabase();
  const navigate = useNavigate();
  const id = String(session?.user?.id || '');
  const { setLike } = useEventActions({ userId: id || null });
  const [showShareOptions, setShowShareOptions] = useState(false);
  const shareOptionsRef = useRef<HTMLDivElement | null>(null);
  const [isHeartFilled, setIsHeartFilled] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const sessionType = session?.type === 'cene+';

  useEffect(() => {
    if (Array.isArray(eventsLiked)) {
      const isLiked = eventsLiked.some(
        (likedEvent: Event) => likedEvent?.id === event?.id
      );
      if (isLiked !== isHeartFilled) {
        setIsHeartFilled(isLiked);
      }
    }
  }, [eventsLiked, event?.id]);

  const handleCloseShareOptions = () => {
    setShowShareOptions(false);
  };
  useClickOutside(shareOptionsRef, handleCloseShareOptions);
  const handleShareClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setShowShareOptions((prev) => !prev);
  };
  const handleCopyLink = () => {
    const textToCopy = `https://cene.xyz/event-details/${event?.id}`;
    navigator.clipboard.writeText(textToCopy);
    handleCloseShareOptions();
  };
  const toggleHeart = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    if (!session) {
      return;
    }
    setIsHeartFilled((prev) => {
      const newIsHeartFilled = !prev;
      setLike(event?.id, !newIsHeartFilled);

      if (newIsHeartFilled) {
        handleHeartClick(event.name);
        console.log(`Event ${event.name} added to the list`);
      } else {
        console.log(`Event ${event.name} removed from the list`);
      }

      return newIsHeartFilled;
    });
  };

  // const toggleHeart = (e: React.MouseEvent<SVGElement>) => {
  //   e.stopPropagation();
  //   setIsHeartFilled((prev) => !prev);
  //   if (!isHeartFilled) {
  //     handleHeartClick(event.name);
  //   }
  // };

  const handleCardClick = () => {
    if (!isScrolling && !showShareOptions) {
      navigate(ROUTE_EVENTS_DETAILS.replace(':id', event?.id));
    }
  };

  const handleMouseDown = () => {
    setIsScrolling(false);
  };

  const handleMouseMove = () => {
    setIsScrolling(true);
  };

  return (
    <div
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onClick={handleCardClick}
      className={classNames([
        'relative flex flex-col rounded-l-[30px] shadow-lg p-4 w-full h-[420px] bg-cover bg-no-repeat z-[4]',
        'transition-opacity duration-300 hover:opacity-70 cursor-pointer flex-shrink-0',
      ])}
    >
      <img
        src={event.featured_image.url}
        alt={event.name}
        className="absolute inset-0 w-full h-full object-cover rounded-tl-[30px]"
      />
      <div className="absolute top-0 left-0 right-0 h-[122px] z-0 bg-top-gradient" />
      <div
        className={classNames(
          'absolute top-[20px] left-[20px] z-10',
          sessionType ? 'text-generic' : 'text-event'
        )}
      >
        <p className="whitespace-pre-line text-[0.875rem] leading-[14px] font-normal">
          {event.address.split(',')[0]}
        </p>

        <p
          className={classNames('text-[0.875rem]/[0.875] font-light mt-[1rem]')}
        >
          {moment(event?.starts_at).format('dddd, MMMM D / h:mm a')}
        </p>
      </div>
      <div className="flex-grow z-10"></div>
      <div className="flex justify-between items-end mt-4 z-10">
        <div className="text-left">
          <p
            className={classNames(
              'whitespace-pre-line text-[0.75rem] leading-[12px]  mb-[4px]',
              sessionType
                ? 'text-generic font-normal'
                : 'text-event uppercase font-light'
            )}
          >
            {event.category}
          </p>
          <p
            title={event.name}
            className={classNames(
              'whitespace-pre-line text-[2rem] leading-[40px] font-normal truncate max-h-[40px]',
              sessionType ? 'text-generic' : 'text-white'
            )}
          >
            {event.name}
          </p>
        </div>
        <div className="text-right flex flex-col items-end">
          <button onClick={handleShareClick}>
            <ShareSvg className="transition-transform duration-200 ease-in-out hover:scale-110" />
          </button>
          {isHeartFilled ? (
            <FilledHeartSvg
              style={{ fill: '#91DBBF' }}
              className="mt-[12px] transition-transform duration-200 ease-in-out hover:scale-110"
              onClick={toggleHeart}
            />
          ) : (
            <HeartSvg
              className="mt-[12px] transition-transform duration-200 ease-in-out hover:scale-110"
              onClick={toggleHeart}
            />
          )}
        </div>
      </div>
      {showShareOptions && (
        <div ref={shareOptionsRef}>
          <ShareOptions
            onCopyLink={handleCopyLink}
            onClose={handleCloseShareOptions}
            event={{
              id: event?.id,
              title: event.name,
              description: event.description,
              link: `https://cene.xyz/event-details/${event?.id}`,
            }}
          />
        </div>
      )}
      <div className="absolute inset-0 bg-discover z-0"></div>
    </div>
  );
};
